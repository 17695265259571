import { createStore, createEffect, createEvent, sample } from 'effector';
import * as R from 'ramda';
import { Mask, recognize, loadMask, loadSketch } from 'services/sketch';
import * as utils from './utils';
import { GroupData, ListData, SegmentData, SegmentDataFromSam, Manager } from './Manager';
import config from 'config';


export const $file = createStore<File | null>(null);
export const $name = createStore<string>('Unnamed');
export const $manager = createStore<Manager | null>(null);
export const $intersection = createStore(90);
const $managerTimout = createStore<NodeJS.Timeout | null>(null);
export const $queue = createStore(-1);

export const setFile = createEvent<File | null>();
export const setName = createEvent<string>();
export const setIntersection = createEvent<number>();
export const clearManager = createEvent();
export const createManager = createEvent<{ id: string; list: Array<ListData>; segments: Array<SegmentData>; groups: Array<GroupData>; prototypeId: string; name: string }>();

export const recognizeFx = createEffect(recognize);
export const loadMaskFx = createEffect(loadMask);
export const loadSketchFx = createEffect(loadSketch);


$name
    .on(setName, R.nthArg(1));

$intersection
    .on(setIntersection, R.nthArg(1));

$file
    .on(setFile, R.nthArg(1))
    .on(clearManager, R.always(null));

$manager
    .on(clearManager, R.always(null));

loadSketchFx.doneData.watch(R.pipe(R.prop('config'), JSON.parse, createManager));

recognizeFx.doneData.watch((id) => fetch(`${config.serverUrl}/drawing/${id}.png`)
    .then(res => res.blob())
    .then(blob => {
        const file = new File([blob], id);
        setFile(file);
    }));

sample({
    clock: recognizeFx.doneData,
    filter: Boolean,
    fn: id => setInterval(() => loadMaskFx(id), 1000),
    target: $managerTimout,
});

sample({
    source: $managerTimout,
    clock: loadMaskFx.doneData,
    filter: (_, value) => value instanceof Array,
    fn: value => {
        if (value) clearInterval(value);
        return null;
    },
    target: $managerTimout,
});

sample({
    clock: loadMaskFx.doneData,
    filter: value => !(value instanceof Array),
    fn: value => {
        if (value instanceof Array) throw new Error('Result is not array.');
        return value.queueSize;
    },
    target: $queue,
});

sample({
    clock: loadMaskFx.done,
    source: { file: $file, name: $name },
    filter: (file, { result }) => Boolean(file) && result instanceof Array,
    fn: ({ file, name }, { params: id, result }) => {
        if (!(result instanceof Array)) throw new Error('Result is not array.');
        if (!file) throw new Error('File is null.');
        return new Manager({ id, segments: result.map(maskToSegmentData), file, groups: [], firstLoad: true, name });
    },
    target: $manager,
});

sample({
    clock: createManager,
    source: $file,
    filter: Boolean,
    fn: (file, { id, list, segments, groups, name }) => {
        if (!file) throw new Error('File is null.');
        return new Manager({ id, segments, file, list, groups, firstLoad: false, name });
    },
    target: $manager,
});

function maskToSegmentData(mask: Mask): SegmentDataFromSam {
    return {
        name: mask.name,
        id: crypto.randomUUID(),
        attachments: [],
        color: utils.generateRandomColor(),
        description: '',
        groupId: null,
    };
}
