import { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "effector-react";
import { $tutorial } from "entities/tutorial";
import { ExtendedHintI, PositionI, bigTooltipsDescr, getFlexDirection, getHintsPosition } from "./helper";
import useElementCoordinates from "services/useElementCoordinates";
import { HintI, setHint } from "entities/hint";
import classNames from "classnames";
import './Hint.scss';



function Hint({ hint }: { hint: HintI }) {
    const tutorial = useStore($tutorial);
    const [showHint, setShowHint] = useState<boolean>(false)
    const [showExtendedHint, setShowExtendedHint] = useState<boolean>(false)
    const [extendedHint, setExtendedHint] = useState<ExtendedHintI | undefined>(undefined)
    const [hintPosition, setHintPosition] = useState<PositionI>({ top: 0, left: 0 });
    const [extendedHintPosition, setExtendedHintPosition] = useState<PositionI>({ top: 0, left: 0 });
    const showTimeoutRef = useRef<NodeJS.Timeout>();
    const extendedHintTimeoutRef = useRef<NodeJS.Timeout>();
    const coordinates = useElementCoordinates(hint.index? hint.elementId+hint.index : hint.elementId);
    const hintRef = useRef<HTMLDivElement | null>(null);
    const exHintRef = useRef<HTMLDivElement | null>(null);
    const [hintSize, setHintSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })
    const [extendedHintSize, setExtendedHintSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })


    useEffect(() => {
        const { topHint, leftHint, topExHint, leftExHint } = getHintsPosition(hint.elementId, hint.arrowPosition, coordinates, hintSize, extendedHintSize);
        setHintPosition({ top: topHint, left: leftHint });
        setExtendedHintPosition({ top: topExHint, left: leftExHint });
    }, [hint.arrowPosition, hint.elementId, coordinates, hintSize, extendedHintSize])

    useEffect(() => {
        if (hintRef && hintRef.current) {
            setHintSize({ height: hintRef.current.offsetHeight, width: hintRef.current.offsetWidth })
        }
    }, [hintRef, hint])

    useEffect(() => {
        if (exHintRef && exHintRef.current) {
            setExtendedHintSize({ height: exHintRef.current.offsetHeight, width: exHintRef.current.offsetWidth })
        }
    }, [hintRef, hint])

    //exHintRef

    const onMouseEnter = useCallback(() => {
        // setShowHint(true);
        showTimeoutRef.current = setTimeout(() => {
            setShowHint(true);
        }, 1000);


        if (hint.extendedHintType) {
            extendedHintTimeoutRef.current = setTimeout(() => {
                setExtendedHint(bigTooltipsDescr.find((item) => item.element === hint.extendedHintType))
                setShowHint(false);
                setShowExtendedHint(true);
            }, 2500);
        }

        // setExtendedHint(bigTooltipsDescr.find((item) => item.element === hint.extendedHintType))
        // setShowHint(false);
        // setShowExtendedHint(true);

    }, [hint.extendedHintType])


    const onMouseLeave = useCallback(() => {
        setShowHint(false);
        setShowExtendedHint(false);
        clearTimeout(showTimeoutRef.current);
        clearTimeout(extendedHintTimeoutRef.current);
        setHint(null)
    }, []);

    const onElementClick = useCallback (()=>{
        setShowHint(true);
    },[])

    useEffect(() => {
        let element : HTMLElement | null = null
        if(hint.index) element = document.getElementById(hint.elementId+hint.index)
        else element = document.getElementById(hint.elementId);
        // const element = document.getElementById(hint.elementId);
        if (element) {
            if (tutorial.step === 0) {
                element.addEventListener('mouseenter', onMouseEnter);
                element.addEventListener('mouseleave', onMouseLeave);
                element.addEventListener('click', hint.elementId==='launch_tutorial-info'? onElementClick : onMouseLeave );
            }
        }

        return () => {
            if (element) {
                element.removeEventListener('mouseenter', onMouseEnter);
                element.removeEventListener('mouseleave', onMouseLeave);
                element.removeEventListener('click', hint.elementId==='launch_tutorial-info'? onElementClick : onMouseLeave);
            }
        };

    }, [hint.elementId, hint.index, onElementClick, onMouseEnter, onMouseLeave, tutorial.step]);



    useEffect(() => {
        if (tutorial.step === 4 && hint.isTutorial) {
            setShowHint(true);
            extendedHintTimeoutRef.current = setTimeout(() => {
                if (bigTooltipsDescr.find((item) => item.element === hint.extendedHintType)) {
                    setExtendedHint(bigTooltipsDescr.find((item) => item.element === hint.extendedHintType));
                    setShowHint(false);
                    setShowExtendedHint(true);
                }
            }, 1500);
        } else if (tutorial.step !== 4 && tutorial.step !== 0) {
            setShowExtendedHint(false)
            setShowHint(false)
            clearTimeout(extendedHintTimeoutRef.current);
            clearTimeout(showTimeoutRef.current);
        }

    }, [coordinates, hint.elementId, hint.extendedHintType, hint.isTutorial, hint.arrowPosition, tutorial.step])



    return (
        <>
            <div className='hint_wrapper'
                ref={hintRef}
                style={{
                    left: hintPosition.left,
                    ...(hint.alignPosition === 'flex-end' ? { bottom: hintPosition.top } : { top: hintPosition.top }),
                    opacity: showHint ? '1' : '0',
                    visibility: showHint ? 'visible' : 'hidden',
                    transition: 'opacity 0.5s, visibility 0.5s',
                    flexDirection: getFlexDirection(hint.arrowPosition),
                    ...(hint.alignPosition && { alignItems: hint.alignPosition })
                }}>
                <div className={classNames({
                    [`hint_before_element_${hint.arrowPosition}`]: true,
                    [`hint_before_${hint.alignPosition}`]: Boolean(hint.alignPosition)
                })} />
                <div className="hint">
                    <p>{hint.text}</p>
                    {hint.extendedHintType && <div className="hint_spinner" />}
                </div>
            </div>
            <div className='big_tooltip_wrapper'
                ref={exHintRef}
                id='big_tooltip'
                style={{
                    left: extendedHintPosition.left,
                    ...(hint.alignPosition === 'flex-end' ? { bottom: extendedHintPosition.top } : { top: extendedHintPosition.top }),
                    opacity: showExtendedHint ? '1' : '0',
                    visibility: showExtendedHint ? 'visible' : 'hidden',
                    transition: 'opacity 0.5s, visibility 0.5s',
                    flexDirection: getFlexDirection(hint.arrowPosition),
                    ...(hint.alignPosition && { alignItems: hint.alignPosition })
                }}
            >
                <div className={classNames({
                    [`hint_before_element_${hint.arrowPosition}`]: true,
                    [`hint_before_${hint.alignPosition}`]: Boolean(hint.alignPosition)
                })} />
                <div className="big_tooltip">
                    <p className="big_tooltip-title">{extendedHint?.title}</p>
                    <div className="big_tooltip-image" />
                    <div className="big_tooltip-text-wrapper">
                        <div className="big_tooltip-text">
                            {extendedHint?.description.map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hint;