import classNames from 'classnames';
import './Input.scss';


type InputProps = {
    placeholder?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    className?: string;
    size?: 'regular' | 'table';
    disabled?: boolean;
    type?: React.HTMLInputTypeAttribute;
    validateError? : boolean,
};

export default function Input({ placeholder, value, onChange, onBlur, onFocus, className = '', size = 'regular', disabled = false, type, validateError=false}: InputProps) {
    return (
        <input
            className={classNames({
                input: true,
                ['input-' + size]: true,
                [className]: Boolean(className),
                'input-error': validateError,
            })}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type}
        />
    );
}