import React, { ChangeEvent, useState } from "react";
import './BillingSettings.scss'
import classNames from "classnames";
import Button from "Components/Button";
import Input from "Components/Input";
import Select from "Components/Select";
import Checkbox from "Components/Checkbox";
import { setHint } from "entities/hint";

enum BillingSection {
    OVERVIEW = 'OVERVIEW',
    PAYMENT_METHODS = 'PAYMENT METHODS',
    BILLING_HISTORY = 'BILLING HISTORY',
    PREFERENCES = 'PREFERENCES'

}
const billingSections = [
    { section: BillingSection.OVERVIEW, label: "OVERVIEW" },
    { section: BillingSection.PAYMENT_METHODS, label: "PAYMENT METHODS" },
    { section: BillingSection.BILLING_HISTORY, label: "BILLING HISTORY" },
    { section: BillingSection.PREFERENCES, label: "PREFERENCES" }
];

export default function BillingSettings() {
    const [activeBillingSection, setActiveBillingSection] = useState<BillingSection>(BillingSection.OVERVIEW)
    return (
        <div className="billing">
            <div className={classNames({ "billing_menu": true, 'billing_menu_narrow': activeBillingSection === BillingSection.BILLING_HISTORY })}>
                <div className="billing_menu-items">
                    {billingSections.map((item) => (
                        <p key={item.section} className={classNames({ 'billing_menu-item': true, 'billing_menu-item_active': activeBillingSection === item.section })} onClick={() => setActiveBillingSection(item.section)}>{item.label}</p>
                    ))}
                </div>

            </div>
            <div className={classNames({ "billing_section": true, "billing_section_wide": activeBillingSection === BillingSection.BILLING_HISTORY })} >
                {activeBillingSection === BillingSection.OVERVIEW && <OverviewSection />}
                {activeBillingSection === BillingSection.PAYMENT_METHODS && <PaymentMethodsSection />}
                {activeBillingSection === BillingSection.BILLING_HISTORY && <HistorySection />}
            </div>
        </div>
    )
}



function OverviewSection() {
    const [onBuyTokens, setOnBuyTokens] = useState<boolean>(false)

    return (
        <div className="overview_section">
            <div className="overview_section_tokens">
                <div className="overview_section_tokens-title">
                    <p>Your balance</p>
                    <div className="balance_info"
                        id="balance-info"
                        onMouseEnter={() => setHint({id:'balance-info'})}
                        onMouseLeave={() => setHint(null)} />
                </div>
                <p className="balance">10 tokens</p>
                <div>
                    <Button icon="add" className="buy_tokens-bttn" onClick={() => setOnBuyTokens(true)} disabled={onBuyTokens}>
                        buy tokens
                    </Button>
                </div>
            </div>
            {onBuyTokens &&
                <div className="overview_section-beta-notion">
                    <p className="beta-notion-title"><span className="beta_span">BETA</span> Testing - No Publicly Available Paid Features</p>
                    <div className="beta-notion-text">
                        <p>We're currently in beta testing. We've distributed free tokens to enthusiasts for feedback collection. If you'd like to join them, please contact us directly.</p>
                        <p>If you're already one of our enthusiasts and have run out of tokens, please contact us via <span className="underline_span">[email]</span> or any way convenient. We'll gladly replenish your tokens for continued testing.</p>
                        <p>Billing will be introduced after the testing phase is complete.</p>
                    </div>
                </div>
            }
        </div>
    )
}

interface PaymentCardI {
    id: string,
    number: string,
    date: string,
    isDefault: boolean,
    type: string
}

function PaymentMethodsSection() {
    const [addNewPayment, setAddNewPayment] = useState<boolean>(false)
    const [cards, setCards] = useState<PaymentCardI[]>([])

    function addCard(newCard: PaymentCardI) {

        if (newCard.isDefault) {
            const prevCards = cards.map((card) => {
                return {
                    ...card,
                    isDefault: false,
                }
            })
            setCards([newCard, ...prevCards])
        }
        else setCards((prev) => [newCard, ...prev])
    }

    const setAsDefaultCard = (id: string) => {
        setCards(
            (prev) => prev.map((card) => {
                return {
                    ...card,
                    isDefault: card.id === id ? true : false
                }
            })
        )
    }

    const deleteCard = (id: string) => {
        setCards((prev) =>
            prev.filter((card) => card.id !== id)
        );
    }

    return (
        <div className="payment_methods">
            <div className="payment-methods-wrapper">
                {(cards.length === 0 && !addNewPayment) && <p className="no-payment-methods">There is no payment method yet</p>}
                {cards.length !== 0 &&
                    cards.map((card) => (
                        <PaymentCard card={card} setAsDefaultCard={setAsDefaultCard} deleteCard={deleteCard} />
                    ))}
                {!addNewPayment &&
                    <div>
                        <Button className="add-payment-bttn" icon="add" onClick={() => setAddNewPayment(true)}>add payment method</Button>
                    </div>}
                {(cards.length !== 0 && addNewPayment) && <div className="new_payment_method"><p>new payment method</p></div>}
                {addNewPayment && <AddPayment onAddCard={addCard} cardsLenght={cards.length} onCancel={() => setAddNewPayment(false)} />}
            </div>
        </div>
    )
}

interface AddPaymentProps {
    onAddCard: (card: PaymentCardI) => void
    onCancel: () => void,
    cardsLenght: number
}

function AddPayment({ onAddCard, onCancel, cardsLenght }: AddPaymentProps) {
    const [isDefaultPayment, setIsDefaultPayment] = useState<boolean>(false)
    const [isCardNumberOnFocus, setIsCardNumberOnFocus] = useState<boolean>(false)
    const [cardNumber, setCardNumber] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [cvc, setCvc] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [cardDetailsError, setCardDetailsError] = useState<boolean>(false)
    const [nameError, setNameError] = useState<boolean>(false)

    const cardNumberOnFocus = () => {
        setCardDetailsError(false)
        setIsCardNumberOnFocus(true)
    }

    const cardNumberOnBlur = () => {
        setIsCardNumberOnFocus(false)
    }

    const handleAddCard = () => {

        let hasError = false
        if (cardNumber.length < 19 || (date.length < 5) || cvc.length < 3) {
            hasError = true
            setCardDetailsError(true)
        }
        if (name.trim().length === 0 || name.trim().split(/\s+/).length < 2) {
            setNameError(true)
            hasError = true
        }

        if (!hasError) {
            const newCard: PaymentCardI = {
                id: crypto.randomUUID(),
                number: cardNumber,
                date: date,
                isDefault: cardsLenght === 0 ? true : isDefaultPayment,
                type: 'visa',
            }

            onAddCard(newCard)
            onCancel()
        }

    }

    const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, '');

        let formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
        setCardNumber(formattedValue);
    };
    const handleCardDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, '');
        let formattedValue = value.replace(/(\d{2})/, '$1/').trim();
        setDate(formattedValue);
    };

    return (
        <div className="add_payment-wrapper">
            <div className="payment_method-input_item">
                <p>Card information</p>
                <div className={classNames({ "error-text": true, "error-text_active": cardDetailsError })}>Invalid data, please try again</div>
                <div className={classNames({ "card_number_input-wrapper": true, 'card_number_input-wrapper_on-focus': isCardNumberOnFocus, 'card_number_input-wrapper_error': cardDetailsError })}>
                    <input className="card_number_input"
                        placeholder="Card number"
                        value={cardNumber}

                        onFocus={cardNumberOnFocus}
                        onBlur={cardNumberOnBlur}
                        type="text"
                        required
                        maxLength={19}
                        onChange={handleCardNumberChange} />

                    <input className="card_date_input"
                        placeholder="MM/YY"
                        value={date}
                        maxLength={5}
                        onChange={handleCardDateChange}
                        onFocus={cardNumberOnFocus}
                        onBlur={cardNumberOnBlur} />

                    <input className="card_cvc_input"
                        placeholder="CVC"
                        value={cvc}
                        type="password"
                        onChange={(e) => setCvc(e.target.value)}
                        onFocus={cardNumberOnFocus}
                        maxLength={3}
                        onBlur={cardNumberOnBlur} />

                </div>
            </div>
            <div className="payment_method-input_item">
                <p>Name on card</p>
                <div className={classNames({ "error-text": true, "error-text_active": nameError })}>Invalid date, please try again</div>
                <Input value={name} onFocus={() => setNameError(false)} onChange={(e) => setName(e.currentTarget.value.toUpperCase())} placeholder="Your name" validateError={nameError} />
            </div>
            <div className="payment_method-input_item">
                <p>Billing address</p>
                <Select value={""} items={[]} setValue={() => undefined} placeholder="Country" />
                <Input value={""} onChange={() => undefined} placeholder="Address line 1" />
                <Input value={""} onChange={() => undefined} placeholder="Address line 2" />
                <div className="payment_method-input_address">
                    <Input value={""} onChange={() => undefined} placeholder="City" className="payment_method-input" />
                    <Input value={""} onChange={() => undefined} placeholder="Postal code" className="payment_method-input" />

                </div>
                <Input value={""} onChange={() => undefined} placeholder="State, country, province, or regin" />
            </div>
            {cardsLenght !== 0 && <div className="payment_method-checkbox_wrapper">
                <Checkbox checked={isDefaultPayment} onChange={setIsDefaultPayment} className="payment-checkbox" />
                <p>Set as default payment method</p>
            </div>}
            <div className="add-payment-bttns-wrapper">
                <Button className="add-payment-bttn" icon="add" onClick={handleAddCard}>add payment method</Button>
                <Button className="add-payment-bttn" onClick={onCancel}>Cancel</Button>
            </div>
        </div>
    )
}


function PaymentCard({ card, setAsDefaultCard, deleteCard }: { card: PaymentCardI, setAsDefaultCard: (id: string) => void, deleteCard: (id: string) => void }) {
    return (
        <div className="payment_card">
            <div className="payment_card-info">
                <div className="payment_card-number-wrapper">
                    <div className="payment_card-number-hiden" />
                    <p className="payment_card-number">{card.number.slice(-4)}</p>
                </div>
                <p className="payment_card-date">Expires {card.date}</p>
                {card.isDefault ?
                    <p className="payment_card-state_default">Default</p> :
                    <p className="payment_card-state_not-default" onClick={() => setAsDefaultCard(card.id)}>set as default</p>
                }

            </div>
            <div className="payment_card-info right-align">
                <div className="payment_card-type">
                    {card.type}
                </div>
                <div className="delete_card">
                    <div className="delete_card-icon" />
                    <p onClick={() => deleteCard(card.id)}>delete</p>
                </div>

            </div>

        </div>
    )
}


function HistorySection() {
    return (
        <div className="billing_histoty">
            <p>Showing invoices within the past 12 months</p>
            <div className="billing_histoty-table">
                <div className="billing_histoty-table_header">
                    <div className="histoty-table_header-cell">Invoice</div>
                    <div className="histoty-table_header-cell">status</div>
                    <div className="histoty-table_header-cell">amount</div>
                    <div className="histoty-table_header-cell">created</div>
                </div>
                <div className="histoty-table_row">
                    <div className="histoty-table_cell">
                        <p className="invoice_undeline">123456789</p>
                    </div>
                    <div className="histoty-table_cell">
                        <div className={classNames({ "invoice_status": true, "invoice_status-paid": true })}>Paid</div>
                    </div>
                    <div className="histoty-table_cell">
                        <p>$100.00</p>
                    </div>
                    <div className="histoty-table_cell">
                        <p>May 7,2024, 21:27 PM</p>
                    </div>
                </div>
                <div className="histoty-table_row">
                    <div className="histoty-table_cell">
                        <p className="invoice_undeline">1023456789</p>
                    </div>
                    <div className="histoty-table_cell">
                        <div className={classNames({ "invoice_status": true, "invoice_status-in_progress": true })}>In progress</div>
                    </div>
                    <div className="histoty-table_cell">
                        <p>$100.00</p>
                    </div>
                    <div className="histoty-table_cell">
                        <p>May 7,2024, 21:27 PM</p>
                    </div>
                </div>

            </div>

        </div>
    )
}