import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Slider from '@mui/material/Slider';
import Switcher from 'Components/Switcher';
import Button from 'Components/Button';
import Select from 'Components/Select';
import Radio from 'Components/Radio';
import Input from 'Components/Input';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { useManager } from 'entities/sketch/Manager';
import { errorEvent } from 'entities/error';
import { ReactComponent as CloseSVG } from './icons/close.svg';
import { ReactComponent as Clip } from './icons/clip.svg'
import { ColorSelect, RenderColor } from 'Components/Select/Select';
import { Notifications, setNotification } from 'entities/notification';
import { setHint } from 'entities/hint';
import './SubmitForRendering.scss';


const functions = [{ key: 'Kitchen', value: 'Kitchen' }, { key: 'Bedroom', value: 'Bedroom' }, { key: 'Bathroom', value: 'Bathroom' }, { key: 'Living Room', value: 'Living Room' }, { key: 'Office', value: 'Office' }, { key: 'Residential building', value: 'Residential building' }, { key: 'Office building', value: 'Office building' }];
const times = [{ key: 'Morning sunrise', value: 'Morning' }, { key: 'Day', value: 'Day' }, { key: 'Evening sunset', value: 'Evening' }];
const seasons = [{ key: 'European summer', value: 'Summer' }, { key: 'European autumn', value: 'Autumn' }, { key: 'European winter', value: 'Winter' }, { key: 'European spring', value: 'Spring' }];
const weathers = [{ key: 'Sunny', value: 'Sunny' }, { key: 'Cloudy', value: 'Cloudy' }];
const sceneryArr = [{ key: 'Urban skyline', value: 'Urban skyline' }, { key: 'Rural countryside', value: 'Rural countryside' }, { key: 'Open field', value: 'Open field' }, { key: 'Dense forest', value: 'Dense forest' }, { key: 'Mountain landscape', value: 'Mountain landscape' }, { key: 'Ocean horizon', value: 'Ocean horizon' }, { key: 'Riverbank view', value: 'Riverbank view' }, { key: 'Lakefront scene', value: 'Lakefront scene' }, { key: 'No scenery', value: 'No scenery' }];
const colors: RenderColor[] = [{ color: '#F4F0E6', name: 'Ivory' }, { color: '#E4C3B2', name: 'Blush' }, { color: '#A55A4F ', name: 'Rust' }, { color: '#8C4A45', name: 'Brick' }, { color: '#6C3F4D', name: 'Burgundy ' }, { color: '#B28291', name: 'Mauve' }, { color: '#C9A760', name: 'Ochre' }, { color: '#A59383', name: 'Taupe' }, { color: '#8C8C8C', name: 'Pewter' }, { color: '#B8B8B8', name: 'Concrete' }, { color: '#B2B4B7', name: 'Ash' }, { color: '#3F4649', name: 'Graphite' }, { color: '#484B52', name: 'Charcoal ' }, { color: '#2C3439', name: 'Gunmetal' }, { color: '#3C2F2F', name: 'Espresso' }, { color: '#637383', name: 'Steel' }, { color: '#556370', name: 'Slate' }, { color: '#3C6468', name: 'Teal' }, { color: '#586450', name: 'Forest' }, { color: '#7D8066', name: 'Olive' }]
const dpi = [{ key: '96', value: '96' }, { key: '64', value: '64' }, { key: '32', value: '32' }];

enum ResolutionType {
    SCREEN = 'SCREEN',
    PRINT = 'PRINT',
}

export default function SubmitForRendering() {
    const manager = useManager('self');
    const [boost, setBoost] = useState(false);
    const [isDefaultSettings, setIsDefaultSettings] = useState(false);
    const [fn, setFn] = useState('');
    const [time, setTime] = useState(times[0].value);
    const [season, setSeason] = useState(seasons[0].value);
    const [weather, setWeather] = useState(weathers[0].value);
    const [scenery, setScenery] = useState(sceneryArr[0].value);
    const [warm, setWarm] = useState(3);
    const [colorful, setColorful] = useState(3);
    const [agitating, setAgitating] = useState(3);
    const [organic, setOrganic] = useState(3);
    const [decorative, setDecorative] = useState(3);
    const [versatile, setVersatile] = useState(3);
    const [dominant, setDominant] = useState(3);
    const [rough, setRough] = useState(3);
    const [artificial, setArtificial] = useState(3);
    const [resolutionType, setResolutionType] = useState(ResolutionType.SCREEN);
    const [conditions] = useState(false);
    const [widthPX, setWidthPX] = useState('800');
    const [heightPX, setHeightPX] = useState('600');
    const [widthMM, setWidthMM] = useState('211.6');
    const [heightMM, setHeightMM] = useState('158.8');
    const [DPI, setDPI] = useState(dpi[0].value);

    const [primaryColor, setPrimaryColor] = useState<RenderColor>();
    const [secondaryColor, setSecondaryColor] = useState<RenderColor>();

    const [isFnError, setIsFnError] = useState<boolean>(false);
    const [isSubmSuccess, setIsSubmSuccess] = useState<boolean>(false);

    useEffect(() => void setNotification(Notifications.RENDER_INFO), []);

    const getSize = useCallback((): { width: number, height: number } => {
        switch (resolutionType) {
            case ResolutionType.PRINT:
                return {
                    width: Number(widthMM),
                    height: Number(heightMM),
                };
            case ResolutionType.SCREEN:
                return {
                    width: Number(widthPX),
                    height: Number(heightPX),
                };
            default:
                throw new Error('Resolution type is nott implemented.');
        }
    }, [widthMM, heightMM, widthPX, heightPX, resolutionType]);

    const submitForRender = useCallback(() => {
        if (fn.trim() === '' || fn === undefined) return setIsFnError(true);
        manager.renderSketch(JSON.stringify({
            weather,
            time,
            conditions: conditions ? 'True' : 'False',
            season,
            ...getSize(),
            prompt_monochrome: 6 - colorful,
            prompt_warm: warm,
            prompt_utilitarian: decorative,
            prompt_plain: 3,
            prompt_uniform: 6 - versatile,
            prompt_smooth: 6 - rough,
            prompt_natural: 6 - artificial,
            image_name: manager.id + '.png',
            json_name: manager.id + '.json',
        })).catch(errorEvent).then(() => setIsSubmSuccess(true));
    }, [fn, manager, weather, time, conditions, season, getSize, colorful, warm, decorative, versatile, rough, artificial]);

    const calcDefinedObjects = useMemo(() => manager.segments.filter(segment => segment.description).length, [manager]);

    return (
        <div className="sfr">
            <div className="sfr__window">
                {isSubmSuccess ? <SubmissionSuccessful /> :
                    <>
                        <CloseSVG className="sfr__close-button" onClick={() => {
                            setNotification(null);
                            setLeftSidePanel(LeftSidePanel.NONE);
                        }} />
                        <div className="sfr__title">
                            <div>Rendering settings</div>
                            <div />
                            <div>account_name@mail.com</div>
                        </div>
                        <div className="sfr__main">
                            <div className="sfr__settings">
                                <div className='sfr__settings-fn-description'>
                                    <p>Following rendering settings apply to undefined image areas and general image qualities such as time of day. AI preconditions settings when possible. </p>
                                    <p>Please review and make any necessary manual adjustments.</p>
                                </div>
                                <div className='sfr__settings-fn_wrapper'>
                                    <div className="sfr__settings-fn">
                                        <div>FUNCTION</div>
                                    </div>
                                    <Select className="sfr__settings-fn-select" items={functions} value={fn} setValue={setFn} onFocus={() => setIsFnError(false)} placeholder='Choose function for undefined image elements' validateError={isFnError} />
                                    {isFnError && <div className='sfr__settings-fn_error'>Please choose function</div>}
                                </div>
                                <div className="sfr__settings-separator" />
                                <div className="sfr__settings-time-label">TIME</div>
                                <Select className="sfr__settings-time-select" items={times} value={time} setValue={setTime} />
                                <div className="sfr__settings-season-label">SEASON</div>
                                <Select className="sfr__settings-season-select" items={seasons} value={season} setValue={setSeason} />
                                <div className="sfr__settings-weather-label">WEATHER</div>
                                <Select className="sfr__settings-weather-select" items={weathers} value={weather} setValue={setWeather} />
                                <div className="sfr__settings-scenery-label">SCENERY
                                    <div className='scenery-info' id='scenery-info'
                                        onMouseEnter={() => setHint({ id: 'scenery-info' })}
                                        onMouseLeave={() => setHint(null)} />
                                </div>
                                <Select className="sfr__settings-weather-select" items={sceneryArr} value={scenery} setValue={setScenery} placeholder='Choose scenery' />
                                <div className="sfr__settings-separator" />
                                <div className="sfr__settings-fn">
                                    <div>Atmosphere</div>
                                </div>
                                <div className='sfr__settings-fn-rangers'>
                                    <RangeInput lables={['Cold', 'Warm']} value={warm} setValue={setWarm} />
                                    <RangeInput lables={['Monochrome', 'Colorful']} value={colorful} setValue={setColorful} />
                                    <RangeInput lables={['Calming', 'Agitating']} value={agitating} setValue={setAgitating} />
                                    <RangeInput lables={['Geometric', 'Organic']} value={organic} setValue={setOrganic} />
                                    <RangeInput lables={['Functional', 'Decorative']} value={decorative} setValue={setDecorative} />
                                    <RangeInput lables={['Uniform', 'Versatile']} value={versatile} setValue={setVersatile} />
                                    <RangeInput lables={['Subtle', 'Dominant']} value={dominant} setValue={setDominant} />
                                    <RangeInput lables={['Smooth', 'Rough']} value={rough} setValue={setRough} />
                                    <RangeInput lables={['Natural', 'Artificial']} value={artificial} setValue={setArtificial} />
                                </div>
                                <div className="sfr__settings-separator" />
                                <div className="sfr__settings-weather-label">Primary color</div>
                                <ColorSelect className="sfr__settings-weather-select" items={colors} value={primaryColor} setValue={setPrimaryColor} placeholder='Choose primary color' />
                                <div className="sfr__settings-weather-label">Secondary color</div>
                                <ColorSelect className="sfr__settings-weather-select" items={colors} value={secondaryColor} setValue={setSecondaryColor} placeholder='Choose secondary color' />
                            </div>
                            <div className="sfr__checkout">
                                <div className="sfr__settings-resolution-label">RESOLUTION</div>
                                <div className="sfr__settings-resolution-radio">
                                    <Radio selected={resolutionType === ResolutionType.SCREEN} select={() => setResolutionType(ResolutionType.SCREEN)} />
                                    <div>Screen(px)</div>
                                </div>
                                <div className={classNames({ "sfr__settings-resolution-inputs": true, 'sfr__settings-resolution-inputs_disabled': resolutionType === ResolutionType.PRINT })}>
                                    <div className="sfr__settings-resolution-input-form">
                                        <div>WIDTH</div>
                                        <Input className="sfr__settings-resolution-input" type="number" value={widthPX} onChange={e => setWidthPX(e.currentTarget.value)} />
                                    </div>
                                    <div className="sfr__settings-resolution-input-form">
                                        <div>HEIGHT</div>
                                        <Input className="sfr__settings-resolution-input" type="number" value={heightPX} onChange={e => setHeightPX(e.currentTarget.value)} />
                                    </div>
                                    <Clip className='sfr__settings-resolution-clip' />
                                </div>
                                <div className="sfr__settings-resolution-radio">
                                    <Radio selected={resolutionType === ResolutionType.PRINT} select={() => setResolutionType(ResolutionType.PRINT)} />
                                    <div>Print(mm)</div>
                                </div>
                                <div className={classNames({ "sfr__settings-resolution-inputs": true, 'sfr__settings-resolution-inputs_disabled': resolutionType === ResolutionType.SCREEN })}>
                                    <div className="sfr__settings-resolution-input-form">
                                        <div>WIDTH</div>
                                        <Input className="sfr__settings-resolution-input" type="number" value={widthMM} onChange={e => setWidthMM(e.currentTarget.value)} />
                                    </div>
                                    <div className="sfr__settings-resolution-input-form">
                                        <div>HEIGHT</div>
                                        <Input className="sfr__settings-resolution-input" type="number" value={heightMM} onChange={e => setHeightMM(e.currentTarget.value)} />
                                    </div>
                                    <div className="sfr__settings-resolution-input-form sfr__settings-resolution-input-form-dpi">
                                        <div>DPI</div>
                                        <Select items={dpi} value={DPI} setValue={setDPI} />
                                    </div>
                                </div>
                                <div className='sfr__checkout-options'>
                                    <div className="sfr__checkout-option">
                                        <div>OBJECTS DEFINED</div>
                                        <div>{calcDefinedObjects} OBJECTS</div>
                                    </div>
                                    <div className="sfr__checkout-option">
                                        <div>IMAGE SIZE</div>
                                        <div>
                                            {widthPX} x {heightPX}
                                            {resolutionType === ResolutionType.PRINT && 'mm'}
                                            {resolutionType === ResolutionType.SCREEN && 'px'}
                                        </div>
                                    </div>
                                    <div className="sfr__checkout-option">
                                        <div>RENDERING TIME</div>
                                        <div>2h 35min 56sec</div>
                                    </div>
                                    <div className="sfr__checkout-option">
                                        <div>BOOST RENDERING SPEED</div>
                                        <Switcher value={boost} onClick={() => setBoost(prev => !prev)} />
                                    </div>
                                    <div className="sfr__checkout-option">
                                        <div>enable default resolution and speed to render for free</div>
                                        <Switcher value={isDefaultSettings} onClick={() => setIsDefaultSettings(prev => !prev)} />
                                    </div>
                                </div>
                                <div className="sfr__checkout-amount">
                                    <div>TOTAL AMOUNT</div>
                                    <div>32 tokens</div>
                                </div>
                                <div className="sfr__checkout-balance">
                                    <div>available balance</div>
                                    <div>10 tokens</div>
                                </div>
                                <div className="sfr__checkout-buy-tokens" onClick={() => setNotification(Notifications.NO_PAID_FEATURES_INFO)}>
                                    buy tokens
                                </div>
                                <Button className="sfr__checkout-button" color="dark" icon="right" onClick={submitForRender}>submit for Rendering</Button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

type RangeInputProps = {
    lables: [string, string],
    value: number,
    setValue: React.Dispatch<React.SetStateAction<number>>,
};

function RangeInput({ lables, value, setValue }: RangeInputProps) {
    return (
        <div className="sfr__range-input-container">
            <div className="sfr__range-input-labels">
                <div>{lables[0]}</div>
                <div className="sfr__range-input-label-points">
                    <div className="sfr__range-input-label-point" />
                    <div className="sfr__range-input-label-point" />
                    <div className="sfr__range-input-label-point" />
                </div>
                <div>{lables[1]}</div>
            </div>
            <Slider
                aria-label="time-indicator"
                size="small"
                value={value}
                min={1}
                max={5}
                step={1}
                onChange={(_, value) => setValue(value as number)}
                sx={{
                    color: 'black',
                    height: 3,
                    padding: '10px 0',
                    '& .MuiSlider-thumb': {
                        width: 12,
                        height: 12,
                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',

                        '&::before': {
                            boxShadow: 'none',
                        },
                        '&:hover, &.Mui-focusVisible': {
                            boxShadow: 'none',
                        },
                        '&.Mui-active': {
                            width: 12,
                            height: 12,
                        },
                    },
                    '& .MuiSlider-rail': {
                        color: '#AAA8A5',
                        height: '1px',
                    },
                }}
            />
        </div>
    );
}

function SubmissionSuccessful() {
    return (
        <div className="sfr__successful-wr">
            <CloseSVG className="sfr__close-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} />
            <div className="sfr__successful-title">Submission successful!</div>
            <p className="sfr__successful-text">A notification will be sent to <span className="highlight-span">account_name@mail.com</span> once the work is completed. All prototype renderings will be stored in the<span className="underline">Prototype Renderings List</span>.</p>
            <Button onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} icon="success">Ok</Button>
        </div>
    );
}
