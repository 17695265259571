import * as Listeners from '../../Listeners';
import { useEffect } from "react";

export default function useCursor(listener: Listeners.Listener) {
    useEffect(() => {
        if (listener instanceof Listeners.Select) {
            function keydown(event: KeyboardEvent) {
                const container = document.getElementById('canvas-container');
                if (!container) return;

                if (event.ctrlKey || event.metaKey) {
                    container.classList.add('cursor-add');
                }

                if (event.shiftKey) {
                    container.classList.add('cursor-remove');
                }
            }

            function keyup() {
                const container = document.getElementById('canvas-container');
                if (!container) return;

                container.classList.remove('cursor-add');
                container.classList.remove('cursor-remove');
            }

            document.addEventListener('keydown', keydown);
            document.addEventListener('keyup', keyup)
            return () => {
                document.removeEventListener('keydown', keydown);
                document.removeEventListener('keyup', keyup);
                keyup();
            };
        }
    }, [listener]);
}