import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import * as R from 'ramda';
import { $file, $intersection, recognizeFx, setFile, setIntersection } from 'entities/sketch';
import Process from './Modals/Process';
import Button from 'Components/Button';
import config from 'config';
import Header from 'Components/Header';
import MobileUpload from './Mobile/MobileUpload';
import { $filename, Notifications, setFileName, setNotification } from 'entities/notification';
import './Upload.scss';


export default function Upload() {
    const file = useStore($file);
    const fileName = useStore($filename)
    const [process, setProcess] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState(false);

    const checkEndSetFile = useCallback((file: File | undefined) => {
        const maxSize = 36 * 1024 * 1024;
        if (!file) throw new Error('File not exist.');
        setFileName(file.name)
        if (file.size > maxSize) return setNotification(Notifications.UPLOAD_SIZE_ERROR);
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) return setNotification(Notifications.UPLOAD_EXT_ERROR)
        setFile(file);
        setNotification(null)
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        setIsDragging(false);
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleUpload = useCallback(() => {
        const input = document.getElementById('input');
        if (!input) throw new Error('Input not found.');
        input.click();
    }, []);

    const dragLeave = useCallback(() => {
        setIsDragging(false);
    }, [])

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return console.error('Files not exist.');
        const file = files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleContinue = useCallback(() => {
        if (!file) return;
        recognizeFx(file);
        setProcess(true);
    }, [file]);

    useEffect(() => void setFile(null), []);

    const checkIsUpload = () => {
        if (file) setShowLeaveModal(true)
        else window.location.href = 'https://quarters-dev.site/';
    }

    return (
        <div className='upload_wrapper'>
            <MobileUpload />
            <Header onLogoClick={checkIsUpload} type='upload' />
            <div className="upload">
                <div className="upload__left-bar">
                    <Settings />
                </div>
                <div className='upload__mid-bar-wrapper'>
                    <div className="upload__mid-bar">
                        <div className={classNames({ "upload__mid-bar-dd": true, "upload__mid-bar-dd_dragging": isDragging })}
                            onDrop={handleDrop} onDragOver={handleDrag} onDragLeave={dragLeave}>
                            <input id="input" onChange={handleChange} accept='image/jpeg, image/jpg, image/png' type='file' hidden />
                            {file ? <LoadedImage file={file} /> : <DragAndDropSpace handleUpload={handleUpload} />}
                            {file && <LoadedImageData fileName={fileName} handleUpload={handleUpload} />}
                        </div>
                        <Button onClick={handleContinue} className="upload__continue-button" icon="right" disabled={Boolean(!file)}>CONTINUE</Button>
                    </div>
                </div>
            </div>
            {process && <Process />}
            {showLeaveModal &&
                <div className='upload__leave-modal'>
                    <div className='leave-modal'>
                        <div className='leave-modal__exit-button-container' />
                        <div className='leave-modal__title'>
                            Are you sure you want to leave?
                        </div>
                        <div className="leave-modal__text">You are about leaving current project…</div>
                        <div className='leave-modal__buttons-container'>
                            <button onClick={() => window.location.href = 'https://quarters-dev.site/'}>OK</button>
                            <button onClick={() => setShowLeaveModal(false)}>CANCEL</button>
                        </div>
                    </div>
                </div>}
        </div>
    );
}

type DragAndDropSpaceProps = {
    handleUpload: () => void;
};

function DragAndDropSpace({ handleUpload }: DragAndDropSpaceProps) {
    return (
        <>
            <div className="upload__mid-bar-dd-add">
                <div className="upload__mid-bar-dd-label">DRAG <span className='percent-icon'>&</span> DROP OR BROWSE DESIGN IMAGE TO RENDER</div>
                <button className='upload__mid-bar-dd-button' onClick={handleUpload}>BROWSE</button>
            </div>
            <div className='upload__mid-bar-dd-notion'>
                <p>Only files with the following<br /> extensions are allowed: png, jpg, jpeg</p>
                <p>36Mb limit<br />One file only</p>
                <div />
            </div>
        </>
    );
}

type LoadedImageProps = {
    file: File;
}

function LoadedImage({ file }: LoadedImageProps) {

    return (
        <img className="upload__mid-bar-dd-image" src={URL.createObjectURL(file)} alt="file" />
    );
}

type LoadedImageDataProps = {
    fileName: string;
    handleUpload: () => void;
}

function LoadedImageData({ fileName, handleUpload }: LoadedImageDataProps) {
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (textRef && textRef.current)
            setIsTruncated(textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth);
    }, [fileName]);

    return (
        <div className="upload__mid-bar-dd-image-data">
            <div className="upload__mid-bar-ddimage-name">
                <div className="upload__mid-bar-ddimage-name-first-part" ref={textRef}>{fileName}</div>
                {isTruncated && <div className="upload__mid-bar-ddimage-name-second-part">{fileName}</div>}
            </div>
            <div className="upload__mid-bar-ddimage-replace" onClick={handleUpload}>
                <div className="upload__mid-bar-ddimage-replace-icon" />
                <p>REPLACE FILE</p>
            </div>
        </div>
    );
}

function Settings() {
    const intersection = useStore($intersection);
    const [state, setState] = useState({
        points_per_side: 0,
        points_per_batch: 0,
        pred_iou_thresh: 0,
        stability_score_thresh: 0,
        stability_score_offset: 0,
        box_nms_thresh: 0,
        crop_n_layers: 0,
        crop_nms_thresh: 0,
        crop_overlap_ratio: 0,
        crop_n_points_downscale_factor: 0,
        min_mask_region_area: 0
    });

    useEffect(() => void fetch(config.serverUrl + '/settings').then(res => res.json()).then(setState), []);

    const handleApply = useCallback((value: typeof state) => fetch(config.serverUrl + '/settings', {
        method: 'POST',
        body: JSON.stringify(value),
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    }), []);

    const handleRestore = useCallback(() => {
        const state = {
            points_per_side: 32,
            points_per_batch: 64,
            pred_iou_thresh: 0.88,
            stability_score_thresh: 0.95,
            stability_score_offset: 1,
            box_nms_thresh: 0.7,
            crop_n_layers: 0,
            crop_nms_thresh: 0.7,
            crop_overlap_ratio: 0.3413,
            crop_n_points_downscale_factor: 1,
            min_mask_region_area: 0
        };

        setState(state);
        handleApply(state);
    }, [handleApply]);

    return (
        <div className="settings">
            <div className="settings_row">
                <div>points_per_side(int)</div>
                <input type="number" min={0} value={state.points_per_side} onChange={e => setState(R.assoc('points_per_side', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>points_per_batch(int)</div>
                <input type="number" min={0} value={state.points_per_batch} onChange={e => setState(R.assoc('points_per_batch', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>pred_iou_thresh[0,1]</div>
                <input type="number" min={0} max={1} value={state.pred_iou_thresh} onChange={e => setState(R.assoc('pred_iou_thresh', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>stability_score_thresh[0,1]</div>
                <input type="number" min={0} max={1} value={state.stability_score_thresh} onChange={e => setState(R.assoc('stability_score_thresh', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>stability_score_offset</div>
                <input type="number" value={state.stability_score_offset} onChange={e => setState(R.assoc('stability_score_offset', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>box_nms_thresh</div>
                <input type="number" value={state.box_nms_thresh} onChange={e => setState(R.assoc('box_nms_thresh', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>crop_n_layers(int)</div>
                <input type="number" value={state.crop_n_layers} onChange={e => setState(R.assoc('crop_n_layers', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>crop_nms_thresh</div>
                <input type="number" value={state.crop_nms_thresh} onChange={e => setState(R.assoc('crop_nms_thresh', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>crop_overlap_ratio</div>
                <input type="number" value={state.crop_overlap_ratio} onChange={e => setState(R.assoc('crop_overlap_ratio', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>crop_n_points_downscale_factor(int)</div>
                <input type="number" value={state.crop_n_points_downscale_factor} onChange={e => setState(R.assoc('crop_n_points_downscale_factor', Number(e.currentTarget.value)))} />
            </div>
            <div className="settings_row">
                <div>min_mask_region_area(int)</div>
                <input type="number" value={state.min_mask_region_area} onChange={e => setState(R.assoc('min_mask_region_area', Number(e.currentTarget.value)))} />
            </div>

            <button onClick={() => handleApply(state)}>apply</button>
            <button onClick={handleRestore}>restore</button>

            <div className="settings_row">
                <div>Intersection (%)</div>
                <input type="number" value={intersection} min={0} max={100} onChange={e => setIntersection(Number(e.currentTarget.value))} />
            </div>
        </div>
    );
}
