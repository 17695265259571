import Button from 'Components/Button';
import { useStore } from 'effector-react';
import { $email } from 'entities/user';
import { RoutePath, goTo } from 'entities/router';
import './Ending.scss';


export default function Ending() {
    const email = useStore($email);
    return (
        <div className="ending">
            <div className="ending__header">
                <div className="ending__header-logo" onClick={() => goTo(RoutePath.HOME)} />
                <div className="ending__header-email">{email}</div>
                <div className="ending__header-demo-tutorial">Demo tutorial</div>
            </div>
            <div className="ending__body">
                <div className="ending__paper">
                    <div className="ending__title">Thank you, Bob!</div>
                    <div className="ending__paragraph">We will contact you via <span className="ending__paragraph-email">{email}</span></div>
                    <div className="ending__paragraph">Once the rendering is ready, we will send you the result and a feedback form. The time it takes will depend on the complexity of the provided attachments.</div>
                    <div className="ending__paragraph">As a token of our gratitude for your generous help, you will receive access to the tech demo as soon as we rectify it. We will send you the link via email. While we do not have an exact timeframe, it is safe to assume that we will reach this goal within 3 months.</div>
                    <div className="ending__buttons">
                        <Button icon="left" onClick={() => goTo(RoutePath.HOME)} >Back to home page</Button>
                        <Button className="ending__second-buttons" onClick={() => goTo(RoutePath.HOME)} >Upload another case</Button>
                    </div>
                </div>
            </div>
        </div >
    );
}