import { sample } from 'effector';
import { attachErrorEvent, errorEvent } from '.';
import { recognizeFx, loadMaskFx } from 'entities/sketch';
import { loadModelsFx, uploadFx } from 'entities/library';

sample({
    clock: [
        loadMaskFx.fail,
        recognizeFx.fail,
        loadModelsFx.fail,
        // uploadFx.fail
    ],
    target: errorEvent,
});


sample({
    clock: uploadFx.fail,
    target: attachErrorEvent,
});