import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from 'Components/Button';
import Input from 'Components/Input';
import Checkbox from 'Components/Checkbox';
import Select from 'Components/Select';
import InputPhone from 'Components/PhoneInput';
import { Notifications, setFileName, setNotification } from 'entities/notification';
import { setHint } from 'entities/hint';
import { useStore } from 'effector-react';
import { $email, $userData, deleteUserFx, updateUserDataFx } from 'entities/user';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import './PersonalSettings.scss';


const professions = [{ key: 'Architecture', value: 'Architecture' }, { key: 'Interior design', value: 'Interior design' }, { key: 'Furniture design', value: 'Furniture design' }, { key: 'Industrial design', value: 'Industrial design' }, { key: 'Landscape design', value: 'Landscape design' }, { key: 'Visualization (CG)', value: 'Visualization (CG)' }, { key: 'Other', value: 'Other' }];
const teamMembers = [{ key: '1-10', value: '1-10' }, { key: '11-50', value: '11-50' }, { key: '50+', value: '50+' }];
const softwareArr = [{ key: 'Autodesk Revit', value: 'Autodesk Revit' }, { key: 'Navisworks', value: 'Navisworks' }, { key: 'STAAD.Pro', value: 'STAAD.Pro' }, { key: 'Rhino', value: 'Rhino' }, { key: 'SketchUp Pro', value: 'SketchUp Pro' }, { key: 'AutoCAD', value: 'AutoCAD' }, { key: 'Civil 3D', value: 'Civil 3D' }, { key: 'Cedreo', value: 'Cedreo' }, { key: 'ArchiCAD', value: 'ArchiCAD' }];

export default function PersonalSettings() {
    const [isDeletingInProcess, setIsDeletingInProcess] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const checkEndSetFile = useCallback((file: File | undefined) => {
        const maxSize = 36 * 1024 * 1024;

        if (!file) throw new Error('File not exist.');
        setFileName(file.name)
        if (file.size > maxSize) return setNotification(Notifications.UPLOAD_SIZE_ERROR)

        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) return setNotification(Notifications.UPLOAD_EXT_ERROR)

        setFile(file);
        setNotification(null)
    }, []);

    const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        setIsDragging(false);
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleUpload = useCallback(() => {
        const input = document.getElementById('user_photo_input');
        if (!input) throw new Error('Input not found.');
        input.click();
    }, []);

    const dragLeave = useCallback(() => setIsDragging(false), []);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return console.error('Files not exist.');
        const file = files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const deleteUser = useCallback(() => {
        deleteUserFx();
        setLeftSidePanel(LeftSidePanel.NONE);
    }, []);

    return (
        <div className="pesonal-settings">
            <div className="pesonal-settings-photo_wrapper">
                <div className="user_photo_container">
                    <div className={classNames({ "user_photo": true, "user_photo_dragging": isDragging })}
                        onDrop={handleDrop}
                        onDragOver={handleDrag}
                        onDragLeave={dragLeave}>
                        <input id="user_photo_input" onChange={handleChange} accept='image/jpeg, image/jpg, image/png' type='file' hidden />

                        {file ? <img src={URL.createObjectURL(file)} alt={file.name} /> :
                            <div className="user_photo-icon" />}
                    </div>
                    <div className="user_photo-settings">
                        <div className="user_photo-settings-upload"
                            onClick={handleUpload}
                        >
                            {file ? <>
                                <div className="replace-photo_icon" />
                                <p>Replace photo</p>
                            </> : <>
                                <div className="upload-photo_icon" />
                                <p>Upload photo</p>
                            </>}

                            <div
                                className="upload-photo_info"
                                id="account_photo-info"
                                onMouseEnter={() => setHint({id:'account_photo-info'})}
                                onMouseLeave={() => setHint(null)}
                            />
                        </div>
                        {file &&
                            <div className="user_photo-settings-upload" onClick={() => setFile(null)}>
                                <div className="remove-photo-icon" />
                                <p>remove</p>
                            </div>}
                    </div>
                </div>
                <SettingsInfoBox />
            </div>
            <div className="pesonal-settings-form_wrapper">
                <div className="personal_setting-info">
                    {isEdit ?
                        <AccountForm close={() => setIsEdit(false)} /> :
                        <>
                            <AccountInfo />
                            <div className="personal_setting-bttns">
                                {isDeletingInProcess ?
                                    <div className="delete_account-process">
                                        <p>Are you sure you want to delete your account?</p>
                                        <div className="delete_account-process-bttns">
                                            <Button onClick={deleteUser}>Delete</Button>
                                            <Button onClick={() => setIsDeletingInProcess(false)}>Cancel</Button>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="account_bttn" onClick={() => setIsEdit(true)} >
                                            <div className="edit_account-icon" />
                                            <p>Edit Details</p>
                                        </div>
                                        <div className="account_bttn" >
                                            <div className="logout-icon" />
                                            <p>Log out</p>
                                        </div>
                                        <div className="account_bttn" onClick={() => setIsDeletingInProcess(true)}>
                                            <div className="delete_account-icon" />
                                            <p>Delete account</p>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

function SettingsInfoBox() {
    const [applied, setIsApplied] = useState<boolean>(false)
    return (
        <div className="pesonal-settings-infobox">
            <div className="pesonal-settings-infobox-icon">
                <div className="infobox-icon_circle" />
            </div>
            <div className="pesonal-settings-infobox-hint">
                {applied ?
                    <div className="infobox-hint_applied">
                        <div className="infobox-hint_applied-ok" />
                        <p>You are on the waitlist to access io-buro <span className="beta_span">Beta</span></p>
                    </div>
                    :
                    <>
                        <p>We are planning to launch the fully featured io-BURO Beta in late 2024.</p>
                        <p>Spots are capped. We are particularly interested in team applications. To increase your chances, please provide your business details.</p>
                        <p>As a courtesy to those willing to participate in occasional test sessions and feedback surveys, we plan to offer generous trial periods and special pricing offers.</p>
                        <button color="dark" className="pesonal-settings-infobox-hint-bttn" onClick={() => setIsApplied(true)}>
                            <p>Apply for early access to io-buro <span className="beta_span">Beta</span></p>
                        </button>
                    </>
                }
            </div>
            <div />
        </div>
    )
}

type AccountFormProps = {
    close: () => void;
};

function AccountForm({ close }: AccountFormProps) {
    const userData = useStore($userData);
    const email = useStore($email);
    const [isDeletingInProcess, setIsDeletingInProcess] = useState(false);
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [profession, setProfession] = useState(userData.profession);
    const [businessWebsiteLink, setBusinessWebsiteLink] = useState(userData.businessWebsiteLink);
    const [isSameEmail, setIsSameEmail] = useState(false);
    const [businessName, setBusinessName] = useState(userData.businessName);
    const [businessEmail, setBusinessEmail] = useState(userData.businessEmail);
    const [teamSize, setTeamSize] = useState(userData.teamSize);
    const [linkedin, setLinkedin] = useState(userData.linkedin);
    const [earlyDesignSoftware, setEarlyDesignSoftware] = useState(userData.earlyDesignSoftware);
    const [businessPhone, setBusinessPhone] = useState(userData.businessPhone);

    if (!email) throw new Error('Something went wrong.');

    useEffect(() => setBusinessEmail(isSameEmail ? email : userData.businessEmail), [email, isSameEmail, userData]);

    const updateUserData = useCallback(() => {
        updateUserDataFx({
            firstName,
            lastName,
            businessName,
            profession,
            businessPhone,
            businessEmail,
            businessWebsiteLink,
            teamSize,
            linkedin,
            earlyDesignSoftware,
            subscribeNewsletter: userData.subscribeNewsletter,
        });
        close();
    }, [firstName, lastName, businessName, profession, businessPhone, businessEmail, businessWebsiteLink, teamSize, linkedin, earlyDesignSoftware, userData, close]);

    const deleteUser = useCallback(() => {
        deleteUserFx();
        setLeftSidePanel(LeftSidePanel.NONE);
    }, []);

    return (
        <div className="basic_user_info-wrapper">
            <div className="settings-form_item-email">
                <div className="email-wrappper">
                    <p>EMAIL</p>
                    <div className="form-email">mail@mail.com</div>
                </div>
                <Button className="update_email-bttn">update email</Button>
            </div>
            <div className="form-inputs_wrapper">
                <div className="form-input">
                    <p>first Name</p>
                    <Input value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} placeholder="Enter your first name" />
                </div>
                <div className="form-input">
                    <p>last Name</p>
                    <Input value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} placeholder="Enter your last name" />
                </div>
            </div>
            <div className="basic_user_info">
                <div className="basic_user_info-section-separator">
                    <p>business details</p>
                </div>
                <div className="form-inputs_wrapper">
                    <div className="form-input">
                        <p>Business name</p>
                        <Input value={businessName} onChange={(e) => setBusinessName(e.currentTarget.value)} placeholder="Enter your business name" />
                    </div>
                    <div className="form-input">
                        <p>Profession</p>
                        <Select items={professions} value={profession} setValue={setProfession} placeholder="Select your profession" />
                    </div>

                    <div className="form-input">
                        <p>Business phone</p>
                        <InputPhone value={businessPhone} setValue={setBusinessPhone} />

                    </div>
                    <div className="form-input">
                        <p>Business website link</p>
                        <Input value={businessWebsiteLink} onChange={(e) => setBusinessWebsiteLink(e.currentTarget.value)} placeholder="Enter your website" />
                    </div>
                    <div className="form-input">
                        <p>Business email</p>
                        <div className="form_checkbox-wrapper">
                            <Checkbox checked={isSameEmail} onChange={setIsSameEmail} className="form_checkbox" />
                            <p>Same as account email</p>
                        </div>
                        <Input value={businessEmail} onChange={(e) => setBusinessEmail(e.currentTarget.value)} placeholder="Enter your business email" disabled={isSameEmail} type={'email'} />
                    </div>

                    <div className="form-input">
                        <p>Team members</p>
                        <Select items={teamMembers} value={teamSize} setValue={setTeamSize} placeholder="Select team members" />
                    </div>
                    <div className="form-input">
                        <p>Linkedin</p>
                        <Input value={linkedin} onChange={(e) => setLinkedin(e.currentTarget.value)} placeholder="Enter your LinkedIn link" />
                    </div>

                    <div className="form-input">
                        <p>software you use most frequently in early design development</p>
                        <Select items={softwareArr} value={earlyDesignSoftware} setValue={setEarlyDesignSoftware} placeholder="Select a software you use" />
                    </div>
                </div>
                {isDeletingInProcess ?
                    <div className="delete_account-process">
                        <p>Are you sure you want to delete your account?</p>
                        <div className="delete_account-process-bttns">
                            <Button onClick={deleteUser}>Delete</Button>
                            <Button onClick={() => setIsDeletingInProcess(false)}>Cancel</Button>
                        </div>
                    </div>
                    :
                    <>
                        <div className="account_bttn" onClick={() => setIsDeletingInProcess(true)}>
                            <div className="delete_account-icon" />
                            <p>Delete account</p>
                        </div>
                        <div className="account_bttn" onClick={updateUserData}>
                            <div className="delete_account-icon" />
                            <p>Save</p>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

function AccountInfo() {
    const userData = useStore($userData);
    const email = useStore($email);

    return (
        <>
            <div className="personal_setting-info-item">
                <div className="personal_setting-info-item-title">
                    <p>Personal details</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">First name</div>
                    <p>{userData.firstName}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Last name</div>
                    <p>{userData.lastName}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">email</div>
                    <p>{email}</p>
                </div>
            </div>

            <div className="personal_setting-info-item">
                <div className="personal_setting-info-item-title">
                    <p>Business details</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Business name</div>
                    <p>{userData.businessName}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Profession</div>
                    <p>{userData.profession}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Business phone</div>
                    <p>{userData.businessPhone}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Business website link</div>
                    <p>{userData.businessWebsiteLink}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Business email</div>
                    <p>{userData.businessEmail}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">team members</div>
                    <p>{userData.teamSize}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">LinkedIN</div>
                    <p>{userData.linkedin}</p>
                </div>
                <div className="details_item">
                    <div className="details_item-title">Software preferences</div>
                    <p>{userData.earlyDesignSoftware}</p>
                </div>
            </div>
        </>
    );
}
