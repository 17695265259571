import { useCallback } from 'react';
import { closeModal } from 'entities/modal';
import { ReactComponent as CrossSvg } from './icons/cross.svg';
import Button from 'Components/Button';
import './Error.scss';


interface ErrorProps {
    type?: 'attachment' | '';
}

export default function Error({ type }: ErrorProps) {
    const confirm = useCallback(() => {
        if (type && type === 'attachment') return closeModal()
        // localStorage.removeItem('sketches');
        const sketchData = localStorage.getItem('sketches');
        let sketchDataArray = sketchData ? JSON.parse(sketchData) : [];
        localStorage.setItem('sketches', JSON.stringify(sketchDataArray));
        window.location.reload();

    }, [type]);

    return (
        <div className="error-modal__container">
            <div className="error-modal">
                <div className="error-modal__exit-button-container">
                    <CrossSvg className="error-modal__exit-button" onClick={() => closeModal()} />
                </div>
                <div className="error-modal__title">
                    {type && type === 'attachment' ? 'Something went wrong' : 'Unknown error'}
                </div>
                <div className="error-modal__text">
                    {type && type === 'attachment' ? 'Please try again later' : ' Please, create a new sketch!'}

                </div>
                <div className="error-modal__buttons-container">
                    <Button onClick={confirm}>OK</Button>
                </div>
            </div>
        </div>
    );
}