import { useCallback, useState } from 'react';
import config from 'config';
import { ReactComponent as FacebookIcon } from './icons/facebook.svg';
import { ReactComponent as Twitter } from './icons/twitter.svg';
import { ReactComponent as In } from './icons/in.svg';
import { ReactComponent as Inst } from './icons/inst.svg';
import { ReactComponent as CopyLink } from './icons/copyLink.svg';
import { ShareRenderModal, closeModal } from 'entities/modal';
import './Share.scss';


enum ShareSoc {
    FACEBOOK = 'FACEBOOK',
    TWITTER = 'TWITTER',
    LINKEDIN = 'LINKEDIN',
    INST = 'INST',
}

const shareItems = [
    {
        icon: <FacebookIcon className="share_icon" />,
        type: ShareSoc.FACEBOOK,
    },
    {
        icon: <Twitter className="share_icon" />,
        type: ShareSoc.TWITTER,
    },
    {
        icon: <In className="share_icon" />,
        type: ShareSoc.LINKEDIN,
    },
    {
        icon: <Inst className="share_icon" />,
        type: ShareSoc.INST,
    },
];

export default function Share({ id }: ShareRenderModal['props']) {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    const handleCopyLink = useCallback(() => {
        navigator.clipboard.writeText(config.backendUrl + '/api/render/' + id + '.png')
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
    }, [id]);

    const handleLinkClick = (soc: ShareSoc) => {
        const url = window.location.origin;
        const text = (encodeURIComponent('This is awesome!'))

        switch (soc) {
            case ShareSoc.FACEBOOK: {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
                break;
            }
            case ShareSoc.TWITTER: {
                window.open(`https://twitter.com/share?url=${url}&text=${text}`, '_blank');
                break;
            }
            case ShareSoc.LINKEDIN: {
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank');
                break;
            }
        }
    }

    return (
        <div className="error-modal__container ">
            <div className="share_modal-wrapper">
                <div className="share_modal-close_icon" onClick={() => closeModal()} />
                <div className="share_modal">
                    <div className="share_icons_wrapper">
                        {shareItems.map((soc) => (
                            <div className="share_icons_item"
                                onClick={() => handleLinkClick(soc.type)}>
                                {soc.icon}
                            </div>
                        ))}
                    </div>
                    <div className={isCopied ? 'copy_link_wrapper_copyed' : 'copy_link_wrapper'}
                        onClick={() => handleCopyLink()}>
                        <CopyLink className="copy_link_icon" />
                        {isCopied ? 'Link copied!' : 'Copy link'}
                    </div>
                </div >
            </div>
        </div>
    );
}
