import { Attachment, Segment } from "entities/sketch/Manager";
import { useEffect, useState, RefObject } from "react";

interface UseChildElementsHeightProps {
  ref: RefObject<HTMLDivElement | null>;
  target?: Attachment[] | Segment[] | boolean;
}

function useChildElementsHeight({ ref, target }: UseChildElementsHeightProps) {
  const [height, setHeight] = useState<number>(0);


  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        const children = ref.current.children;
        let totalHeight = 0;

        for (let i = 0; i < children.length; i++) {
          const child = children[i] as HTMLElement;
          totalHeight += child.offsetHeight;

          if (child.children.length > 0) {
            for (let j = 0; j < child.children.length; j++) {
              const grandchild = child.children[j] as HTMLElement;
              totalHeight += grandchild.offsetHeight;
            }
          }
        }

        setHeight(totalHeight);
      }
    };

    updateHeight();
    
    const observer = new MutationObserver(updateHeight);
    if (ref.current) {
      observer.observe(ref.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [ref,target]);

  return height;
}

export default useChildElementsHeight;