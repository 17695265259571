import { createEvent, createStore } from "effector";
import { setTutorialHint } from "entities/hint";

export interface TutorialI {
  step: number;
  elementsIds: string[];
  prevElementsIds: string[];
  tooltipText: string[];
}

export interface PrevSketchStateI {
  showUndefined: boolean;
    hideMasks: boolean;
}

export const enum TutorialDisabledReasons {
  NO_ELEMENTS = "NO_ELEMENTS",
  IS_EDIT_MODE = "IS_EDIT_MODE",
  NONE = "NONE",
}

export const tutorialItems: TutorialI[] = [
  {
    step: 1,
    elementsIds: ["left-bar__button_chair", "list__head"],
    prevElementsIds: ["help"],
    tooltipText: [
      "indicate objects on the image creating or editing object masks and define them in the table adding references and links to render your idea realistically",
    ],
  },
  {
    step: 2,
    elementsIds: ["left-bar__button_chair"],
    prevElementsIds: [
      // "left-bar__button_chair",
      "list__head",
    ],
    tooltipText: [
      "indicate objects on the image creating or editing object masks and define them in the table adding references and links to render your idea realistically ",
    ],
  },
  {
    step: 3,
    elementsIds: ["left-bar__button_chair"],
    prevElementsIds: [],
    tooltipText: ["hover and wait for extra tooltips"],
  },
  // {
  //   step: 4,
  //   elementsIds: [],
  //   prevElementsIds: ["left-bar__button_chair"],
  //   targetElement: 'cell5-0',
  //   tooltipText: [
  //     `One important feature to note is the lock switch.`,
  //     `When you indicate new objects in the viewport, the AI may try to refine the outlines of every present object mask, unless they are locked. `,
  //     `To prevent undesired changes, make sure to lock the mask of any object that you want to keep untouched.`,
  //   ],
  // },
  {
    step: 4,
    elementsIds: ["left-bar__buttons_wrapper"],
    prevElementsIds: ["left-bar__button_chair"],
    tooltipText: ["hover and wait for extra tooltips"],
  },
  {
    step: 5,
    elementsIds: ["help"],
    prevElementsIds: ["left-bar__buttons_wrapper", "help"],
    tooltipText: ["hover and wait for extra tooltips"],
  },
  // {
  //   step: 0,
  //   elementsIds: ["help"],
  //   prevElementsIds: ["left-bar__buttons_wrapper"],
  //   targetElement: 'sketch__continue-button',
  //   tooltipText: [
  //     'hover and wait for extra tooltips'
  //    ],
  // },
];
//help
export const $tutorial = createStore<TutorialI>(initTutorialState());
export const $tutorialDisabled = createStore<TutorialDisabledReasons>(TutorialDisabledReasons.NONE)
export const $prevSketchState = createStore<PrevSketchStateI>({showUndefined: false, hideMasks: false})
export const next = createEvent();
export const prev = createEvent();
export const skip = createEvent();
export const setTutorial = createEvent();
export const setTutorialDisabled = createEvent<TutorialDisabledReasons>()
export const setPrevSketchState = createEvent<PrevSketchStateI>()

$tutorial
  .on(setTutorial, (state) => {
    const nextStep = 1;
    const tutorialItem = tutorialItems.find((item) => item.step === nextStep);
    if (tutorialItem) {
      const { elementsIds, tooltipText, prevElementsIds } = tutorialItem;
      return { step: nextStep, elementsIds, prevElementsIds, tooltipText };
    }
    return state;
  })

  .on(next, (state) => {
    const nextStep = state.step + 1;
    const tutorialItem = tutorialItems.find((item) => item.step === nextStep);
    if (tutorialItem) {
      const { elementsIds, tooltipText, prevElementsIds } = tutorialItem;
      return { step: nextStep, elementsIds, prevElementsIds, tooltipText };
    }
    return {
      step: 0,
      elementsIds: [],
      prevElementsIds: state.elementsIds,
      targetElement: undefined,
      tooltipText: [],
    };
  })
  .on(prev, (state) => {
    const prevStep = state.step - 1;
    const tutorialItem = tutorialItems.find((item) => item.step === prevStep);
    if (tutorialItem) {
      const { elementsIds, tooltipText, prevElementsIds } = tutorialItem;
      return { step: prevStep, elementsIds, prevElementsIds, tooltipText };
    }
  })
  .on(skip, (state) => {
    return {
      step: 0,
      elementsIds: [],
      prevElementsIds: state.elementsIds,
      targetElement: undefined,
      tooltipText: [],
    };
  });

$tutorial.watch((tutorial) => {
  if (tutorial.step === 4) {
    setTutorialHint("left-bar__icon1");
  }
});

$tutorialDisabled.on(setTutorialDisabled,(_, tutorialDisabled)=>{return tutorialDisabled})
$prevSketchState.on(setPrevSketchState, (_, prevSketchState)=>{return prevSketchState})

function initTutorialState() {
  // return tutorialItems[0];
  // const rawCompleted = localStorage.getItem('show_tutorial');
  // if (!rawCompleted) return tutorialItems[0];
  // const completed = JSON.parse(rawCompleted);

  // if (completed) return { step: 0, elementsIds: [], prevElementsIds: [], targetElement: undefined, tooltipText: [] };
  // else return tutorialItems[0];
  return {
    step: 0,
    elementsIds: [],
    prevElementsIds: [],
    targetElement: undefined,
    tooltipText: [],
  };
}
