import classNames from 'classnames';
import { ReactComponent as Left } from './icons/left.svg';
import { ReactComponent as Right } from './icons/right.svg';
import { ReactComponent as Success } from './icons/success.svg';
import { ReactComponent as SuccessSmall } from './icons/success-small.svg';
import { ReactComponent as Cross } from './icons/cross.svg';
import { ReactComponent as Add } from './icons/add.svg'
import './Button.scss';


type ButtonProps = {
    className?: string;
    disabled?: boolean;
    children?: string;
    onClick?: () => void;
    id?: string;
    size?: 'primary' | 'secondary';
    icon?: 'right' | 'success' | 'left' | 'cross' | 'add';
    color?: 'dark' | 'white'
};

export default function Button({ disabled = false, children = '', className = '', id,color='white', size = 'primary', onClick, icon }: ButtonProps) {
    return (
        <button
            className={classNames({
                button: true,
                ['button-' + size]: true,
                ['button-'+ color]: true,
                ['button-' + size + '-with-icon']: Boolean(icon),
                [className]: Boolean(className),
            })}
            id={id}
            disabled={disabled}
            onClick={onClick}
        >
            {icon === 'left' && <Left />}
            {icon === 'add' && <Add/>}
            {icon === 'cross' && <Cross/>}
            {icon === 'success' && (
                size === 'secondary'
                    ?
                    <SuccessSmall />
                    :
                    <Success />
            )}
            {children}
            {icon === 'right' && <Right />}
        </button>
    );
}
