import classNames from 'classnames';
import { ReactComponent as RadioSvg } from './icons/radio.svg';
import './Radio.scss';


type RadioProps = {
    selected: boolean;
    select?: () => void;
    className?: string;
};

export default function Radio({ selected, select, className = '' }: RadioProps) {
    return (
        <RadioSvg className={classNames({ 'radio': true, 'radio_selected': selected, [className]: className })} onClick={select} />
    );
}