import classNames from 'classnames';
import RadioButton from 'Components/Radio';
import './Radio.scss';


type RadioProps = {
    className?: string;
    collections: Array<string>;
    value: string | undefined;
    setValue: (value: string) => void;
};

export default function Radio({ className, collections, value, setValue }: RadioProps) {
    return (
        <div className={classNames('sign-up-step-one-radio', className)}>
            {
                collections.map(option => <Button key={option} value={option} selected={value === option} select={value => setValue(value)} />)
            }
        </div>
    );
}

type ButtonProps = {
    value: string;
    selected: boolean;
    select: (value: string) => void;
};

function Button({ value, selected, select }: ButtonProps) {
    return (
        <div className="sign-up-step-one-radio-button" onClick={()=>select(value)}>
            <RadioButton selected={selected} />
            <div className="sign-up-step-one-radio-button__label">{value}</div>
        </div>
    );
}
