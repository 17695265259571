import { ActionType, Group, Manager } from 'entities/sketch/Manager';


export class Listener {
    constructor(protected readonly manager: Manager) {
        manager.unhoverSegment();
    }
    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) { }
    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onDoubleClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) { }
    public onMouseLeave() { }
}

export class Zoom extends Listener {
    private x = 0;
    private y = 0;
    public setGrabbing: (value: boolean) => void = () => undefined;
    #dragging = false;

    get dragging() {
        return this.#dragging;
    }
    set dragging(value: boolean) {
        this.#dragging = value;
        this.setGrabbing(value);
    }

    public onMouseDown = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) => {
        this.x = e.clientX;
        this.y = e.clientY;
        this.dragging = true;
    }

    public async onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (!this.dragging) return;
        const { clientX, clientY } = e;
        const { width, height } = e.currentTarget;
        this.manager.translate([
            (clientX - this.x) / width,
            (this.y - clientY) / height,
        ]);
        this.x = clientX;
        this.y = clientY;
    };

    public async onMouseLeave() {
        this.dragging = false;
    }

    public onMouseUp() {
        this.dragging = false;
    }
}

export abstract class Point extends Listener {
    protected abstract type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT;

    public async onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        await this.manager.segmentEditor.showPoint(e.nativeEvent.offsetX, e.nativeEvent.offsetY, this.type)
    };

    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.manager.segmentEditor.addPoint(e.nativeEvent.offsetX, e.nativeEvent.offsetY, this.type);
    }

    public async onMouseLeave() {
        this.manager.segmentEditor.noPreview();
    }
}

export class PositivePoint extends Point {
    type: ActionType.POSITIVE_POINT = ActionType.POSITIVE_POINT;
}

export class NegativePoint extends Point {
    type: ActionType.NEGATIVE_POINT = ActionType.NEGATIVE_POINT;
}

export class BoxListener extends Listener {
    private beginX = 0;
    private beginY = 0;
    private drawing = false;

    private calcPoint(e: React.MouseEvent<HTMLDivElement | HTMLCanvasElement, MouseEvent>) {
        const element = document.getElementById('canvas');
        if (!element) throw new Error('Canvas not found.');

        const rect = element.getBoundingClientRect();

        const distanceX = e.clientX - (rect.left + window.scrollX);
        const distanceY = e.clientY - (rect.top + window.scrollY);

        const x = Math.max(Math.min(element.offsetWidth, distanceX), 1);
        const y = Math.max(Math.min(element.offsetHeight, distanceY), 1);
        return { x, y };
    }

    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const { x, y } = this.calcPoint(e);
        this.beginX = x;
        this.beginY = y;
        this.drawing = true;
    }

    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (!this.drawing) return;
        const { x, y } = this.calcPoint(e);
        this.manager.segmentEditor.showBox([this.beginX, this.beginY, x, y]);
    }

    public onMouseUp(e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) {
        if (!this.drawing) return;
        const { x, y } = this.calcPoint(e);
        this.manager.segmentEditor.addBox([this.beginX, this.beginY, x, y]);
        this.beginX = 0;
        this.beginY = 0;
        this.drawing = false;
    }

    public onMouseLeave(): void {
        this.beginX = 0;
        this.beginY = 0;
        this.drawing = false;
        this.manager.segmentEditor.hideBox();
    }
}

export abstract class BrushToolListener extends Listener {
    private drawing = false;

    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.drawing = true;
        this.onMouseMove(e);
    }

    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.manager.setCursor([e.nativeEvent.offsetX, e.nativeEvent.offsetY]);
        if (!this.drawing) return;
        if (e.buttons !== 1) return;
        this.manager.segmentEditor.draw(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    }

    public onMouseUp() {
        if (!this.drawing) return;
        this.drawing = false;
        this.manager.segmentEditor.endDraw();
    }

    public onMouseLeave(): void {
        this.manager.setCursor(null);
        if (!this.drawing) return;
        this.drawing = false;
        this.manager.segmentEditor.endDraw();
    }
}

export class EraserListener extends BrushToolListener {
    constructor(manager: Manager) {
        super(manager);
        manager.segmentEditor.brushAlfa = 0;
    }
}

export class BrushListener extends BrushToolListener {
    constructor(manager: Manager) {
        super(manager);
        manager.segmentEditor.brushAlfa = 1;
    }
}

export class Select extends Listener {
    private overDrawing = false;
    private selectBoxBegin: [number, number] | null = null;
    private x = 0;
    private y = 0;
    public delta: number | null = null;

    private calculateDistance(x1: number, y1: number, x2: number, y2: number): number {
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;
        return Math.sqrt(deltaX ** 2 + deltaY ** 2);
    }

    public onMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        this.selectBoxBegin = [e.nativeEvent.offsetX, e.nativeEvent.offsetY];
    }

    public onMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        if (this.delta === null) {
            this.selectBoxBegin = null;
            const segment = this.manager.hoveredElement;
            if (!segment) return this.manager.unselectAllElements();
            if (e.shiftKey) return this.manager.unselectElement(segment);
            const element = document.getElementById('element_' + segment.id);
            element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
            if (e.ctrlKey || e.metaKey) return this.manager.selectElement(segment);
            return this.manager.selectOneElement(segment);
        } else {
            this.manager.selectSegmentsWithBox();
        }

        this.manager.setSelectBox(null);
        this.selectBoxBegin = null;
        this.delta = null;
        this.manager.deltaFrame = this.delta;
    }

    public onDoubleClick(): void {
        const element = this.manager.hoveredElement;
        if (!element || element instanceof Group) return;
        this.manager.segmentEditor.editSegment(element);
    }

    public onMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        this.overDrawing = true;
        this.x = e.nativeEvent.offsetX;
        this.y = e.nativeEvent.offsetY;

        if (this.selectBoxBegin !== null) {
            const delta = this.calculateDistance(...this.selectBoxBegin, this.x, this.y);
            if (delta > 5) {
                this.delta = delta * Math.sign(this.x - this.selectBoxBegin[0]);
                this.manager.deltaFrame = this.delta;
                this.manager.setSelectBox([...this.selectBoxBegin, this.x, this.y]);
                console.time('calc');
                this.manager.selectSegmentsWithBox();
                console.timeEnd('calc');
                return;
            }
        }

        this.manager.hoverSegment(this.x, this.y);
    }

    public onMouseLeave(): void {
        this.overDrawing = false;
        this.manager.unhoverSegment();
    }

    public handleTab() {
        if (!this.overDrawing) return;
        this.manager.tabHover(this.x, this.y);
    }
}

export class EditGroup extends Select {
    public onClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        let adding: boolean | null = null;
        if (e.ctrlKey) adding = true;
        if (e.shiftKey) adding = false;
        if (e.ctrlKey && e.shiftKey) adding = null;
        if (adding === null) return;
        this.manager.editGroupHandler(adding);
    }

    public onDoubleClick(): void { }

    public onMouseDown(): void { }

    public onMouseUp(): void { }
}
