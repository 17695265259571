import { useEffect, useState, RefObject } from "react";

export default function useScrollDetection({ref}: {ref: RefObject<HTMLDivElement | null>}) {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let scrollTimeout: NodeJS.Timeout;
        const element = ref.current;
        if (element) {
            const handleScroll = (): void => {
                if (scrollTimeout) clearTimeout(scrollTimeout)

                const scrollTop = element.scrollTop;
                const scrollHeight = element.scrollHeight;
                const clientHeight = element.clientHeight;
                const isFullyScrolled = scrollTop + clientHeight > scrollHeight;
                setIsScrolling(!isFullyScrolled);

                scrollTimeout = setTimeout(() => {
                    setIsScrolling(false);
                }, 1000);
            };
            element.addEventListener('scroll', handleScroll);
            return () => {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }
                element.removeEventListener('scroll', handleScroll);
            };
        }

    }, [ref]);

    return isScrolling;
}
