import { SectionItemI } from "./helpers";

const getText = (item: SectionItemI) => {
    switch (item.title) {
        case 'Tooltips':
            return <p>Hover over a button to see the tooltip. Pause the cursor to explore tooltip details.</p>;
        case 'Viewport Navigation':
            return (
                <>
                    <p>Use the <span className="highlight-span">+</span> and <span className="highlight-span">-</span> keys to zoom in and out of the image. Click <span className="highlight-span">Fit</span> to adjust the image to the viewport.</p>
                    <p>Toggle <span className="highlight-span">Hide masks</span> to view the image without masks.</p>
                    <p>Click on the <span className="highlight-span">color circle</span> in the row of the object to refresh the mask color. The color will change randomly; click until you find a satisfactory color.</p>
                    <p>Switch between <span className="highlight-span">Select masks</span> and <span className="highlight-span">Hand tool</span> to navigate the viewport.</p>
                    <p>Press and hold the spacebar or scroll wheel to temporarily enable the <span className="highlight-span">Hand tool</span>.</p>
                </>
            )
        case 'Indicate Objects You Intend to Define':
            return (
                <>
                    <p>Masks are the areas of the image where AI will draw the objects you define. Use <span className="highlight-span">Create/Edit object mask</span> tool to indicate objects you intend to define in the image.</p>
                    <p>To join masks together, select the desired masks and click <span className="highlight-span">Join masks</span>. Object definitions will be combined.</p>
                    <p>To <span className="highlight-span">Delete object mask</span>, hover over the object row and click the appearing delete button, or select the checkboxes of the masks to delete and press the Delete key or button.</p>
                </>
            )
        case 'Define Indicated Objects':
            return (
                <>
                    <p>Use the table to define the indicated objects. Definition tells the AI what to draw in the mask.</p>
                    <p>Attachments used to define objects will appear as indented rows under the defined object or group in the table.</p>
                    <p>To <span className="highlight-span">delete</span> attachments, hover over the attachment row and click the appearing delete button, or select the checkboxes of the attachments to delete and press the Delete key or button. To remove all definitions including text and attachments, select designated rows and click <span className="highlight-span">undefine</span>.</p>
                    <p>Toggle <span className="highlight-span">Show undefined</span> to highlight image parts without definitions. The table will filter accordingly. To refresh the filter, toggle it off and on again.</p>
                </>

            )
        case 'Group Similar Objects':
            return <p>To define similar objects together, group them. The group's definition will apply to every object in the group during rendering.</p>
        case 'Undo/Redo Action Limit':
            return (
                <>
                    <p>Use the <span className="highlight-span">Undo</span> and <span className="highlight-span">Redo</span> buttons to fix mistakes or revert changes.</p>
                    <p>There is a limit of X undo actions you can perform.</p>
                </>
            )
        case 'Frame to Add': return <p>Refine the object mask indicating image elements to add drawing a frame over them. Preview the mask while dragging and release the mouse when satisfied. Only one frame per mask is possible. Redo to override.</p>
        case 'Point to Add': return <p>Refine the object mask by clicking on the image elements you want to add. Think of it as pointing with your finger and saying, "this is it." Preview the mask while hovering and click when you're satisfied. You can create multiple points within a single mask.</p>
        case 'Point to Exclude': return <p>Refine the object mask by clicking on the image elements you want to exclude. Think of it as pointing with your finger and saying, "this is not it." Preview the mask while hovering and click when you're satisfied. You can create multiple points within a single mask.</p>
        case 'Brush': return <p>Draw the mask with the brush. To adjust the brush size, use the right bracket <span className="beta">&#93;</span> to increase and the left bracket <span className="beta">&#91;</span> to decrease the size. </p>
        case 'Eraser': return <p>Refine the mask with eraser. To adjust the eraser size, use the right bracket <span className="beta">&#93;</span> to increase and the left bracket <span className="beta">&#91;</span> to decrease the size. </p>
        case 'Upload': return <p>Naturally add any file, as if you were sending it to explain the object to someone. This can be any file that aids the explanation process from PDFs to Revit families.</p>
        case 'URLs': return <p>Naturally add any link, as if you were sending it to explain the object to someone. This can be any link that aids the explanation process from product’s web page to an accessible link to a file stored online.</p>
        case 'Products': return (
            <>
                <p>Try applying products that the artificial intelligence of io-buro already knows.</p>
                <p>The io-buro beta will include a product catalog curated by manufacturers. The AI will use this catalog to accurately render products and provide detailed information about them in the chat.</p>
            </>
        )
        case 'Entourage': return (
            <>
                <p>Try applying entourage that the artificial intelligence of io-buro already knows.</p>
                <p>The io-buro beta will feature a palette of entourage elements, such as cutouts, that you can apply even when the loaded image lacks these elements.</p>
            </>
        )
        case 'Create, edit or delete account': return (
            <>
                <p>An io-buro account allows you to use all features of the io-buro demo and save and resume your work in the demo.</p>
                <div className="extended_help_home_quick-start">
                    <p className="section_title">Create your account</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Click <span className="highlight-span">Sign Up</span></p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p>Answer a short 2-question survey (takes less than 1 minute)</p>
                    </div>
                    <div className="quick-start_item">
                        <p>3.</p>
                        <div className="extended_help_home_quick-start">
                            <p>Choose to sign up with Google (a) or email (b):</p>
                            <div className="quick-start_item">
                                <p>a.</p>
                                <p>Sign up immediately using your Google account</p>
                            </div>
                            <div className="quick-start_item">
                                <p>b.</p>
                                <p>Fill in your name and email. We will send you a link with instructions to verify your email</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="extended_help_home_quick-start">
                    <p className="section_title">Edit your email</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Click your account icon to launch the account settings menu</p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p>Select the <span className="highlight-span">Personal Settings</span> tab</p>
                    </div>
                    <div className="quick-start_item">
                        <p>3.</p>
                        <p>Click <span className="highlight-span">Change Email</span></p>
                    </div>
                    <div className="quick-start_item">
                        <p>4.</p>
                        <p>Step 4</p>
                    </div>
                </div>

                <div className="extended_help_home_quick-start">
                    <p className="section_title">Delete your account</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Click your account icon to launch the account settings menu</p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p>Select the <span className="highlight-span">Personal Settings</span> tab</p>
                    </div>
                    <div className="quick-start_item">
                        <p>3.</p>
                        <p>Click <span className="highlight-span"> Delete Account and confirm the action</span></p>
                    </div>
                </div>

            </>
        )
        case 'Personal settings': return (
            <>
                <p>To access your personal settings, go to your account settings clicking your account icon and choose the <span className="highlight-span">Personal Settings</span> section.</p>
                <p>To change your name, retype your first name and surname in the appropriate text fields.</p>
                <p>To change your email, click <span className="highlight-span">Change Email</span> and follow the procedures you receive in your current email.</p>
                <p>To set up and change business details, click <span className="highlight-span">Edit</span> and modify the necessary information. Click  <span className="highlight-span">Save</span> when ready.</p>
            </>
        )
        case 'Billing settings': return (
            <>
                <p>To access your billing settings, go to your account settings clicking your account icon and choose the <span className="highlight-span">Billing</span> section.</p>
                <p>The billing section opens with an overview of your account balance.</p>
                <div className="extended_help_home_quick-start">
                    <p className="section_title">To top up your balance:</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Click <span className="highlight-span">Buy Tokens</span>.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p>Choose a payment method and amount, then click <span className="highlight-span">Pay</span>.</p>
                    </div>
                </div>

                <div className="extended_help_home_quick-start">
                    <p className="section_title">To set up payment methods::</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Go to the <span className="highlight-span">Payment Methods</span> section.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p>Click <span className="highlight-span">Add Payment Method</span>.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>3.</p>
                        <p>Fill in payment information, then approve or cancel.</p>
                    </div>
                </div>
                <p>To view billing history, click the <span className="highlight-span">Billing History</span> section. Click any invoice to download it.</p>
                <p>To adjust billing preferences, go to the <span className="highlight-span">Preferences</span> section.</p>
                <p>Update your details. These changes will affect future invoices, including company name, business address, and tax ID.</p>
            </>
        )
        case 'Mailing preferences': return (
            <>
                <p>To access your mailing preferences, go to your account settings clicking your account icon and choose the <span className="highlight-span">Mailing preferences</span> section.</p>
                <p>Opt in or out of our email newsletter by toggling the switch on or off.</p>
                <p>Turn the switch on if you want to be notified when your rendering is ready.</p>
            </>
        )

        default: return <></>

    }

}

export default getText