import EventEmitter from 'eventemitter3';
import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $intersection, $manager } from '.';
import * as utils from './utils';
import * as constants from './constants';
import { FileData } from 'services/library';
import { createSketch, saveSketch, renderSketch, saveMask } from 'services/sketch';
import { $userId } from 'entities/user';
import config from 'config';
import { errorEvent } from 'entities/error';
import Engine from './Engine';
import { SpriteState as SegmentState } from './Engine/Engine';


export enum AttachmentType {
    CUSTOM = 'CUSTOM',
    MODEL = 'MODEL',
}

/**
** [r, g, b, a];
** 0 - 255
*/
export type Color = [number, number, number, number]; // [r, g, b, a];
export type SegmentData = Omit<typeof Segment extends new (arg: infer T) => any ? T : never, 'engine' | 'width' | 'height' | 'manager' | 'maskSize'>;
export type SegmentDataFromSam = Omit<SegmentData, 'size'>;
export type GroupData = ReturnType<Group['toJSON']>;
export type ListData = { id: string, constructor: 'Group' | 'Segment' };
export type AttachmentProps = FileData & {
    type?: AttachmentType;
};

export class Attachment extends EventEmitter {
    readonly originalname: string;
    readonly filename: string;
    readonly type: AttachmentType;

    #note: string;

    get note() {
        return this.#note;
    }
    set note(value: string) {
        this.#note = value;
        this.emit('note', this.note);
    }

    constructor({ originalname, filename, note, type = AttachmentType.CUSTOM }: AttachmentProps) {
        super();
        this.originalname = originalname;
        this.filename = filename;
        this.#note = note;
        this.type = type;
    }

    public getImageSrc(userId: string): string {
        switch (this.type) {
            case AttachmentType.CUSTOM:
                return `${config.minioUrl}/${config.backet}/${userId}/${this.filename}`;
            case AttachmentType.MODEL:
                return `${config.trainerUrl}/preview-images/${encodeURIComponent(this.originalname)}`;
            default:
                return '';
        }
    }

    public toJSON() {
        return {
            originalname: this.originalname,
            filename: this.filename,
            note: this.#note,
            type: this.type,
        };
    }
}

export enum ActionType {
    POSITIVE_BOX = 'POSITIVE_BOX',
    NEGATIVE_BOX = 'NEGATIVE_BOX',
    POSITIVE_POINT = 'POSITIVE_POINT',
    NEGATIVE_POINT = 'NEGATIVE_POINT',
    BRUSH = 'BRUSH',
}

interface Action {
    value: any;
    type: ActionType;
}

export interface Box extends Action {
    value: Array<number>;
    type: ActionType.POSITIVE_BOX | ActionType.NEGATIVE_BOX;
}

export interface Point extends Action {
    value: {
        x: number;
        y: number;
        clickType: 1;
    };
    type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT;
}

export class Segment extends EventEmitter {
    private readonly engine: Engine;
    private readonly manager: Manager;
    readonly id: string;
    readonly mask: Uint8Array;

    #description: string;
    #attachments: Array<Attachment>;
    #color: Color;
    #blackAndWhiteColor: Color;
    #name: number;
    #state: SegmentState = SegmentState.REGULAR;

    texture: WebGLTexture | null = null;
    blackAndWhite = false;
    hoverOutlineWidth = 0;
    selectOutlineWidth = 0;
    maskBox = [0, 0, 1, 1];
    maskSize = 0;
    width = 0;
    height = 0;
    groupId: string | null;

    constructor({
        id,
        description = '',
        attachments,
        color,
        name,
        width,
        height,
        groupId,
        engine,
        manager,
    }: {
        id: Segment['id'];
        description: Segment['description'];
        attachments: Segment['attachments'];
        color: Segment['color'];
        name: Segment['name'];
        width: Segment['width'];
        height: Segment['height'];
        groupId: Segment['groupId'],
        engine: Segment['engine'],
        manager: Segment['manager'];
    }) {
        super();
        this.id = id;
        this.#description = description;
        this.#attachments = attachments.map(data => new Attachment(data));
        this.#color = color;
        this.#blackAndWhiteColor = [color[0] * 0.299, color[1] * 0.587, color[2] * 0.114, color[3]];
        this.#name = name;
        this.width = width;
        this.height = height;
        this.maskBox = [0, 0, width, height];
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.mask = new Uint8Array(side * side * 4);
        this.groupId = groupId;
        this.engine = engine;
        this.manager = manager;
    }

    get description() {
        return this.#description;
    }
    set description(value: string) {
        this.#description = value;
        this.emit('description', this.description);
    }

    get attachments(): Array<Attachment> {
        return this.#attachments;
    }
    set attachments(value: Array<FileData>) {
        this.#attachments = value.map(data => new Attachment(data));
        this.emit('attachments', this.attachments);
    }

    get color() {
        return this.#color;
    }
    set color(value: Color) {
        this.#color = value;
        const brightness = Math.floor(value[0] * 0.299 + value[1] * 0.587 + value[2] * 0.114);
        this.#blackAndWhiteColor = [brightness, brightness, brightness, value[3]];
        this.emit('color', this.color);
    }

    get renderColor() {
        if (this.blackAndWhite) return this.#blackAndWhiteColor;
        else return this.#color;
    }

    get name() {
        return this.#name;
    }
    set name(value: number) {
        this.#name = value;
        this.emit('name', this.name);
    }

    get state() {
        return this.#state;
    }
    set state(value: SegmentState) {
        this.#state = value;
        this.emit('state', this.state);
    }

    public attach(data: AttachmentProps) {
        const attachment = new Attachment(data)
        this.attachments = [...this.#attachments, attachment];
        this.manager.saveSketch();
    }

    public unattach(attachment: Attachment) {
        this.attachments = this.#attachments.filter(a => a !== attachment);
        this.manager.saveSketch();
    }

    public async setSrc(src: string) {
        const image = new Image();
        await new Promise((res, rej) => {
            image.src = src;
            image.crossOrigin = '*';
            image.onload = res;
            image.onerror = rej;
        });

        this.width = image.naturalWidth;
        this.height = image.naturalHeight;
        const { context } = utils.createCanvasAndContext(this.width, this.height);
        context.drawImage(image, 0, 0);
        const buffer = context.getImageData(0, 0, this.width, this.height).data;
        for (let h = 0; h < this.height; h++) {
            for (let w = 0; w < this.width; w++) {
                const index = h * this.width + w;
                const value = Boolean(buffer[(index) * 4 + 3]);

                if (value) {
                    const byteIndex = Math.floor(index / 8);
                    const bitIndex = index % 8;
                    this.maskSize++;
                    this.mask[byteIndex] |= (1 << bitIndex);
                }
            }
        }

        this.updateBoundingRectangle();
    }

    public overMask(x: number, y: number): boolean {
        const index = y * this.width + x;
        const byteIndex = Math.floor(index / 8);
        const bitIndex = index % 8;

        return (this.mask[byteIndex] & (1 << bitIndex)) !== 0;
    }

    public updateMask(mask: Uint8Array) {
        this.mask.set(mask);
        this.engine.updateSprite(this);
        this.updateBoundingRectangle();
    }

    public async getPng(): Promise<Blob> {
        const { canvas, context } = utils.createCanvasAndContext(this.width, this.height);
        const imageData = context.createImageData(this.width, this.height);
        const data = imageData.data;

        const length = this.width * this.height;

        for (let i = 0; i < length; i++) {
            const byteIndex = Math.floor(i / 8);
            const bitIndex = i % 8;
            const isPixelSet = (this.mask[byteIndex] >> bitIndex) & 1;

            const pixelIndex = i * 4;
            if (isPixelSet) {
                data[pixelIndex] = this.color[0];
                data[pixelIndex + 1] = this.color[1];
                data[pixelIndex + 2] = this.color[2];
                data[pixelIndex + 3] = this.color[3];
            }
        }

        context.putImageData(imageData, 0, 0);
        const blob = await new Promise<Blob | null>(res => canvas.toBlob(res, 'image/png'));
        if (!blob) throw new Error('Blob is null.');

        return blob;
    }

    public async saveMask() {
        const blob = await this.getPng();

        saveMask(blob, this.name, this.manager.id);
    }

    public updateBoundingRectangle() {
        let y1 = null,
            y2 = null,
            x1 = null,
            x2 = null;

        // Находим верхнюю границу (y1)
        for (let i = 0; i < this.height; i++) {
            for (let j = 0; j < this.width; j++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    y1 = i;
                    break;
                }
            }
            if (y1 !== null) break;
        }
        if (y1 === null) return [0, 0, 0, 0];

        // Находим нижнюю границу (y2)
        for (let i = this.height - 1; i >= 0; i--) {
            for (let j = 0; j <= this.width; j++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    y2 = i + 1;
                    break;
                }
            }
            if (y2 !== null) break;
        }
        if (y2 === null) return [0, 0, 0, 0];

        // Находим левую границу (x1)
        for (let j = 0; j < this.width; j++) {
            for (let i = y1; i <= y2; i++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    x1 = Math.max(j - (j % 8 || 8), 0);
                    break;
                }
            }
            if (x1 !== null) break;
        }
        if (x1 === null) return [0, 0, 0, 0];

        // Находим правую границу (x2)
        for (let j = this.width; j >= 0; j--) {
            for (let i = y1; i <= y2; i++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    x2 = Math.min(j + (8 - j % 8), this.width);
                    break;
                }
            }
            if (x2 !== null) break;
        }
        if (x2 === null) return [0, 0, 0, 0];

        this.maskBox = [x1, y1, x2, y2];
    }

    public toJSON(): SegmentData {
        return {
            description: this.#description,
            attachments: this.#attachments,
            color: this.color,
            name: this.name,
            id: this.id,
            groupId: this.groupId,
        };
    }
}

type OnnxState = {
    busy: boolean;
    prevTime: number;
};

class SegmentEditor extends EventEmitter {
    private readonly engine: Engine;
    private readonly recognizingQueue: Array<Parameters<SegmentEditor['onnxUpdate']>> = [];
    private readonly worker: Worker;
    private readonly manager: Manager;
    private readonly sourceMask: Uint8Array;
    private readonly tempMask: Uint8Array;
    private recognizing = false;
    private prevBrushPoint: { x: number; y: number } | null = null;
    private originalColor: Color = [0, 0, 0, 0];

    public brushAlfa: 1 | 0 = 1;

    #segment: Segment | null = null;
    #undo: Array<Uint8Array> = [];
    #redo: Array<Uint8Array> = [];
    #editing = false;
    #onnxState: OnnxState = { busy: false, prevTime: 0 };

    get segment() {
        return this.#segment;
    }
    private set segment(value: Segment | null) {
        this.#segment = value;
        this.emit('segment', this.segment);
    }

    get undo() {
        return this.#undo;
    }
    private set undo(value: Array<Uint8Array>) {
        this.#undo = value;
        this.emit('undo', this.undo);
    }

    get redo() {
        return this.#redo;
    }
    private set redo(value: Array<Uint8Array>) {
        this.#redo = value;
        this.emit('redo', this.redo);
    }

    get editing() {
        return this.#editing;
    }
    set editing(value: boolean) {
        this.#editing = value;
        this.emit('editing', this.editing);
    }

    get onnxState() {
        return this.#onnxState;
    }
    set onnxState(value: OnnxState) {
        this.#onnxState = value;
        this.emit('onnxState', this.onnxState);
    }

    constructor(private readonly width: number, private readonly height: number, manager: Manager, engine: Engine) {
        super();

        const worker = new Worker(new URL('./AIWorker.ts', import.meta.url));
        if (!worker) throw new Error('Worker is null.');
        this.worker = worker;

        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.tempMask = new Uint8Array(side * side * 4);
        this.sourceMask = new Uint8Array(side * side * 4);

        this.manager = manager;
        this.engine = engine;
    }

    public async init(id: string) {
        await Promise.all([
            this.initWorker(),
            this.loadEmbedding(id),
        ]);
    }

    private async initWorker() {
        await new Promise((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'model') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(null) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
    }

    private async loadEmbedding(id: string) {
        this.worker.postMessage({ type: 'tensor', props: id });
        await new Promise((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'tensor') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(null) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
    }

    public editSegment(segment: Segment) {
        this.sourceMask.set(segment.mask);
        this.tempMask.set(segment.mask);
        this.segment = segment;
        this.originalColor = [...this.segment.color];
        this.segment.color = constants.defaultMaskColor;
        this.editing = true;
        this.manager.segments.forEach(segment => segment.blackAndWhite = true);
        segment.blackAndWhite = false;
    }

    public cancelCreateSegment() {
        if (!this.segment) throw new Error('Segment is null.');

        this.engine.deleteSprite(this.segment);
        const index = this.manager.segments.findIndex(segment => segment === this.segment);
        if (!~index) throw new Error('Segment not found.');
        this.manager.segments.splice(index, 1);

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);
        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
    }

    public cancelEditSegment() {
        if (!this.segment) throw new Error('Segment is null.');

        this.segment.color = [...this.originalColor];
        this.segment.updateMask(this.sourceMask);

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);
        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
    }

    public endEditing() {
        if (!this.segment) throw new Error('Segment is null.');

        this.segment.color = [...this.originalColor];
        this.segment.updateMask(this.tempMask);
        this.segment.saveMask();
        this.manager.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
        this.manager.saveSketch();

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);
        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
    }

    public endCreating() {
        if (!this.segment) throw new Error('Segment is null.');

        this.segment.color = [...this.originalColor];
        this.segment.updateMask(this.tempMask);
        this.segment.saveMask();
        this.segment.color = utils.generateRandomColor();
        this.manager.syncListWithSegments();
        this.manager.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
        this.manager.saveSketch();

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);
        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
    }

    public async undoAction() {
        if (!this.segment) throw new Error('Segment is null.');
        if (this.#undo.length < 1) return;
        const mask = this.#undo.pop();
        if (!mask) return;
        this.undo = [...this.#undo];
        this.redo = [...this.#redo, this.segment.mask.slice()];
        this.segment.updateMask(mask);
        this.tempMask.set(this.segment.mask);
    }

    public async rendoAction() {
        if (!this.segment) throw new Error('Segment is null.');
        const mask = this.#redo.pop();
        if (!mask) return;
        this.undo = [...this.#undo, this.segment.mask.slice()];
        this.redo = [...this.#redo];
        this.segment.updateMask(mask);
        this.tempMask.set(this.segment.mask);
    }

    public async showPoint(x: number, y: number, type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT) {
        const point: Point = {
            value: {
                ...this.manager.transformCoordinates(x, y),
                clickType: 1,
            },
            type,
        };

        if (point.value.x < 0 || point.value.x > this.width) return;
        if (point.value.y < 0 || point.value.y > this.height) return;

        this.onnx(point, true, false);
    }

    public noPreview() {
        this.onnx('CLEAR', false, false);
    }

    public async addPoint(x: number, y: number, type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT) {
        const point: Point = {
            value: {
                ...this.manager.transformCoordinates(x, y),
                clickType: 1,
            },
            type,
        };

        if (point.value.x < 0 || point.value.x > this.width) return;
        if (point.value.y < 0 || point.value.y > this.height) return;

        this.onnx(point, true, true);
    }

    public async showBox(box: Array<number>) {
        const x0x1 = [box[0], box[2]].sort((a, b) => a - b);
        const y0y1 = [box[1], box[3]].sort((a, b) => a - b);
        const сoordinates = [x0x1[0], y0y1[0], x0x1[1], y0y1[1]];
        const sortedBox: Box = { value: сoordinates, type: ActionType.POSITIVE_BOX };
        this.engine.frame = sortedBox;

        const { x: x0, y: y0 } = this.manager.transformCoordinates(сoordinates[0], сoordinates[1]);
        const { x: x1, y: y1 } = this.manager.transformCoordinates(сoordinates[2], сoordinates[3]);

        const realBox: Box = { value: [Math.max(x0, 0), Math.max(y0, 0), Math.min(x1, this.width), Math.min(y1, this.height)], type: ActionType.POSITIVE_BOX };

        this.onnx(realBox, true, false);
    }

    public async addBox(box: Array<number>) {
        const x0x1 = [box[0], box[2]].sort((a, b) => a - b);
        const y0y1 = [box[1], box[3]].sort((a, b) => a - b);
        const сoordinates = [x0x1[0], y0y1[0], x0x1[1], y0y1[1]];
        this.engine.frame = null;

        const { x: x0, y: y0 } = this.manager.transformCoordinates(сoordinates[0], сoordinates[1]);
        const { x: x1, y: y1 } = this.manager.transformCoordinates(сoordinates[2], сoordinates[3]);

        const realBox: Box = { value: [Math.max(x0, 0), Math.max(y0, 0), Math.min(x1, this.width), Math.min(y1, this.height)], type: ActionType.POSITIVE_BOX };

        this.onnx(realBox, true, true);
    }

    public hideBox() {
        this.engine.frame = null;
    }

    public draw(x: number, y: number) {
        if (!this.segment) throw new Error('Segment is null.');

        const { x: x2, y: y2 } = this.manager.transformCoordinates(x, y);
        const x1 = Math.round(x2);
        const y1 = Math.round(y2);

        const radius = Math.round(this.engine.cursorRadius / this.engine.scale / 2);

        const setPixel = (bitmap: Uint8Array, width: number, height: number, x: number, y: number) => {
            if (x >= 0 && x < width && y >= 0 && y < height) {
                const bitIndex = y * width + x;
                const byteIndex = Math.floor(bitIndex / 8);
                const bitPosition = bitIndex % 8;

                if (this.brushAlfa) bitmap[byteIndex] |= (1 << bitPosition);
                else bitmap[byteIndex] &= ~(1 << bitPosition);
            }
        }

        function drawFilledCircle(bitmap: Uint8Array, width: number, height: number, centerX: number, centerY: number, radius: number) {
            for (let y = -radius; y <= radius; y++) {
                for (let x = -radius; x <= radius; x++) {
                    if (x * x + y * y <= radius * radius) {
                        setPixel(bitmap, width, height, centerX + x, centerY + y);
                    }
                }
            }
        }

        let x0 = this.prevBrushPoint ? this.prevBrushPoint.x : x1;
        let y0 = this.prevBrushPoint ? this.prevBrushPoint.y : y1;
        let dx = Math.abs(x1 - x0);
        let dy = Math.abs(y1 - y0);
        let sx = (x0 < x1) ? 1 : -1;
        let sy = (y0 < y1) ? 1 : -1;
        let err = dx - dy;

        while (true) {
            drawFilledCircle(this.segment.mask, this.segment.width, this.segment.height, x0, y0, radius);

            if (x0 === x1 && y0 === y1) break;
            let e2 = 2 * err;
            if (e2 > -dy) {
                err -= dy;
                x0 += sx;
            }
            if (e2 < dx) {
                err += dx;
                y0 += sy;
            }
        }

        this.engine.updateSprite(this.segment);

        this.prevBrushPoint = { x: x1, y: y1 };
    }

    public endDraw() {
        if (!this.segment) throw new Error('Segment is null.');

        this.prevBrushPoint = null;
        this.undo = [...this.#undo, this.tempMask.slice()];
        this.redo = [];
        this.tempMask.set(this.segment.mask);
    }

    private async onnxUpdate(payload: Point | Box | 'CLEAR', join: boolean, permanent: boolean): Promise<void> {
        if (!this.segment) throw new Error('Segment is null.');

        if (payload === 'CLEAR') {
            this.segment.updateMask(this.tempMask);
            this.engine.updateSprite(this.segment);
            return;
        }

        const LONG_SIDE_LENGTH = 1024;
        const samScale = LONG_SIDE_LENGTH / Math.max(this.height, this.width);

        switch (payload.type) {
            case ActionType.POSITIVE_BOX:
                this.worker.postMessage({ type: 'mask', props: { box: payload.value, points: [], height: this.height, width: this.width, samScale } });
                break;
            case ActionType.NEGATIVE_BOX:
                this.worker.postMessage({ type: 'mask', props: { box: payload.value, points: [], height: this.height, width: this.width, samScale } });
                break;
            case ActionType.NEGATIVE_POINT:
                this.worker.postMessage({ type: 'mask', props: { box: [], points: [payload.value], height: this.height, width: this.width, samScale } });
                break;
            case ActionType.POSITIVE_POINT:
                this.worker.postMessage({ type: 'mask', props: { box: [], points: [payload.value], height: this.height, width: this.width, samScale } });
                break;
        }
        this.onnxState = { busy: true, prevTime: this.onnxState.prevTime };
        const start = performance.now();
        const { buffer } = await new Promise<{ buffer: ArrayBufferLike }>((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'mask') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(data.result) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
        const end = performance.now();
        const prevTime = end - start;
        this.onnxState = { busy: false, prevTime };

        const array = new Uint8Array(buffer);

        const arrayIsPositive = payload.type.includes('POSITIVE');

        if (join === arrayIsPositive) {
            for (let i = 0; i < array.length; i++) {
                this.segment.mask[i] = this.tempMask[i] | array[i];
            }
        } else {
            for (let i = 0; i < array.length; i++) {
                this.segment.mask[i] = this.tempMask[i] & ~array[i];
            }
        }

        if (permanent) {
            this.undo = [...this.#undo, this.tempMask.slice()];
            this.redo = [];
            this.tempMask.set(this.segment.mask);
        }

        this.engine.updateSprite(this.segment);
    }

    private async onnx(...payload: Parameters<SegmentEditor['onnxUpdate']>): Promise<void> {
        if (this.recognizing) {
            let i = 0;
            while (i < this.recognizingQueue.length) {
                if (this.recognizingQueue[i][2]) i++;
                else this.recognizingQueue.splice(i, 1);
            }

            this.recognizingQueue.push(payload);
        } else {
            this.recognizing = true;
            return new Promise<void>(async res => {
                try {
                    await this.onnxUpdate(...payload);
                } catch (e) {
                    console.error(e);
                }
                res();
                this.recognizing = false;
                const next = this.recognizingQueue.shift();
                if (next) this.onnx(...next);
            });
        }
    }
}

type GroupProps = {
    segments: Array<Segment>;
    description: string;
    savingDescriptionAndAttachments: boolean;
    attachments: Array<FileData>;
    id?: string;
    name: number;
    included: Array<Segment>;
    state: 'editing' | 'creating' | 'default';
    manager: Manager;
}

export class Group extends EventEmitter {
    readonly id: string;
    readonly manager: Manager;

    #segments: Array<Segment> = [];
    #description: string;
    #attachments: Array<Attachment>;
    #name: number;
    #isOpenForEditing = true;
    #included: Array<Segment>;
    #state: 'editing' | 'creating' | 'default';

    savingDescriptionAndAttachments = false;

    constructor({ segments, description, savingDescriptionAndAttachments, attachments, id = crypto.randomUUID(), name, included, state, manager }: GroupProps) {
        super();
        this.id = id;
        this.manager = manager;
        this.#segments = segments;
        this.#segments.forEach(segment => segment.groupId = this.id);
        this.#description = description;
        this.savingDescriptionAndAttachments = savingDescriptionAndAttachments;
        this.#attachments = attachments.map(data => new Attachment(data));
        this.#name = name;
        this.#included = included;
        this.#state = state;
    }

    get segments() {
        return this.#segments;
    }
    private set segments(value: Array<Segment>) {
        this.#segments = value;
        this.emit('segments', this.segments);
    }

    get description() {
        return this.#description;
    }
    set description(value: string) {
        this.#description = value;
        this.emit('description', this.description);
    }


    get attachments(): Array<Attachment> {
        return this.#attachments;
    }
    set attachments(value: Array<FileData>) {
        this.#attachments = value.map(data => new Attachment(data));
        this.emit('attachments', this.attachments);
    }

    get name() {
        return this.#name;
    }
    set name(value: number) {
        this.#name = value;
        this.emit('name', this.name);
    }

    get isOpenForEditing() {
        return this.#isOpenForEditing;
    }
    set isOpenForEditing(value: boolean) {
        this.#isOpenForEditing = value;
        this.emit('isOpenForEditing', this.isOpenForEditing);
    }

    get included() {
        return this.#included;
    }
    set included(value: Array<Segment>) {
        this.#included = value;
        this.emit('included', this.included);
    }

    get state() {
        return this.#state;
    }
    set state(value: 'editing' | 'creating' | 'default') {
        this.#state = value;
        this.emit('state', this.state);
    }

    public addSegment(segment: Segment, destination: number) {
        /* @ts-ignore */
        this.segments = this.#segments.toSpliced(destination, 0, segment);
        segment.groupId = this.id;
    }

    public changeSegmentPosition(source: number, destination: number) {
        const segments = [...this.#segments];
        const segment = segments.splice(source, 1)[0];
        segments.splice(destination, 0, segment);
        this.segments = segments;
    }

    public removeSegment(segment: Segment) {
        this.segments = this.#segments.filter(s => s !== segment);
        segment.groupId = null;
    }

    public unattach(attachment: FileData) {
        this.attachments = this.#attachments.filter(a => a !== attachment);
        this.manager.saveSketch();
    }

    public attach(attachment: FileData) {
        this.attachments = [...this.#attachments, attachment];
        this.manager.saveSketch();
    }

    public toJSON() {
        return {
            id: this.id,
            savingDescriptionAndAttachments: this.savingDescriptionAndAttachments,
            segments: this.#segments.map(({ id }) => id),
            description: this.#description,
            attachments: this.#attachments,
            name: this.#name,
            included: this.#included.map(segment => segment.id),
            state: this.#state,
        };
    }
}

type ManagerProps = {
    id: string;
    segments: Array<SegmentDataFromSam>;
    file: File;
    list?: Array<ListData>;
    groups: Array<GroupData>;
    firstLoad: boolean;
    name: string;
};

export class Manager extends EventEmitter {
    private readonly hoverOutlineWidth = 5;
    private readonly selectOutlineWidth = 3;
    private readonly engine: Engine;
    public readonly segments: Array<Segment> = [];  // READONLY!!!
    private firstLoad;

    segmentEditor!: SegmentEditor;
    canvas: HTMLCanvasElement | null = null;
    context: WebGL2RenderingContext | null = null;
    size = { width: 0, height: 0 };

    #groups: Array<Group> = [];
    #list: Array<Segment | Group> = [];
    #selectedElements: Array<Segment | Group> = [];
    #id: string;
    #loaded = false;
    #editingGroup: Group | undefined;
    #hoveredElement: Segment | Group | null = null;
    #name: string;
    #deltaFrame: number | null = null;

    constructor({ id, segments, file, list, groups, firstLoad, name }: ManagerProps) {
        super();
        this.#id = id;
        this.#name = name;
        this.engine = new Engine(this.segments);
        this.firstLoad = firstLoad;

        this.init(segments, file, groups, list);
    }

    get loaded() {
        return this.#loaded;
    }
    set loaded(value: boolean) {
        this.#loaded = value;
        this.emit('loaded', this.loaded);
    }

    get id() {
        return this.#id;
    }

    get editingGroup() {
        return this.#editingGroup;
    }
    set editingGroup(value: Group | undefined) {
        if (value) this.segmentEditor.editing = false;
        this.#editingGroup = value;
        this.emit('editingGroup', this.editingGroup);
    }

    get hoveredElement() {
        return this.#hoveredElement;
    }
    set hoveredElement(value: Segment | Group | null) {
        this.#hoveredElement = value;
        this.segments.forEach(segment => segment.hoverOutlineWidth = 0);
        if (value instanceof Segment) value.hoverOutlineWidth = this.hoverOutlineWidth;
        if (value instanceof Group) value.segments.forEach(segment => segment.hoverOutlineWidth = this.hoverOutlineWidth);
        this.emit('hoveredElement', this.hoveredElement);
    }

    get groups() {
        return this.#groups
    }
    private set groups(value: Array<Group>) {
        this.#groups = value;
        this.emit('groups', this.groups);
    }

    get list() {
        return this.#list;
    }
    private set list(value: Array<Segment | Group>) {
        this.#list = value;
        this.emit('list', this.list);
    }

    get selectedElements() {
        return this.#selectedElements;
    }
    private set selectedElements(value: Array<Segment | Group>) {
        this.#selectedElements = value;
        this.segments.forEach(segment => segment.selectOutlineWidth = 0);
        value.forEach(element => {
            if (element instanceof Segment) element.selectOutlineWidth = this.selectOutlineWidth;
            if (element instanceof Group) element.segments.forEach(segment => segment.selectOutlineWidth = this.selectOutlineWidth);
        })
        this.emit('selectedElements', this.selectedElements);
    }

    get name() {
        return this.#name;
    }
    set name(value: string) {
        this.#name = value;
        this.emit('name', this.name);
    }

    get deltaFrame() {
        return this.#deltaFrame;
    }
    set deltaFrame(value: number | null) {
        this.#deltaFrame = value;
        if (value !== null) {
            if (value === 0) this.engine.frameColor = [0.5, 0.5, 0.5, 0.8];
            if (value > 0) this.engine.frameColor = [0, 0.64, 0.91, 0.8];
            if (value < 0) this.engine.frameColor = [0.93, 0.11, 0.14, 0.8];
        }
        this.emit('deltaFrame', this.deltaFrame);
    }

    get self() {
        return this;
    }

    public createNewSegment(): Segment {
        const segment = new Segment({
            id: crypto.randomUUID(),
            description: '',
            attachments: [],
            color: utils.generateRandomColor(),
            name: -1,
            width: this.size.width,
            height: this.size.height,
            groupId: null,
            engine: this.engine,
            manager: this,
        });

        segment.name = this.list.sort(({ name: a }, { name: b }) => a - b).slice(-1)[0].name + 1;

        this.segments.push(segment);
        this.engine.addSprite(segment);

        return segment;
    }

    public selectElement(value: Segment | Group) {
        this.selectedElements = [...this.#selectedElements, value];
    }

    public selectOneElement(value: Segment | Group) {
        if (this.#editingGroup) return;
        this.selectedElements = [value];
    }

    public selectElements(value: Array<Segment | Group>) {
        this.selectedElements = value;
    }

    public unselectElement(value: Segment | Group) {
        this.selectedElements = this.#selectedElements.filter(segment => segment !== value);
    }

    public unselectAllElements() {
        this.selectedElements = [];
    }

    private async init(segments: Array<SegmentDataFromSam>, file: File, groups: Array<GroupData>, list?: Array<ListData>,) {
        try {
            this.size = await this.loadImageSize(file);
            this.segmentEditor = new SegmentEditor(this.size.width, this.size.height, this, this.engine);
            await this.segmentEditor.init(this.#id);
            await this.initSegments(segments);
            if (this.firstLoad) this.removeIdenticalMasks();
            this.initGroups(groups);
            this.initList(list);
            if (this.firstLoad) await this.createSketch();
            this.firstLoad = false;
            this.loaded = true;
        } catch (e) {
            console.error('Segments not init.');
            console.error(e);
            errorEvent();
        }
    }

    private initGroups(groups: Array<GroupData>) {
        this.groups = groups.map(group => {
            const segments = group.segments.map(id => {
                const segment = this.segments.find(segment => segment.id === id);
                if (!segment) throw new Error('Error init groups.');
                return segment;
            });
            return new Group({ manager: this, ...group, segments, included: [], state: 'default' });
        });
    }

    private initList(list?: Array<ListData>) {
        if (!list) this.list = [...this.segments];
        else this.list = list.map(element => {
            if (element.constructor === 'Segment') {
                const segment = this.segments.find(segment => segment.id === element.id);
                if (!segment) throw new Error('Error init list.');
                return segment;
            }
            if (element.constructor === 'Group') {
                const group = this.#groups.find(group => group.id === element.id);
                if (!group) throw new Error('Error init list.');
                return group;
            }
            throw new Error('Error init list.');
        });
    }

    public changeOrder(source: number, destination: number) {
        const list = [...this.#list];
        const element = list.splice(source, 1)[0];
        list.splice(destination, 0, element);
        this.list = list;
    }

    public addSegmentToGroup(segment: Segment, group: Group, destination: number) {
        const alreadyInGroup = this.groups.flatMap(({ segments }) => segments).includes(segment);
        if (alreadyInGroup) return;
        group.addSegment(segment, destination);
        this.list = this.#list.filter(s => s !== segment);
    }

    public removeSegmentFromGroup(segment: Segment, group: Group, destination: number) {
        group.removeSegment(segment);
        /* @ts-ignore */
        this.list = this.#list.toSpliced(destination, 0, segment);
    }

    private initSegments = async (masks: Array<SegmentDataFromSam>) => {
        const hostname = this.firstLoad ? config.serverUrl : config.backendUrl + '/api';
        await Promise.all(masks.map(async mask => {
            const segment = new Segment({ ...mask, engine: this.engine, manager: this, width: this.size.width, height: this.size.height });
            await segment.setSrc(`${hostname}/masks/${this.#id}/${mask.name}.png`);
            segment.color = utils.generateRandomColor();
            this.segments.push(segment);
        }));
    }

    private loadImageSize = async (file: File) => {
        const image = new Image();
        await new Promise((res, rej) => {
            image.src = URL.createObjectURL(file);
            image.onload = res;
            image.onerror = rej;
        });

        return { width: image.naturalWidth, height: image.naturalHeight };
    }

    private removeIdenticalMasks() {
        this.segments.forEach((segment, index) => {
            let removedCount = 0;
            for (let i = index + 1; i < this.segments.length; i++) {
                let intersectionCount = 0;

                for (let j = 0; j < segment.mask.length; j++) {
                    let and = segment.mask[j] & this.segments[i - removedCount].mask[j];

                    while (and) {
                        intersectionCount += and & 1;
                        and >>= 1;
                    }
                }

                const intersectionPercentage = intersectionCount / segment.maskSize * 100;
                if (intersectionPercentage > $intersection.getState()) {
                    this.segments.splice(i - removedCount, 1);
                    removedCount++;
                }
            }
        });
    }

    public connectCanvas(canvas: HTMLCanvasElement, picture: HTMLImageElement) {
        this.canvas = canvas;
        const context = canvas.getContext('webgl2', { preserveDrawingBuffer: true });
        if (!context) throw new Error('Context not found.');
        this.context = context;
        const fn = () => {
            this.engine.draw();
            requestAnimationFrame(fn);
        }
        this.engine.init(context, picture, this.size.width, this.size.height).then(() => {
            this.engine.draw();
            requestAnimationFrame(fn);
            this.saveSketch();
        });
        const resizeObserver = new ResizeObserver(() => context.viewport(0, 0, canvas.width, canvas.height));
        resizeObserver.observe(canvas);
    }

    public syncListWithSegments() {
        this.segments.forEach(segment => {
            if (!this.list.includes(segment)) this.list = [...this.#list, segment];
        });
    }

    private getSketchData() {
        const list = this.#list.map(element => {
            let constructor = '';
            if (element instanceof Segment) constructor = 'Segment';
            if (element instanceof Group) constructor = 'Group';
            return ({ id: element.id, constructor });
        });

        return { id: this.id, list, segments: this.segments, groups: this.#groups, userId: $userId.getState(), name: this.#name };
    }

    public updateTutorialDraw(tutorialStep: number) {
        /*         if (!this.#tutorialContext || !this.#tutorialCanvas) return;
                const { width, height } = this.#tutorialCanvas;
                this.#tutorialContext.clearRect(0, 0, width, height);
        
                const context = this.#tutorialContext;
                this.#selectedElements.forEach(element => {
                    if (element instanceof Segment) {
                        if (tutorialStep === 1) {
                            context.drawImage(element.outline, 0, 0, width, height);
        
                        } else {
                            context.drawImage(element.outline, 0, 0, width, height);
                            context.drawImage(element.image, 0, 0, width, height);
                        }
        
                    }
                    if (element instanceof Group) element.segments.forEach(segment => {
                        context.drawImage(segment.outline, 0, 0, width, height)
                        context.drawImage(segment.image, 0, 0, width, height)
                    })
                }); */
    }

    public createGroup(segments: Array<Segment>, { including = false, saving = false }: { including?: boolean; saving?: boolean }, { state = 'default' }: { state?: 'editing' | 'creating' | 'default' }) {
        const list = [...this.#list];
        const [index, ...indexes] = this.list.reduce<Array<number>>((acc, cur, index) => {
            if (!segments.includes(cur as any)) return acc;
            acc.push(index);
            return acc;
        }, []).sort((a, b) => a - b);
        this.unselectAllElements();
        const name = this.#list.sort(({ name: a }, { name: b }) => a - b).slice(-1)[0].name + 1;
        const group = new Group({ manager: this, segments, description: 'Group description: ', savingDescriptionAndAttachments: saving, attachments: [], name, included: !including ? [...segments] : [], state: state });
        list.splice(index, 1, group);
        indexes.reverse().forEach(index => list.splice(index, 1));
        this.groups = [...this.#groups, group];
        this.list = list;
        this.saveSketch();
        setTimeout(() => {
            const element = document.getElementById('element_' + group.id);
            element?.scrollIntoView({ block: 'center' });
        }, 10);
        return group;
    }

    public changeToRandomColor(segment: Segment) {
        segment.color = utils.generateRandomColor();
        this.saveSketch()
    }

    public ungroup(groups: Array<Group>) {
        groups.forEach(group => {
            const list = [...this.#list];
            const index = list.indexOf(group);
            list.splice(index, 1, ...group.segments);
            this.groups = this.#groups.filter(g => g !== group);
            this.list = list;
            group.segments.forEach(segment => segment.groupId = null);
        });
    }

    public removeSegment(segment: Segment) {
        if (segment === this.#hoveredElement) this.hoveredElement = null;
        this.selectedElements = this.#selectedElements.filter(element => element !== segment);
        this.list = this.#list.filter(s => s !== segment);
        const index = this.segments.findIndex(s => s === segment);
        if (!~index) throw new Error('Segment not found.');
        this.segments.splice(index, 1);
        this.groups.forEach(group => {
            if (group.segments.includes(segment)) group.removeSegment(segment);
        });
        this.engine.deleteSprite(segment);
        this.saveSketch();
    }

    public doneEditingGroup(description: string, attachments: Attachment[]) {
        const group = this.editingGroup;
        if (!group) return console.error('Group is not being edited.');
        group.description = description;
        group.attachments = attachments;
        group.included = [];
        group.segments.forEach(segment => {
            segment.attachments = [];
            segment.description = '';
        });
        this.editingGroup = undefined;
        this.saveSketch();
    }

    public canselCreatingGroup() {
        const group = this.editingGroup;
        if (!group) return console.error('Group is not being edited.');
        group.description = '';
        group.attachments = [];
        group.included = [];
        this.editingGroup = undefined;
    }

    public cancelEditingGroup() {
        const group = this.editingGroup;
        if (!group) return console.error('Group is not being edited.');
        // group.description = [group.description, ...group.included.map(segment => segment.description)].filter(Boolean).join(', ');
        // group.attachments = [...new Set([...group.attachments, ...group.included.flatMap(segment => segment.attachments)])];
        // group.included = [];
        // group.segments.forEach(segment => {
        //     segment.attachments = [];
        //     segment.description = '';
        // });
        this.editingGroup = undefined;
    }

    public setSelectBox(value: Box['value'] | null): void {
        this.engine.frame = value && {
            value,
            type: ActionType.POSITIVE_BOX,
        };
    }

    public selectSegmentsWithBox() {
        if (!this.engine.frame) throw new Error('Select box is not exist.');
        const { x: x0, y: y0 } = this.transformCoordinates(this.engine.frame.value[0], this.engine.frame.value[1]);
        const { x: x1, y: y1 } = this.transformCoordinates(this.engine.frame.value[2], this.engine.frame.value[3]);
        const selectBox = [x0, y0, x1, y1].map(Math.floor);
        const sortedBox = [
            selectBox[0] < selectBox[2] ? selectBox[0] : selectBox[2],
            selectBox[1] < selectBox[3] ? selectBox[1] : selectBox[3],
            selectBox[0] < selectBox[2] ? selectBox[2] : selectBox[0],
            selectBox[1] < selectBox[3] ? selectBox[3] : selectBox[1],
        ];
        const width = this.size.width;
        const height = this.size.height;
        const everyone = selectBox[0] > selectBox[2];
        const rectMask = new Uint8Array(width * height / 8);

        for (let h = sortedBox[1]; h <= sortedBox[3]; h++) {
            for (let w = sortedBox[0]; w <= sortedBox[2]; w++) {
                const index = h * width + w;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;

                rectMask[byteIndex] |= (1 << bitIndex);
            }
        }

        const intersected = this.segments.filter(segment =>
            !(segment.maskBox[0] > sortedBox[2] ||
                segment.maskBox[2] < sortedBox[0] ||
                segment.maskBox[1] > sortedBox[3] ||
                segment.maskBox[3] < sortedBox[1])
        ).filter(segment => {
            let intersection = 0;
            for (let i = 0; i < rectMask.length; i++) {
                let and = rectMask[i] & segment.mask[i];

                while (and) {
                    intersection += and & 1;
                    and >>= 1;
                }
            }

            if (everyone) return intersection / segment.maskSize > 0;
            else return intersection / segment.maskSize === 1;
        });

        this.selectElements(intersected);
    }

    public removeGroup(group: Group) {
        this.list = this.#list.filter(g => g !== group);
        group.segments.forEach(segment => this.removeSegment(segment));
        this.groups = this.#groups.filter(g => g !== group);
        this.saveSketch();
    }

    private async createSketch() {
        const data = JSON.stringify(this.getSketchData());

        const masks: Parameters<typeof createSketch>[1] = [];

        await Promise.all(this.segments.map(async segment => {
            const mask = await segment.getPng();

            masks.push({ mask, name: segment.name });
        }));

        await createSketch(data, masks, this.id);
    }

    public async saveSketch() {
        const data = JSON.stringify(this.getSketchData());

        const sketch = await this.engine.drawPreview();

        await saveSketch(data, sketch, this.#id);
    }

    public async renderSketch(settings: string) {
        await this.saveSketch();

        const sketch = await this.engine.drawPreview();

        const masks: Parameters<typeof renderSketch>[1] = [];

        await Promise.all(this.segments.map(async segment => {
            const color = segment.color;
            segment.color = [0, 160, 80, 255];
            const mask = await segment.getPng();
            segment.color = color;

            masks.push({ mask, id: segment.id });
        }));

        const data = JSON.stringify(this.getSketchData());

        await renderSketch(sketch, masks, data, settings, crypto.randomUUID());
    }

    public transformCoordinates(x: number, y: number) {
        if (!this.canvas) throw new Error('Canvas is null.');
        const position = this.engine.calcGlobalPosition();
        const scale = this.engine.calcScaleMatrix2();

        return {
            x: (x - (1 + position[0]) * this.canvas.width / 2) * this.size.width / this.canvas.width / scale[0],
            y: (y - (1 - position[3]) * this.canvas.height / 2) * this.size.height / this.canvas.height / scale[1],
        };
    }

    public hoverSegment = this._hoverSegment();

    private _hoverSegment() {
        let prevSegments: Array<Segment> = [];
        return (rawX: number, rawY: number) => {
            const { x, y } = this.transformCoordinates(rawX, rawY);
            if (x < 0 || x > this.size.width) return this.hoveredElement = null;
            if (y < 0 || y > this.size.height) return this.hoveredElement = null;
            const segments = this.segments.filter((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y)));
            if (prevSegments.length === segments.length) {
                const diff = segments.filter(segment => !prevSegments.includes(segment));
                if (diff.length === 0) return;
            }
            prevSegments = segments;
            /* @ts-ignore */
            const segment = this.segments.toReversed().find((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y)));
            if (!segment) return this.hoveredElement = null;
            const element = this.#groups.find(({ segments }) => segments.includes(segment)) || segment;
            this.hoveredElement = element;
        }
    }

    public setCursor(value: [number, number] | null) {
        this.engine.cursor = value;
    }

    public changeCursorRadius(delta: number) {
        if (delta > 0) this.engine.cursorRadius += 1;
        else this.engine.cursorRadius -= 1;
    }

    public tabHover(rawX: number, rawY: number) {
        if (!this.#hoveredElement) return;
        const { x, y } = this.transformCoordinates(rawX, rawY);
        /* @ts-ignore */
        const segments = this.segments.filter((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y))).toReversed();

        const index = segments.findIndex((segment: Segment) => {
            if (this.#hoveredElement instanceof Segment) return segment === this.#hoveredElement;
            else return this.#hoveredElement?.segments.includes(segment);
        });
        const segment = segments[(index + 1) % segments.length];
        const element = this.#groups.find(({ segments }) => segments.includes(segment)) || segment;
        this.hoveredElement = element;
    }

    public unhoverSegment() {
        this.hoveredElement = null;
    }

    public async combineSegments(segments: Array<Segment>, including: boolean) {
        if (!segments.length) throw new Error('Need 2 or more segments.');

        const segment = this.createNewSegment();

        segments.forEach(s => s.mask.forEach((value, index) => segment.mask[index] |= value));
        segment.updateBoundingRectangle();

        if (including) {
            segment.description = segments.map(({ description }) => description).filter(Boolean).join(', ');
            segment.attachments = [...new Set(segments.flatMap(segment => segment.attachments))];
        }

        const firstIndex = this.#list.findIndex(element => segments.includes(element as Segment));

        segments.forEach(segment => {
            const index = this.segments.findIndex(s => s === segment);
            if (~index) this.segments.splice(index, 1);
        });

        this.list = this.#list.filter(element => !segments.includes(element as Segment));
        this.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        /* @ts-ignore */
        this.list = this.#list.toSpliced(firstIndex, 0, segment);
        this.saveSketch();
        setTimeout(() => {
            const element = document.getElementById('element_' + segment.id);
            element?.scrollIntoView({ block: 'center' });
        }, 10);
        return segment;
    }

    public async editGroupHandler(adding: boolean) {
        if (!this.#editingGroup) return;
        /* @ts-ignore */
        const segment = this.#hoveredElement;
        if (!(segment instanceof Segment)) return;
        if (!segment) return false;
        const included = this.#editingGroup.segments.includes(segment);
        if (included) {
            if (adding) return;
            this.#editingGroup.removeSegment(segment);
            this.list = [...this.#list, segment];
        } else {
            if (!adding) return;
            const alreadyInGroup = this.groups.flatMap(({ segments }) => segments).includes(segment);
            if (alreadyInGroup) return;
            this.#editingGroup.addSegment(segment, 0);
            this.list = this.#list.filter(s => s !== segment);
        }
    }

    public scale(event: React.WheelEvent<HTMLCanvasElement>) {
        // Эта функция была написана по принципу, если долго и не осмысленно писать символы, то когда-нибудь из них получится правильно работающая программа
        const scale =
            event.deltaY > 0
                ? Math.max(this.engine.scale / 1.1, 0.3)
                : Math.min(this.engine.scale * 1.1, 3);

        const { offsetX, offsetY } = event.nativeEvent;
        const { width, height } = event.currentTarget;

        const multiplier = event.deltaY > 0
            ? this.engine.scale / scale
            : scale / this.engine.scale;

        const scaleMatrix = this.engine.calcScaleMatrix2();

        const cursorX = offsetX / width * 2 - 1;
        const pictureX = this.engine.translate[0] - scaleMatrix[0];

        const cursorY = 1 - offsetY / height * 2;
        const pictureY = this.engine.translate[1] + scaleMatrix[1];

        const deltaX = (cursorX - pictureX - scaleMatrix[0]);
        const deltaY = (pictureY - scaleMatrix[1] - cursorY);

        const valueX = deltaX * (multiplier - 1);
        const valueY = deltaY * (multiplier - 1);

        event.deltaY > 0 ? this.engine.translate[0] += valueX / multiplier : this.engine.translate[0] -= valueX;
        event.deltaY > 0 ? this.engine.translate[1] -= valueY / multiplier : this.engine.translate[1] += valueY;
        this.engine.scale = scale;
    }

    public scaleFromButton(value: number) {
        const scale =
            value > 0
                ? Math.max(this.engine.scale / 1.1, 0.3)
                : Math.min(this.engine.scale * 1.1, 3);

        this.engine.scale = scale;
    }

    public translate(translate: [number, number]) {
        this.engine.translate[0] += translate[0] * 2;
        this.engine.translate[1] += translate[1] * 2;
    }

    public fit() {
        this.engine.translate = [0, 0];
        this.engine.scale = 1;
    }
}

export function useAttachment<T extends keyof Pick<Attachment, 'note'>>(attachment: Attachment, key: T) {
    const [value, setValue] = useState(attachment[key]);

    useEffect(() => {
        setValue(attachment[key]);
        attachment.addListener(key, setValue);
        return () => void attachment.removeListener(key, setValue);
    }, [attachment, key]);

    return value;
}

export function useSegment<T extends keyof Pick<Segment, 'description' | 'name' | 'attachments' | 'state'>>(segment: Segment, key: T) {
    const [value, setValue] = useState(segment[key]);

    useEffect(() => {
        setValue(segment[key]);
        segment.addListener(key, setValue);
        return () => void segment.removeListener(key, setValue);
    }, [segment, key]);

    return value;
}

export function useGroup<T extends keyof Pick<Group, 'segments' | 'description' | 'attachments' | 'name' | 'isOpenForEditing'>>(group: Group, key: T) {
    const [value, setValue] = useState(group[key]);

    useEffect(() => {
        group.addListener(key, setValue);
        return () => void group.removeListener(key, setValue);
    }, [group, key]);

    return value;
}

export function useEditor<T extends keyof Pick<SegmentEditor, 'editing' | 'undo' | 'redo' | 'segment' | 'onnxState'>>(key: T) {
    const editor = useManager('segmentEditor');
    const [value, setValue] = useState(editor[key]);

    useEffect(() => {
        editor.addListener(key, setValue);
        return () => void editor.removeListener(key, setValue);
    }, [editor, key]);

    return value;
}

export function useManager<T extends keyof Pick<Manager, 'segments' | 'loaded' | 'groups' | 'self' | 'list' | 'editingGroup' | 'selectedElements' | 'segmentEditor' | 'hoveredElement' | 'name' | 'deltaFrame'>>(key: T): Manager[T] {
    const manager = useStore($manager);
    if (!manager) throw new Error('Manager is undefined.');
    const [value, setValue] = useState(manager[key]);

    useEffect(() => {
        if (!manager) return;
        setValue(manager[key]);
        manager.addListener(key, setValue);
        return () => void manager.removeListener(key, setValue);
    }, [manager, key]);

    return value;
}
