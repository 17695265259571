import React from "react";
import './Notification.scss'
import { useStore } from "effector-react";
import { $filename, $notification, setNotification, toggleNotificationShow } from "entities/notification";
import { getNotificationText } from "./helper";
import { CheckboxBlack } from "Components/Checkbox/Checkbox";



export default function Notification() {
    const notification = useStore($notification)
    const filename = useStore($filename);

    return (
        <>
            {notification &&
                <div className="notification">
                    <div className="notification_header">
                        <div className="notification_header-type">
                            <div className={`notification-type_icon ${notification.type.toLowerCase()}_icon`} />
                            <p>{notification.title}</p>
                        </div>
                        <div className="notification_close-icon" onClick={() => setNotification(null)} />
                    </div>
                    <div className="notification_text">
                        {getNotificationText(notification.notification, filename)}
                        {notification.show_again !== undefined &&
                            <div className="notification_checkbox-wrapper">
                                <CheckboxBlack checked={!notification.show_again} onChange={() => toggleNotificationShow()} className="notification_checkbox" />
                                <p>Sure, don't show this message again</p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}