import { useCallback } from 'react';
import { setLeftSidePanel, LeftSidePanel as LeftSidePanelEnum } from 'entities/leftSidePanel';
import { ReactComponent as CloseSVG } from './icons/close.svg';
import './LeftSidePanel.scss';


type LeftSidePanelProps = {
    children: React.ReactNode;
};

export default function LeftSidePanel({ children }: LeftSidePanelProps) {
    const close = useCallback(() => setLeftSidePanel(LeftSidePanelEnum.NONE), []);

    return (
        <div className="left-side-panel">
            <div className="left-side-panel__container">
                <CloseSVG className="left-side-panel__close-button" onClick={close} />
                {children}
            </div>
        </div>
    );
}
