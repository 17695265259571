import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CreateGroupModal, closeModal } from 'entities/modal';
import { ReactComponent as CrossSvg } from './icons/cross.svg';
import { ReactComponent as CheckboxSvg } from './icons/checkbox.svg';
import Button from 'Components/Button';
import './CreateGroup.scss';


export default function CreateGroup({ res, rej }: CreateGroupModal['props']) {
    const [saving, setSaving] = useState(true);

    useEffect(() => rej, [rej]);

    const resolve = useCallback((including: boolean) => {
        closeModal();
        res({ including, saving });
    }, [res, saving]);

    const reject = useCallback(() => {
        closeModal();
        rej();
    }, [rej]);

    return (
        <div className="create-group-modal__container">
            <div className="create-group-modal">
                <div className="create-group-modal__exit-button-container">
                    <CrossSvg className="create-group-modal__exit-button" onClick={reject} />
                </div>
                <div className="create-group-modal__title">Create group</div>
                <div className="create-group-modal__text">Include existing object’s description and attachments in the group?</div>
                <div className="create-group-modal__checkbox-container">
                    <CheckboxSvg className={classNames({ 'create-group-modal__checkbox': true, 'create-group-modal__checkbox_active': saving })} onClick={() => setSaving(prev => !prev)} />
                    <div className="create-group-modal__checkbox-label">Remember settings for this group creation session</div>
                </div>
                <div className="create-group-modal__buttons-container">
                    <Button onClick={() => resolve(true)}>INCLUDE</Button>
                    <Button onClick={() => resolve(false)}>NO</Button>
                </div>
            </div>
        </div>
    );
}