import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, DragUpdate, DropResult, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { Segment, useManager, Group as GroupClass } from 'entities/sketch/Manager';
import SegmentElement from './SegmentElement';
import { ReactComponent as CheckboxSvg } from './icons/checkbox.svg';
import { ReactComponent as EditSvg } from './icons/edit.svg';
import { ReactComponent as UngroupSvg } from './icons/ungroup.svg';
import { ReactComponent as JoinSvg } from './icons/join.svg';
import { ReactComponent as DeleteSvg } from './icons/delete.svg';
import { ReactComponent as DiscardSvg } from './icons/discard.svg'
import { Group, EditingGroup } from './Group';
import { JoinModal, ModalType, openModal } from 'entities/modal';
import { setHint } from 'entities/hint';
import { useHotKey } from 'hooks';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { useStore } from 'effector-react';
import { $elementForAttach } from 'entities/library';
import Attachments from '../Attachments';
import { $tutorial } from 'entities/tutorial';
import useScrollDetection from 'services/useScrollDetection';
import './List.scss';


export default function List() {
    const list = useManager('list');
    const selectedElements = useManager('selectedElements')
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const [hoveredEditingGroup, setHoveredEditingGroup] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);
    const isScrolling = useScrollDetection({ ref: listRef })
    const [removedSegmentFromGroupID, setRemovedSegmentFromGroupID] = useState<string | undefined>(undefined);
    const createGroupDisabled = useMemo(() => selectedElements.some(element => element instanceof GroupClass || element.groupId) || selectedElements.length === 0, [selectedElements]);
    const editDisabled = useMemo(() => selectedElements.some(element => element instanceof Segment) || selectedElements.length !== 1, [selectedElements]);
    const ungroupDisabled = useMemo(() => selectedElements.some(element => element instanceof Segment) || selectedElements.length === 0, [selectedElements]);
    const joinDisabled = useMemo(() => selectedElements.some(element => element instanceof GroupClass || element.groupId) || selectedElements.length < 2, [selectedElements]);
    const deleteDisabled = useMemo(() => selectedElements.some(element => element instanceof Segment && element.groupId) || selectedElements.length === 0, [selectedElements]);

    const elementForAttach = useStore($elementForAttach);

    const tutorial = useStore($tutorial)

    const handleCreateGroup = useCallback(async () => {
        if (createGroupDisabled) return;
        try {
            // const needAsk = (selectedElements as Array<Segment>).some(segment => segment.description || segment.attachments.length);
            // const result = needAsk
            //     ?
            //     await new Promise<Parameters<CreateGroupModal['props']['res']>[0]>((res, rej) => openModal({ type: ModalType.CREATE_GROUP, props: { res, rej } }))
            //     :
            //     {};
            //BACK 
            // const group = segments.createGroup(selectedElements as Array<Segment>, result);
            const group = manager.createGroup(selectedElements as Array<Segment>, {}, { state: 'creating' });
            manager.editingGroup = group;
            manager.selectOneElement(group);
        } catch (e) { }
    }, [selectedElements, manager, createGroupDisabled]);

    const handleEdit = useCallback(() => {
        if (editDisabled) return;
        // segments.editingGroup = selectedElements[0] as GroupClass;
        const elements = selectedElements[0] as GroupClass;
        elements.state = 'editing'
        manager.editingGroup = elements;
        manager.unselectAllElements();
    }, [selectedElements, editDisabled, manager]);

    const handleUngroup = useCallback(() => {
        if (ungroupDisabled) return;
        manager.ungroup(selectedElements as Array<GroupClass>);
        manager.unselectAllElements();
    }, [selectedElements, manager, ungroupDisabled]);

    const handleJoin = useCallback(async () => {
        if (joinDisabled) return;
        try {
            const needAsk = (selectedElements as Array<Segment>).some(segment => segment.description || segment.attachments.length);
            const result = needAsk
                ?
                await new Promise<Parameters<JoinModal['props']['res']>[0]>((res, rej) => openModal({ type: ModalType.JOIN, props: { res, rej } }))
                :
                false;
            const segment = await manager.combineSegments(selectedElements as Array<Segment>, result);
            manager.selectOneElement(segment);
        } catch (e) { }
    }, [selectedElements, manager, joinDisabled]);

    const handleDelete = useCallback(async () => {
        if (deleteDisabled) return;
        selectedElements.forEach(element => {
            if (element instanceof Segment) manager.removeSegment(element);
            if (element instanceof GroupClass) manager.removeGroup(element);
        });
    }, [selectedElements, manager, deleteDisabled]);

    useHotKey('Delete', handleDelete);

    const handleDragEnd = useCallback(async (event: DropResult) => {
        if (!event.destination) return;
        if (event.destination.index === event.source.index) return;

        if (!editingGroup) return manager.changeOrder(event.source.index, event.destination.index);

        const draggableElement = [...manager.groups, ...manager.segments].find(element => element.id === event.draggableId);
        if (!draggableElement) throw new Error('Drag error.');

        if (hoveredEditingGroup && !editingGroup.isOpenForEditing) {
            if (draggableElement instanceof GroupClass) return;
            manager.addSegmentToGroup(draggableElement, editingGroup, 0);
            editingGroup.isOpenForEditing = true;
            return setHoveredEditingGroup(false);
        }

        const groupIndex = list.findIndex(element => element === editingGroup);
        const term = event.source.index < groupIndex ? 1 : 0;
        const toGroup = event.destination.index + term > groupIndex && event.destination.index <= (groupIndex + editingGroup.segments.length);
        if (toGroup) {
            const term = event.source.index > groupIndex ? -1 : 0;
            const fromGroup = event.source.index > groupIndex && event.source.index <= (groupIndex + editingGroup.segments.length);
            if (fromGroup) return editingGroup.changeSegmentPosition(event.source.index - groupIndex - 1, event.destination.index - groupIndex - 1);
            else {
                if (draggableElement instanceof GroupClass) return;
                return manager.addSegmentToGroup(draggableElement, editingGroup, event.destination.index - groupIndex + term);
            }
        } else {
            const term = event.destination.index <= groupIndex ? -1 : 0;
            const sourceIndex = event.source.index < groupIndex ? event.source.index : (event.source.index - editingGroup.segments.length);
            const destinationIndex = event.destination.index <= groupIndex ? event.destination.index : (event.destination.index - editingGroup.segments.length);
            const fromGroup = event.source.index > groupIndex && event.source.index <= (groupIndex + editingGroup.segments.length);
            if (fromGroup) {
                if (draggableElement instanceof GroupClass) return;
                manager.removeSegmentFromGroup(draggableElement, editingGroup, destinationIndex + 1 + term);
                setRemovedSegmentFromGroupID(draggableElement.id);
            }
            else return manager.changeOrder(sourceIndex, destinationIndex);
        }
    }, [manager, editingGroup, list, hoveredEditingGroup]);

    const createList = useMemo(() => {
        let counter = 0;
        let editGroupCounter = 0;
        return list.map((element, index) => {
            if (element instanceof Segment) return (<SegmentElement segment={element} realIndex={editGroupCounter === 0 ? index : index - 1} index={index + counter} key={element.id} />);

            if (element instanceof GroupClass) {
                if (element === editingGroup) {
                    counter += element.segments.length;
                    editGroupCounter += 1;
                    if (tutorial.step === 0) return (<EditingGroup group={element} index={index + counter - element.segments.length} key={element.id} hovered={hoveredEditingGroup} removedSegmentFromGroupID={removedSegmentFromGroupID} setRemovedSegmentFromGroupID={setRemovedSegmentFromGroupID} />);
                    else return null
                }
                return (<Group group={element} index={index + counter} key={element.id} realIndex={editGroupCounter === 0 ? index : index - 1} />);
            }
            return null;
        })
    }, [list, editingGroup, hoveredEditingGroup, removedSegmentFromGroupID, tutorial.step]);

    const onDragUpdate = useCallback((dragUpdate: DragUpdate) => {
        const destination = dragUpdate.destination;
        if (!destination) return;
        const trueList = list.map(element => {
            if (element instanceof GroupClass) return [element, ...element.segments];
            if (element instanceof Segment) return element;
            throw new Error('Element has unknown type.');
        }).flatMap(i => i);
        setHoveredEditingGroup(trueList[destination.index + 1] === editingGroup);
    }, [list, editingGroup]);

    useEffect(() => {
        if (tutorial.step === 1) {
            setTimeout(() => {
                if (listRef.current) listRef.current.scrollTo({ top: 0, behavior: "smooth" });
            }, 100);
        }
    }, [tutorial.step]);

    useEffect(() => {
        if (isScrolling) setHint(null);
    }, [isScrolling]);

    return (
        <div className="list_wrapper">
            <Attachments element={elementForAttach} />
            <div className="list_tools">
                <div className="list__toolbar" id="list__toolbar">
                    <div className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': joinDisabled || editingGroup })} onClick={handleJoin}
                        id="join"
                        onMouseEnter={() => setHint({ id: 'join' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <JoinSvg />
                        <div className="list__toolbar-button-text">Join masks</div>
                    </div>
                    <div className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': editDisabled && createGroupDisabled })}
                        id="edit_group"
                        onClick={() => {
                            if (editDisabled) return handleCreateGroup()
                            if (createGroupDisabled) return handleEdit()
                        }}
                        onMouseEnter={() => setHint({ id: 'edit_group' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <EditSvg />
                        <div className="list__toolbar-button-text">group Editing</div>
                    </div>
                    <div className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': ungroupDisabled })}
                        onClick={handleUngroup}
                        id='ungroup'
                        onMouseEnter={() => setHint({ id: 'ungroup' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <UngroupSvg />
                        <div className="list__toolbar-button-text">Ungroup</div>
                    </div>
                    <div className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': deleteDisabled || editingGroup })}
                        id='undefine'
                        onMouseEnter={() => setHint({ id: 'undefine' })}
                        onMouseLeave={() => setHint(null)}>
                        <DiscardSvg />
                        <div className="list__toolbar-button-text">undefine</div>
                    </div>
                    <div className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': deleteDisabled || editingGroup })}
                        onClick={handleDelete}
                        id='delete'
                        onMouseEnter={() => setHint({ id: 'delete' })}
                        onMouseLeave={() => setHint(null)}>
                        <DeleteSvg />
                        <div className="list__toolbar-button-text">delete</div>
                    </div>
                    <div className="list__toolbar-sizer" />
                    <button className='render__button' onClick={() => setLeftSidePanel(LeftSidePanel.SUBMIT_FOR_RENDERING)}>
                        <div className='render__button_icon' />
                        render
                    </button>
                </div>
                <div id='list__head' className="list__head">
                    <Checkbox />
                    <div className="list__head-cell" id='grid'>#</div>
                    <div className="list__head-cell-no-border">description</div>
                </div>
            </div>
            <div className={classNames({ 'list': true, 'list_scrolling': isScrolling })} ref={listRef}>
                <div className="list__table" >
                    <div className='list__table-elements-wrapper' id='list__table-elements'>
                        <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={onDragUpdate}>
                            <Droppable droppableId="single">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            createList
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Checkbox() {
    const list = useManager('list');
    const selectedElements = useManager('selectedElements');
    const manager = useManager('self');

    const partSelected = useMemo(() => selectedElements.length > 0 && selectedElements.length < list.length, [selectedElements, list]);
    const allSelected = useMemo(() => selectedElements.length === list.length, [selectedElements, list]);

    const handleClick = useCallback(() => {
        if (allSelected) return manager.unselectAllElements();
        if (partSelected) return manager.unselectAllElements();
        manager.selectElements(list);
    }, [partSelected, allSelected, manager, list]);

    return (
        <div className="list__head-cell"
            id={'checkbox'}
            onMouseEnter={() => setHint({ id: partSelected || allSelected ? 'checkbox_selected' : 'checkbox' })}
            onMouseLeave={() => setHint(null)}>
            <CheckboxSvg
                className={classNames({
                    'list__head-cell-checkbox': true,
                    'list__head-cell-checkbox_part-selected': selectedElements.length > 1,
                })}
                onClick={handleClick}
            />
        </div>
    );
}
