const config = {
    serverUrl: process.env.REACT_APP_SERVER_URL || '',
    backendUrl: process.env.REACT_APP_BECKEND_URL || '',
    minioUrl: 'https://minioserver.quarters-dev.site',
    trainerUrl: 'https://trainer.api.quarters-dev.site',
    backet: 'backup',
    magicPK: process.env.REACT_APP_MAGIC_KEY || '',
    googleId: process.env.REACT_APP_GOOGLE_ID || '',
};

export default config;
