import config from 'config';

type GoogleAuthType = {
    code: string;
    teamSize?: string;
    profession?: string;
    firstName?: string;
    lastName?: string;
    subscribeNewsletter?: boolean;
};

export async function googleAuth({ code, teamSize, profession, firstName, lastName, subscribeNewsletter }: GoogleAuthType): Promise<{ email: string, id: string }> {
    const res = await fetch(`${config.backendUrl}/auth/google-demo`, {
        method: 'POST',
        body: JSON.stringify({ code, teamSize, profession, firstName, lastName, subscribeNewsletter }),
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
    return data.result;
}

type MagicAuthProps = {
    token: string;
    teamSize?: string;
    profession?: string;
    firstName?: string;
    lastName?: string;
    subscribeNewsletter?: boolean;
};

export async function magicAuth({ token, teamSize, profession, firstName, lastName, subscribeNewsletter }: MagicAuthProps): Promise<{ email: string, id: string }> {
    const res = await fetch(`${config.backendUrl}/auth/magic`, {
        method: 'POST',
        body: JSON.stringify({ token, teamSize, profession, firstName, lastName, subscribeNewsletter }),
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
    return data.result;
}

export async function login(): Promise<UserData> {
    const res = await fetch(`${config.backendUrl}/auth/login`, { credentials: 'include', method: 'POST' });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
    return data.result;
}

export class UserData {
    id: string = '';
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    businessName: string = '';
    profession: string = '';
    businessPhone: string = '';
    businessEmail: string = '';
    businessWebsiteLink: string = '';
    teamSize: string = '';
    linkedin: string = '';
    earlyDesignSoftware: string = '';
    subscribeNewsletter: boolean = false;
}

export async function updateUserData(userData: Omit<UserData, 'id' | 'email'>) {
    const res = await fetch(`${config.backendUrl}/api/user-data`, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(userData),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
}

export async function logout() {
    const res = await fetch(`${config.backendUrl}/auth/logout`, { credentials: 'include', method: 'POST' });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
}

export async function deleteUser() {
    const res = await fetch(`${config.backendUrl}/api/user`, { credentials: 'include', method: 'DELETE' });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
}

export async function checkEmail(email: string): Promise<boolean> {
    const res = await fetch(`${config.backendUrl}/api/email-exist/${email}`);

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();
    if (data.error) throw new Error(data.result);
    return data.result;
}