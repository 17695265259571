// import { useEffect, useMemo, useState } from 'react';
// import { FileData } from 'services/library';
// import { useStore } from 'effector-react';
// import { $library, setElementForAttach } from 'entities/library';
import { Group, Segment } from 'entities/sketch/Manager';
import { Tab } from '../Attachments';
import Button from 'Components/Button';
import { setHelpSection } from 'entities/help';
import { HelpSections } from 'Components/Help/helpers/helpers';
import './Library.scss';


// enum Type {
//     CAD = 'CAD',
//     TEXTS = 'TEXTS',
//     IMAGES = 'IMAGES',
// }

// function getFilter(name: Type) {
//     switch (name) {
//         case Type.CAD:
//             return function (data: FileData) {
//                 const extname = data.originalname.split('.').splice(-1)[0];
//                 return ['rvt', 'dwg', 'dxf'].includes(extname);
//             }
//         case Type.TEXTS:
//             return function (data: FileData) {
//                 const extname = data.originalname.split('.').splice(-1)[0];
//                 return ['txt', 'url'].includes(extname);
//             }
//         case Type.IMAGES:
//             return function (data: FileData) {
//                 const extname = data.originalname.split('.').splice(-1)[0];
//                 return ['png', 'jpg', 'jpeg'].includes(extname);
//             }
//         default:
//             console.error(name + ' not implemented.');
//             return function () {
//                 return true;
//             }
//     }
// }

type Props = {
    element: Segment | Group;
    setTab: (tab: Tab) => void;
}

export default function Library({ element, setTab }: Props) {
    // const [type, setType] = useState(Type.CAD);
    // const [chosenFile, setChosenFile] = useState<number | null>(null);
    // const library = useStore($library);
    // const files = useMemo(() => library.filter(getFilter(type)), [type, library]);


    // useEffect(() => {
    //     const button = document.getElementById('apply-attachments');
    //     if (!(button instanceof HTMLButtonElement)) return console.error('Button not found.');
    //     const file = files[chosenFile!];
    //     button.onclick = () => {
    //         element.attach(file);
    //         setElementForAttach(null);
    //         setTab(Tab.UPLOAD);
    //     };
    // }, [files, element, chosenFile, setTab]);

    return (
        <div className="library-tab">
            <div className='products-attach_descr'>
                <p>Try adding the preset definition of context that the artificial intelligence of io-buro already knows. Action will automatically replace all preexisting attachments with newly attached definition.</p>
                <div className='read_more'  onClick={()=>setHelpSection({section: HelpSections.DESCRIBE, itemId: 'help-section__entourage'})}>
                    <p>read more</p>
                    <div className='read_more-icon' />
                </div>

            </div>
            <div className='context-attach-area'>
                {Array(15).fill(null).map((_, index) => (
                    <div key={index} className='context-attach__container'>
                        <div className='context-attach__image-wrapper' />
                        <div className='context-attach__info'>
                            <div className='context-attach__info-title'>Entourage Name</div>
                            <Button color='dark'>Add</Button>
                        </div>

                    </div>
                ))}

            </div>
            {/* <div className="library-tab__radio">
                <div className="library-tab__radion-item" onClick={() => setType(Type.CAD)}>
                    <div className={classNames({ "library-tab__radio-input": true, "library-tab__radio-input_active": type === Type.CAD })} />
                    <div className="library-tab__radion-label">CAD</div>
                </div>
                <div className="library-tab__radion-item" onClick={() => setType(Type.TEXTS)}>
                    <div className={classNames({ "library-tab__radio-input": true, "library-tab__radio-input_active": type === Type.TEXTS })} />
                    <div className="library-tab__radion-label">Texts</div>
                </div>
                <div className="library-tab__radion-item" onClick={() => setType(Type.IMAGES)}>
                    <div className={classNames({ "library-tab__radio-input": true, "library-tab__radio-input_active": type === Type.IMAGES })} />
                    <div className="library-tab__radion-label">Images</div>
                </div>
            </div>
            <div className="library-tab__list">
                {
                    files.map((file, fileIndex) =>
                        <div className="library-tab__item" key={file.filename} onClick={() => setChosenFile(fileIndex)}>
                            <div className={classNames({ "library-tab__document": true, "library-tab__document_active": chosenFile === fileIndex })}>
                                <div className="library-tab__item-icon" />
                                <div className="library-tab__res">{file.filename.split('.').splice(-1)[0]}</div>
                            </div>
                            <div className="library-tab__name">{file.originalname}</div>
                        </div>
                    )
                }
            </div> */}
        </div>
    );
}