import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { $file } from 'entities/sketch';
import { useStore } from 'effector-react';
import * as Listeners from '../Listeners';
import { ReactComponent as PlusSvg } from './icons/plus.svg';
import { ReactComponent as MinusSvg } from './icons/minus.svg';
import { useEditor, useManager } from 'entities/sketch/Manager';
import useStatusBar from './hooks/useStatusBar';
import { setHint } from 'entities/hint';
import useCursor from './hooks/useCursos';
import { $modal, ModalType } from 'entities/modal';
import { $elementForAttach } from 'entities/library';
import Switcher from 'Components/Switcher';
import { $tutorial } from 'entities/tutorial';
import { SpriteState } from 'entities/sketch/Engine/Engine';
import './Drawing.scss';


type DrawingProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
    undefinedMode: boolean;
    setUndefinedMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Drawing({ listener, setListener, undefinedMode, setUndefinedMode }: DrawingProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [masksHidden, setMasksHidden] = useState(false);
    const [size, setSize] = useState<{ width: 'auto' | number, height: number }>({ width: 'auto', height: 300 });
    const [grabbing, setGrabbing] = useState(false);
    const status = useStatusBar(listener);
    const editing = useEditor('editing');
    const onnxState = useEditor('onnxState');
    const modal = useStore($modal);
    const elementForAttach = useStore($elementForAttach);
    const drawingFooterRef = useRef<HTMLDivElement | null>(null);
    const tutorial = useStore($tutorial);
    const manager = useManager('self');
    const deltaFrame = useManager('deltaFrame');
    const file = useStore($file);

    useCursor(listener);

    useEffect(() => {
        const canvas = document.getElementById('drawing__container');
        if (!canvas) return;
        let prev: Listeners.Listener | undefined;

        function onMouseDown(e: MouseEvent) {
            if (e.button !== 1) return;
            setListener(value => {
                prev = value;
                return new Listeners.Zoom(manager);
            });
            listener.onMouseDown(e);
        }

        function onMouseUp() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
            listener.onMouseLeave();
        }

        canvas.addEventListener('mousedown', onMouseDown);
        canvas.addEventListener('mouseup', onMouseUp);
        canvas.addEventListener('mouseleave', onMouseUp);
        return () => {
            canvas.removeEventListener('mousedown', onMouseDown);
            canvas.removeEventListener('mouseup', onMouseUp);
            canvas.removeEventListener('mouseleave', onMouseUp);
        };
    }, [manager, listener, setListener]);

    const { startDrag, endDrag } = useMemo(() => {
        let prev: Listeners.Listener | undefined;

        function startDrag(e?: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) {
            setListener(value => {
                if (value instanceof Listeners.Zoom) return value;
                prev = value;
                const next = new Listeners.Zoom(manager);
                if (e) next.onMouseDown(e);
                return next;
            });
        }

        function endDrag() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
        }

        return { startDrag, endDrag };
    }, [manager, setListener]);

    useEffect(() => {
        function start(e: KeyboardEvent) {
            if (e.code === 'Space' && document.activeElement instanceof HTMLBodyElement) startDrag();
        }

        function end(e: KeyboardEvent) {
            if (e.code === 'Space') endDrag();
        }

        document.addEventListener('keydown', start);
        document.addEventListener('keyup', end);

        return () => {
            document.removeEventListener('keydown', start);
            document.removeEventListener('keyup', end);
        };
    }, [startDrag, endDrag]);

    useEffect(() => {
        if (modal.type === ModalType.NONE && !elementForAttach) {
            function fn(e: KeyboardEvent) {
                if (e.code !== 'Escape') return;

                manager.unselectAllElements();
            }

            document.addEventListener('keydown', fn);
            return () => document.removeEventListener('keydown', fn);
        }
    }, [modal, manager, elementForAttach]);

    const treeWheel = useCallback((event: React.WheelEvent<HTMLCanvasElement>) => {
        if (tutorial.step !== 0) return
        manager.scale(event);
    }, [manager, tutorial.step]);

    useEffect(() => {
        const handleKeydown = (e: KeyboardEvent) => {
            if (listener instanceof Listeners.Select && e.key === 'Tab') {
                e.preventDefault();
                listener.handleTab();
            }
        }

        document.addEventListener('keydown', handleKeydown);

        return () => document.removeEventListener('keydown', handleKeydown);
    }, [listener]);

    useEffect(() => {
        if (!file) throw new Error('File is null.');
        const canvas = document.getElementById('canvas');
        if (!(canvas instanceof HTMLCanvasElement)) throw new Error('canvas not canvas =)');
        if (!manager) throw new Error('Segments not exist');
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => manager.connectCanvas(canvas, image);
    }, [manager, file]);

    //CHECK
    useEffect(() => {
        if (tutorial.step === 0) return;

        manager.fit();
        manager.updateTutorialDraw(tutorial.step);
    }, [manager, tutorial.step])

    useEffect(() => {
        if (editing) return;

        if (masksHidden) return manager.segments.forEach(segment => segment.state = SpriteState.HIDE);

        if (undefinedMode) return manager.segments.forEach(segment => {
            if ((segment.description === '' || segment.description === 'Group description: ') && segment.attachments.length === 0) segment.state = SpriteState.REGULAR;
            else segment.state = SpriteState.HIDE;
        });

        return manager.segments.forEach(segment => segment.state = SpriteState.REGULAR);
    }, [undefinedMode, masksHidden, editing, manager]);

    useEffect(() => {
        const container = ref.current;
        if (!container) throw new Error('Container not exist.');

        const resizeObserver = new ResizeObserver(e => setSize({ width: e[0].contentRect.width, height: e[0].contentRect.height }));
        resizeObserver.observe(container);

        return () => resizeObserver.unobserve(container);
    }, []);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) return;

        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "[") manager.changeCursorRadius(-1);

            if (event.key === "]") manager.changeCursorRadius(1);
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) manager.setCursor(null);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.Zoom)) setGrabbing(false);
        else listener.setGrabbing = setGrabbing;
    }, [listener]);

    const onMouseDown = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) => {
        if (e.button !== 1) return listener.onMouseDown(e);

        startDrag(e);
    }, [listener, startDrag]);

    const onMouseUp = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onMouseUp(e);

        endDrag();
    }, [listener, endDrag]);

    const onMouseClick = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onClick(e);
    }, [listener]);

    return (
        <div className="drawing" ref={ref}>
            <canvas
                id="canvas"
                className={classNames({
                    drawing__canvas: true,
                    'cursor-box': listener instanceof Listeners.BoxListener || (deltaFrame !== null && deltaFrame === 0),
                    'cursor-box-red': deltaFrame !== null && deltaFrame < 0,
                    'cursor-box-blue': deltaFrame !== null && deltaFrame > 0,
                    'cursor-add': listener instanceof Listeners.PositivePoint,
                    'cursor-remove': listener instanceof Listeners.NegativePoint,
                    'cursor-brush-tool': listener instanceof Listeners.BrushToolListener,
                    'cursor-grab': listener instanceof Listeners.Zoom,
                    'cursor-grabbing': grabbing,
                })}
                onWheel={treeWheel}
                onMouseDown={e => onMouseDown(e)}
                onMouseMove={e => listener.onMouseMove(e)}
                onMouseUp={e => onMouseUp(e)}
                onClick={e => onMouseClick(e)}
                onDoubleClick={e => listener.onDoubleClick(e)}
                onMouseLeave={() => listener.onMouseLeave()}
                {...size}
            />
            {/* {tutorial && tutorial.step !== 0 && <div className='drawing__footer-bar_cover' style={{ height: drawingFooterHeight }} />} */}
            <div className="drawing__footer-bar" ref={drawingFooterRef}>
                <div className='drawing__footer-bar-left_parth'>
                    <div className={classNames({ 'drawing__label-switcher': true })}
                        id='undefined-switcher'
                        onMouseEnter={() => setHint({ id: 'undefined-switcher' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <div className="drawing__switcher-text">SHOW undefined</div>
                        <Switcher value={undefinedMode} onClick={() => setUndefinedMode(prev => !prev)} />
                    </div >
                    <div
                        className="drawing__original-switcher"
                        id='original_switcher'
                        onMouseEnter={() => setHint({ id: 'original_switcher' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <div className="drawing__switcher-text">HIDE MASKS</div>
                        <Switcher value={masksHidden} onClick={() => setMasksHidden(prev => !prev)} />
                    </div>
                </div >
                <div className="drawing__zoom-buttons" id='drawing__zoom-buttons'>
                    <div className="drawing__zoom-minus"
                        onClick={() => manager.scaleFromButton(1)}
                        id='zoom_in'
                        onMouseEnter={() => setHint({ id: 'zoom_in' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <MinusSvg />
                    </div>
                    <div className="drawing__zoom-plus"
                        onClick={() => manager.scaleFromButton(-1)}
                        onMouseEnter={() => setHint({ id: 'zoom_out' })}
                        onMouseLeave={() => setHint(null)}
                        id='zoom_out'>
                        <PlusSvg />
                    </div>
                    <div className="drawing__zoom-fit" onClick={() => manager.fit()}
                        id='zoom_fit'
                        onMouseEnter={() => setHint({ id: 'zoom_fit' })}
                        onMouseLeave={() => setHint(null)}>
                        FIT
                    </div>
                </div>
            </div >
            <div className="drawing__footer">{status} <br />\\\\\busy: {onnxState.busy ? 'true' : 'false'}, prev time: {onnxState.prevTime}</div>
        </div >
    );
}
