import { useCallback, useState } from 'react';
import config from 'config';
import Checkbox from 'Components/Checkbox';
import Input from 'Components/Input';
import Button from 'Components/Button';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { magicAuthFx } from 'entities/user';
import { Step, magic } from '../Auth';
import './SignUpStepTwo.scss';


type SignUpStepTwoProps = {
    setStep: React.Dispatch<React.SetStateAction<Step>>;
    teamSize: string | undefined;
    profession: string | undefined;
};

export default function SignUpStepTwo({ setStep, teamSize, profession }: SignUpStepTwoProps) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);

    const onClickGoogle = useCallback(() => {
        const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('client_id', config.googleId);
        url.searchParams.append('redirect_uri', window.location.origin);
        url.searchParams.append('scope', 'email');
        url.searchParams.append('state', JSON.stringify({ teamSize, profession, firstName, lastName, subscribeNewsletter }));

        window.location.href = url.toString();
    }, [teamSize, profession, firstName, lastName, subscribeNewsletter]);

    const signUpWithMagic = useCallback(async () => {
        const token = await magic.auth.loginWithMagicLink({ email });
        if (!token) throw new Error('Token not exist.');
        magicAuthFx({ token, teamSize, profession, firstName, lastName, subscribeNewsletter });
    }, [email, teamSize, profession, firstName, lastName, subscribeNewsletter]);

    return (
        <div className="sign-up-step-two">
            <div className="sign-up-step-two__title-container">
                <div className="sign-up-step-two__title">Sign up</div>
            </div>
            <div className="sign-up-step-two__form-container">
                <div className="sign-up-step-two__form-gmail" onClick={onClickGoogle}>
                    <div className="sign-up-step-two__form-gmail-icon" />
                    <div className="sign-up-step-two__form-gmail-text">SIGN UP WITH GOOGLE</div>
                </div>
                <div className="sign-up-step-two__form-title">OR SIGN UP WITH YOUR EMAIL</div>
                <div className="sign-up-step-two__first-name-label">FIRST NAME</div>
                <Input className="sign-up-step-two__first-name-input" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} placeholder="Enter your first name" />
                <div className="sign-up-step-two__last-name-label">LAST NAME</div>
                <Input className="sign-up-step-two__last-name-input" value={lastName} onChange={e => setLastName(e.currentTarget.value)} placeholder="Enter your last name" />
                <div className="sign-up-step-two__email-label">EMAIL</div>
                <Input className="sign-up-step-two__email-input" value={email} onChange={e => setEmail(e.currentTarget.value)} placeholder="Enter your email" />
                <Button disabled={!firstName || !lastName || !email} className="sign-up-step-two__form-button" onClick={signUpWithMagic}>SIGN UP</Button>
                <div className="sign-up-step-two__form-checkbox-form">
                    <Checkbox checked={subscribeNewsletter} onChange={setSubscribeNewsletter} />
                    <div className="sign-up-step-two__form-checkbox-label">Subscribe to the newsletter.</div>
                </div>
                <div className="sign-up-step-two__form-sign-up">Already has an account? <span className="sign-up-step-two__form-sign-up-create-link" onClick={() => setStep(Step.SIGN_IN)}>Sign in</span></div>
            </div>
            <div className="sign-up-step-two__exit-button-container">
                <div className="sign-up-step-two__exit-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} />
            </div>
        </div>
    );
}
