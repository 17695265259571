import { useState } from 'react';
import MyMedia from 'Components/Media/MyMedia';
import { ReactComponent as Menu } from './icons/burgerMenu.svg';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import './MobileUpload.scss';


export default function MobileUpload() {
    return (
        <div className="mobile_upload">
            <MyMedia tablet={<TabletHeader />} mobile={<MobileHeader />} />
            <div className="mobile_upload-body">
                <h3 className="mobile_upload-body-headline">Headline</h3>
                <div className="mobile_upload-body-text">
                    <p>Diam tincidunt sed morbi dolor faucibus scelerisque eu convallis.</p>
                    <p>Mauris quam et dolor sapien convallis orci risus. Nec euismod ut enim erat enim. Egestas amet adipiscing velit vulputate sit felis orci non. Diam sed quisque semper.</p>
                </div>
            </div>
            <button className="mobile_upload-body-button" onClick={() => window.location.href = 'https://quarters-dev.site/'} >
                <div className="upload-body-button_arrow" />
                Back to home page
            </button>
        </div>
    );
}

function MobileHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    return (
        <div className="mobile_header" >
            <div className="mobile_header-logo" onClick={() => window.location.href = 'https://quarters-dev.site/'} />
            <div onClick={() => setIsMenuOpen((prev) => !prev)}>
                {isMenuOpen ? <p>Close</p> : <Menu />}
            </div>
            <div className={`menu ${isMenuOpen ? 'menu_open' : ''}`}>
                <div className="menu_wrapper">
                    <div className="menu_options">
                        <p onClick={() => setLeftSidePanel(LeftSidePanel.AUTH)}>Register</p>
                        <p>Research demo</p>
                    </div>
                    <div className="menu_lins">
                        <p>mail@i-o.design</p>
                        <p>LinkedIn</p>
                        <p>Instagram</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function TabletHeader() {
    return (
        <div className="mobile_header">
            <div className="mobile_header-logo" onClick={() => window.location.href = 'https://quarters-dev.site/'} />
            <div className="tablet_header_item">
                research demo
            </div>
            <div className="tablet_header_item" onClick={() => setLeftSidePanel(LeftSidePanel.AUTH)}>
                Register
            </div>
        </div>
    );
}
