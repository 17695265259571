import { HelpSections } from "Components/Help/helpers/helpers";
import { createEvent, createStore } from "effector";

export const $helpSection = createStore<{section: HelpSections; itemId: string | null}>({ section: HelpSections.WHAT_IS, itemId: null });
export const setHelpSection = createEvent<{ section: HelpSections; itemId: string | null;}>();

$helpSection.on(setHelpSection, (_, { section, itemId }) => {
  return {
    section: section,
    itemId: itemId,
  };
});
