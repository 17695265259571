import { closeModal } from 'entities/modal';
import { ReactComponent as CrossSvg } from './icons/cross.svg';
import Button from 'Components/Button';
import { RoutePath, closeSketch, goTo } from 'entities/router';
import './SketchLeaving.scss';


export default function SketchLeaving() {
    return (
        <div className="sketch-leaving-modal__container">
            <div className="sketch-leaving-modal">
                <div className="sketch-leaving-modal__exit-button-container">
                    <CrossSvg className="sketch-leaving-modal__exit-button" onClick={() => closeModal()} />
                </div>
                <div className="sketch-leaving-modal__title">Sketch leaving</div>
                <div className="sketch-leaving-modal__text">Are you sure you want to leave?</div>
                <div className="sketch-leaving-modal__buttons-container">
                    <Button onClick={() => {
                        closeSketch();
                        localStorage.removeItem('fileName');
                        goTo(RoutePath.HOME);
                    }}>OK</Button>
                    <Button onClick={() => closeModal()}>CANCEL</Button>
                </div>
            </div>
        </div>
    );
}
