import { useState } from 'react';
import Select from 'Components/Select';
import Checkbox from 'Components/Checkbox';
import './DemoSettings.scss';


const autoSave = [{ key: '5 min', value: '5 min' }, { key: '10 min', value: '10 min' }, { key: '15 min', value: '15 min' }, { key: '20 min', value: '20 min' }];

export default function DemoSettings() {
    const [dontRemind, setDontRemind] = useState<boolean>(false);
    const [saveFrequency, setSaveFrequency] = useState<string>('5 min');

    return (
        <div className="demo_settings">
            <div className="demo_settings-menu ">
                <p>set reminder intervals</p>
            </div>
            <div className="demo_settings-items">
                <Select items={autoSave} value={saveFrequency} setValue={setSaveFrequency} disabled={dontRemind} />
                <div className="dont_remind-checkbox-wrapper">
                    <Checkbox checked={dontRemind} onChange={setDontRemind} className="dont_remind-checkbox" />
                    <p>Do not remind</p>
                </div>
            </div>
        </div>
    );
}
