import tinycolor from 'tinycolor2';
import { Color } from './Manager';

export function generateRandomColor(): Color {
    const color = tinycolor({ h: getRandomHue(), s: 0.55, l: 0.5 }).toRgb();
    return [color.r, color.g, color.b, color.a * 220];
}

export function parseLabelBox(labelBox: string) {
    const obj = JSON.parse(labelBox.replaceAll(`'`, `"`));
    return [obj.left, obj.top, obj.right, obj.bottom];
}

export function createCanvasAndContext(width = 0, height = 0): { canvas: HTMLCanvasElement, context: CanvasRenderingContext2D } {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    if (!canvas) throw new Error('Canvas not exist.');
    const context = canvas.getContext('2d', { willReadFrequently: true });
    if (!context) throw new Error('Context not exist.');
    return { canvas, context };
}

function getRandomHue(): number {
    const randomAngleBefore = Math.floor(Math.random() * 195);
    const randomAngleAfter = Math.floor(Math.random() * (360 - 265) + 266);

    return (Math.random() < 0.5 ? randomAngleBefore : randomAngleAfter);
}
