import { useCallback, useEffect } from 'react';
import { JoinModal, closeModal } from 'entities/modal';
import { ReactComponent as CrossSvg } from './icons/cross.svg';
import Button from 'Components/Button';
import './Join.scss';


export default function Join({ res, rej }: JoinModal['props']) {
    useEffect(() => rej, [rej]);

    const resolve = useCallback((including: boolean) => {
        closeModal();
        res(including);
    }, [res]);

    const reject = useCallback(() => {
        closeModal();
        rej();
    }, [rej]);

    return (
        <div className="join-modal__container">
            <div className="join-modal">
                <div className="join-modal__exit-button-container">
                    <CrossSvg className="join-modal__exit-button" onClick={reject} />
                </div>
                <div className="join-modal__title">Join segments</div>
                <div className="join-modal__text">Include existing object’s description and attachments in the new segment?</div>
                <div className="join-modal__buttons-container">
                    <Button onClick={() => resolve(true)}>INCLUDE</Button>
                    <Button onClick={() => resolve(false)}>NO</Button>
                </div>
            </div>
        </div>
    );
}