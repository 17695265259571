import React, {useState } from "react";
import './MailSettings.scss'
import Switcher from "Components/Switcher";

export default function MailSettings() {
    const [newsLetter, setNewsLetter] = useState<boolean>(true)
    const [notify, setNotify] = useState<boolean>(false)

    return (
        <div className="mail_preferences">
            <div className="mail_preferences-menu">
                <p>email notifications</p>
            </div>
            <div className="mail_preferences_items">
                <div className="mail_preferences_toggle">
                  <Switcher value={newsLetter} onClick={()=>setNewsLetter((prev)=>!prev)}/>
                    <p>Product newsletter</p>
                </div>
                <div className="mail_preferences_toggle">
                <Switcher value={notify} onClick={()=>setNotify((prev)=>!prev)}/>
                    <p>Notify me when rendering is ready</p>
                </div>
            </div>
        </div>
    )
}

