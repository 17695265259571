import classNames from 'classnames';
import { ReactComponent as CheckboxSvg } from './icons/checkbox.svg';
import { ReactComponent as CheckboxBlackSvg } from './icons/checkboxBlack.svg';
import './Checkbox.scss';

type CheckboxProps = {
    className?: string;
    id?: string;
    checked: boolean;
    onChange: (value: boolean) => void,
};

export default function Checkbox({ className = '', id, checked, onChange }: CheckboxProps) {
    return (
        <CheckboxSvg
            id={id}
            className={classNames({
                'checkbox': true,
                'checkbox_active': checked,
                [className]: Boolean(className),
            })}
            onClick={() => onChange(!checked)}
        ></CheckboxSvg>
    );
}

export function CheckboxBlack({ className = '', id, checked, onChange }: CheckboxProps) {
    return (
        <CheckboxBlackSvg
            id={id}
            className={classNames({
                'checkbox': true,
                'checkbox_black_active': checked,
                [className]: Boolean(className),
            })}
            onClick={() => onChange(!checked)}
        ></CheckboxBlackSvg>
    );
}
