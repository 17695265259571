import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import './Select.scss';


type SelectProps<T> = {
    className?: string;
    items: readonly { key: string; value: T }[];
    value: string | undefined;
    setValue: (value: T) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    //TO DO
    placeholder?: string;
    disabled?: boolean;
    validateError?: boolean;
}

export default function Select<T>({ className, items, value, setValue, onFocus, placeholder, disabled = false, validateError = false }: SelectProps<T>) {
    const select = useRef<HTMLDivElement>(null);
    const [, setActive] = useState(false);

    const handleSetValue = useCallback((item: T) => {
        setValue(item);
        select.current?.blur();
    }, [setValue]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.currentTarget;
        setActive(prev => {
            if (prev) target.blur();
            else target.focus();
            return !prev;
        });
    }, []);

    return (
        <div ref={select} tabIndex={0} className={classNames('select', className, { 'select_disabled': disabled, 'select_error': validateError })} onClick={handleClick} onFocus={onFocus}>
            <div className={classNames({ 'select__placeholder': true, 'select__placeholder_selected': Boolean(value) })}>{value || placeholder}</div>
            <div className="select__button" />
            <div className="select__items">
                {
                    items.map(({ key, value }, index) => (
                        <div className="select__item" key={index} onClick={() => handleSetValue(value)}>{key}</div>
                    ))
                }
            </div>
        </div>
    );
}

export type RenderColor = {
    color: string,
    name: string,
};

type ColorSelectProps = {
    className?: string;
    items: Array<RenderColor>;
    value: RenderColor | undefined;
    setValue: React.Dispatch<React.SetStateAction<RenderColor>> | React.Dispatch<React.SetStateAction<RenderColor | undefined>>;
    placeholder?: string;
    disabled?: boolean;
};

export function ColorSelect({ className, items, value, setValue, placeholder, disabled = false }: ColorSelectProps) {
    const select = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState(false);

    const handleSetValue = useCallback((item: RenderColor) => {
        setValue(item);
        select.current?.blur();
    }, [setValue]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.currentTarget;
        setActive(prev => {
            if (prev) target.blur();
            else target.focus();
            return !prev;
        });
    }, []);

    return (
        <div ref={select} tabIndex={0} className={classNames('select', className, { 'select_disabled': disabled })} onClick={handleClick}>
            <div className='select-color-wrapper'>
                {value !== undefined && <div className='color-square' style={{ backgroundColor: value.color }} />}
                <div className={classNames({ 'select__placeholder': true, 'select__placeholder_selected': Boolean(value) })}>{value?.name || placeholder}</div>
            </div>
            <div className="select__button" />

            <div className="select__items-color" style={{ display: active ? 'flex' : 'none' }}>
                {items.map(item => (
                    <div className="select__color-item" key={item.color} onClick={() => handleSetValue(item)}>
                        <div className='color-square' style={{ backgroundColor: item.color }} />
                        <p>{item.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
