import './InputPhone.scss'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function InputPhone({ value, setValue }: { value: string, setValue: (value: string) => void }) {

    return (
        <PhoneInput
            country={"us"}
            // enableSearch={true}
            placeholder="Enter your business phone"
            value={value}
            containerClass={'phoneInput_container'}
            inputClass={'phoneInput_input'}
            buttonClass={'phoneInput_dropdown-bttn'}
            dropdownClass={'phoneInput_dropdown'}
            onChange={(e) => setValue(e ? e.toString() : value)}
        />
    )
}


// export default function PhoneInput({ value, setValue }: {value: string, setValue: (value: string) => void}) {
//     return (

//         <Input
//             defaultCountry="US"
//             initialValueFormat="national"
//             countryCallingCodeEditable={false}
//             international
//             placeholder="Enter your business phone"
//             value={value}
//             onChange={(e) => setValue(e ? e.toString() : value)}
//         />
//     )
// }

