import { useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Attachment, Segment, useEditor, useSegment, useManager } from 'entities/sketch/Manager';
import { setElementForAttach } from 'entities/library';
import AttachmentComponent from '../Attachment';
import { ReactComponent as Checkbox } from './icons/checkbox.svg';
import { ReactComponent as AddAttachSvg } from './icons/add_attach.svg';
import { ReactComponent as AttachSvg } from './icons/attach.svg';
import { ReactComponent as DeleteSvg } from './icons/delete.svg';
import { ReactComponent as DropAttachSvg } from './icons/drop-attach.svg';
import { useHotKey } from 'hooks';
import useClickOutside from 'services/useClickOutside';
import useChildElementsHeight from 'services/useChildElementsHeight';
import { useStore } from 'effector-react';
import { $tutorial } from 'entities/tutorial';
import { Notifications, setNotification } from 'entities/notification';
import { setHint } from 'entities/hint';
import { SpriteState } from 'entities/sketch/Engine/Engine';
import './SegmentElement.scss';


type SegmentElementProps = {
    segment: Segment;
    index: number;
    realIndex: number;
};

export default function SegmentElement({ segment, index, realIndex }: SegmentElementProps) {
    const tutorial = useStore($tutorial);
    const editingGroup = useManager('editingGroup');
    const selectedElemets = useManager('selectedElements');
    const hoveredElement = useManager('hoveredElement');
    const manager = useManager('self');
    const editing = useEditor('editing');
    const attachments = useSegment(segment, 'attachments');
    const state = useSegment(segment, 'state');
    const description = useSegment(segment, 'description');
    const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState<Array<Attachment>>([]);

    const selected = useMemo(() => selectedElemets.includes(segment), [selectedElemets, segment]);

    const handleSelect = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup) {
            const group = manager.editingGroup;
            if (!group) throw new Error('Group is not being edited.');
            if (!e.ctrlKey && !e.metaKey) return;
            if (group.segments.includes(segment)) throw new Error('Group has this segment.');
            else manager.addSegmentToGroup(segment, group, group.segments.length);
            return;
        }
        if (editing) return;
        let target: unknown = e.target;

        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (['checkbox', 'button'].includes(target.getAttribute('data-type') as any)) return;
            target = target.parentElement;
        }

        manager.selectOneElement(segment);
    }, [segment, manager, editingGroup, editing]);

    const handleCheckbox = useCallback(() => {
        if (editingGroup || editing) return;
        if (selected) manager.unselectElement(segment);
        else manager.selectElement(segment);
    }, [selected, manager, segment, editing, editingGroup]);

    useEffect(() => {
        if (tutorial.step === 0) return manager.unselectAllElements();
        if ((realIndex === 0 && tutorial.step === 1) ||
            (realIndex === 1 && tutorial.step === 2) ||
            (realIndex === 2 && tutorial.step === 3)) {
            manager.unselectAllElements();
            manager.selectElement(segment);
        } else if (tutorial.step === 4 || tutorial.step === 0) {
            manager.unselectAllElements();
        }
    }, [realIndex, segment, manager, tutorial.step]);

    const handleRemoveAttachments = useCallback(() => {
        selectedAttachments.forEach(attach => segment.unattach(attach));
    }, [selectedAttachments, segment]);

    useHotKey('Delete', handleRemoveAttachments);

    useEffect(() => {
        if (attachments.length > 0) setAttachmentIsOpen(true);
        else setAttachmentIsOpen(false);
    }, [attachments]);

    const segmentAttachRef = useClickOutside(() => {
        if (editingGroup || editing) setSelectedAttachments([]);
    });

    const attachmentsHeight = useChildElementsHeight({ ref: segmentAttachRef, target: attachments });

    const onMouseEnter = useCallback(() => manager.hoveredElement = segment, [segment, manager]);

    const onMouseLeave = useCallback(() => {
        if (manager.hoveredElement === segment) manager.hoveredElement = null;
    }, [segment, manager]);

    return (
        <Draggable draggableId={segment.id} index={index}>
            {(provided, snapshot) => (
                <div {...provided.draggableProps} ref={provided.innerRef} >
                    <div
                        id={'element_' + segment.id}
                        className={classNames({
                            segment: true,
                            'segment_is-being-dragged': snapshot.isDragging,
                            segment_active: selected,
                            segment_hidden: state === SpriteState.HIDE,
                            segment_hovered: hoveredElement === segment,
                        })}
                        onClick={handleSelect}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <div className={classNames({ segment__cell1: true, })}>
                            <div className="segment__drag" {...provided.dragHandleProps} />
                            <div className="segment__checkbox">
                                <Checkbox className={classNames({ 'segment__checkbox-icon_active': selected, 'segment__checkbox-icon_inactive': (editingGroup || editing) })} onClick={handleCheckbox} data-type="checkbox" />
                            </div>
                        </div>
                        <div className="segment__cell2">
                            <div
                                id={`color_circle_${index}`}
                                onMouseEnter={() => setHint({ id: 'color_circle_', index: index.toLocaleString() })}
                                onMouseLeave={() => setHint(null)}
                                className={classNames({ "segment__color": true, "segment__color_active": selected })}
                                style={{ backgroundColor: `rgb(${segment.color[0]}, ${segment.color[1]}, ${segment.color[2]})` }}
                                onClick={() => manager.changeToRandomColor(segment)}
                            />
                            <div className="segment__index">{segment.name.toString().padStart(3, '0')}</div>
                        </div>
                        <div className="segment__cell4">
                            <TextareaAutosize value={description} onChange={e => segment.description = e.currentTarget.value} className="segment__desc" placeholder="Note" onBlur={() => manager.saveSketch()} />
                        </div>
                        <div className="segment__cell3">
                            <div className={`segment__cell3-attachments ${attachments.length ? '' : 'segment__cell3-attachments_disabled'}`}
                                onClick={() => {
                                    if (attachments.length)
                                        setAttachmentIsOpen(prev => !prev)
                                }}>
                                {Boolean(attachments.length) ?
                                    <div className={classNames({ "segment__attach-drop": true, "segment__attach-drop_active": attachmentIsOpen })} ><DropAttachSvg /></div> :
                                    <div className='segment__attach-drop' />}
                                <div className="segment__attach-button">
                                    <AttachSvg className='segment__attach-icon' />
                                </div>
                                Attachments ({attachments.length})
                            </div>
                        </div>
                        <div className='segment__cell6'>
                            <button className='segment__attach-add_button' onClick={() => { setNotification(Notifications.MORE_ATTACHMENTS_INFO); setElementForAttach(segment) }} >
                                <AddAttachSvg />
                                Add
                            </button>
                        </div>
                        <div data-type="button" className="segment__cell5" onClick={() => manager.removeSegment(segment)}>
                            <DeleteSvg />
                        </div>
                    </div>
                    <div className={classNames({ segment__attachments: true, segment__attachments_open: attachmentIsOpen, segment__attachments_selected: selected, segment__attachments_hidden: state === SpriteState.HIDE })}
                        ref={segmentAttachRef}
                        style={{ maxHeight: attachmentIsOpen ? attachmentsHeight : 0 }}>
                        {attachments.map((attachment, index) => <AttachmentComponent element={segment} attachment={attachment} selectedAttachments={[selectedAttachments, setSelectedAttachments]} key={`attachment_${segment.id}_${attachment.filename}_${index}`} elementId='attachment_segment_' index={index} />)}
                    </div>
                </div >
            )}
        </Draggable >
    );
}
