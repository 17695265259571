export enum HelpSections {
  WHAT_IS = "WHAT_IS",
  CHOOSING_IMAGE = "CHOOSING_IMAGE",
  MASK_SELECTION = "MASK_SELECTION",
  CREATE_MASKS = "CREATE_MASKS",
  DESCRIBE = "DESCRIBE",
  GROUP = "GROUP",
  RENDERING = "RENDERING",
  ACCOUNT = "ACCOUNT",
  INTERFACE = "INTERFACE",
}

export interface SectionI {
  type: HelpSections;
  title: string;
}
export interface SectionItemI {
  title: string;
  isBeta?: boolean;
  image: string | undefined;
  id?: string, 
  readMoreAbout: SectionI | undefined;
}

export const readMoreItems: SectionI[] = [
  {
    type: HelpSections.INTERFACE,
    title: "Tour the interface",
  },
  {
    type: HelpSections.WHAT_IS,
    title: "What is io-buro?",
  },
  {
    type: HelpSections.CHOOSING_IMAGE,
    title: "Choosing image to render",
  },
  {
    type: HelpSections.MASK_SELECTION,
    title: "mask selection tips and tricks",
  },
  {
    type: HelpSections.CREATE_MASKS,
    title: "how to create and edit object masks",
  },
  {
    type: HelpSections.DESCRIBE,
    title: "how to describe objects",
  },
  {
    type: HelpSections.GROUP,
    title: "how to group identical objects to define together",
  },
  {
    type: HelpSections.RENDERING,
    title: "rendering set up",
  },
  {
    type: HelpSections.ACCOUNT,
    title: "manage your account",
  },
];

export const interfaceDropdownItems: SectionItemI[] = [
  { title: "Tooltips", image: "", readMoreAbout: undefined},
  {
    title: "Viewport Navigation",
    image: "",
    readMoreAbout: {
      type: HelpSections.MASK_SELECTION,
      title: "on mask selection tips and tricks",
    },
  },
  {
    title: "Indicate Objects You Intend to Define",
    image: "",
    readMoreAbout: {
      type: HelpSections.CREATE_MASKS,
      title: "how to create and edit object masks",
    },
  },
  {
    title: "Define Indicated Objects",
    image: "",
    readMoreAbout: {
      type: HelpSections.DESCRIBE,
      title: "how to define objects",
    },
  },
  {
    title: "Group Similar Objects",
    image: undefined,
    readMoreAbout: {
      type: HelpSections.GROUP,
      title: "on object groups",
    },
  },
  {
    title: "Undo/Redo Action Limit",
    image: undefined,
    readMoreAbout: undefined,
  },
];

export const editingToolsItems: SectionItemI[] = [
  { title: "Frame to Add", image: "", readMoreAbout: undefined },
  { title: "Point to Add", image: "", readMoreAbout: undefined },
  { title: "Point to Exclude", image: "", readMoreAbout: undefined },
  { title: "Brush", image: "", readMoreAbout: undefined },
  { title: "Eraser", image: "", readMoreAbout: undefined },
];

export const attachmentTypesItems: SectionItemI[] = [
  { title: "Upload", image: undefined, isBeta: true, readMoreAbout: undefined },
  { title: "URLs", image: undefined, isBeta: true, readMoreAbout: undefined },
  { title: "Products", image: undefined, readMoreAbout: undefined, id: 'help-section__product'},
  { title: "Entourage", image: undefined, readMoreAbout: undefined, id: 'help-section__entourage'},
];

export const accountSettingsItems: SectionItemI[] = [
  {
    title: "Create, edit or delete account",
    image: undefined,
    readMoreAbout: undefined,
  },
  { title: "Personal settings", image: undefined, readMoreAbout: undefined },
  { title: "Billing settings", image: undefined, readMoreAbout: undefined },
  { title: "Mailing preferences", image: undefined, readMoreAbout: undefined },
  { title: "Demo settings", image: undefined, readMoreAbout: undefined },
];
