import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import Button from 'Components/Button';
import * as Listeners from '../Listeners';
import { Group, Manager, useEditor, useManager } from 'entities/sketch/Manager';
import { ReactComponent as ButtonSVG0 } from './icons/button0.svg';
import { ReactComponent as ButtonSVG1 } from './icons/button1.svg';
import { ReactComponent as ButtonSVG2 } from './icons/button2.svg';
import { ReactComponent as ButtonSVG3 } from './icons/button3.svg';
import { ReactComponent as EraserSVG } from './icons/eraser.svg';
import { ReactComponent as BrushSVG } from './icons/brush.svg';
import { ReactComponent as UndoSvg } from './icons/arrow_left.svg';
import { ReactComponent as RendoSvg } from './icons/arrow_right.svg';
import { ReactComponent as HightlightSvg } from './icons/highlight.svg';
import { ReactComponent as CursorHandSvg } from './icons/hand.svg';
import { setHint } from 'entities/hint';
import { useHotKey } from 'hooks';
import { /* $prevSketchState, */ $tutorial/* , setPrevSketchState */ } from 'entities/tutorial';
import './LeftBar.scss';


type LeftBarProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

class EndingButtons {
    constructor(protected readonly manager: Manager) { }
    handleDone() { };
    handleCancel() { };
}

class EditEndingButton extends EndingButtons {
    handleDone(): void {
        this.manager.segmentEditor.endEditing();
    }

    handleCancel() {
        this.manager.segmentEditor.cancelCreateSegment();
    }
}

class CreateEndingButton extends EndingButtons {
    handleDone(): void {
        this.manager.segmentEditor.endCreating();
    }

    handleCancel() {
        this.manager.segmentEditor.cancelEditSegment();
    }
}

export default function LeftBar({ listener, setListener }: LeftBarProps) {
    const editingGroup = useManager('editingGroup');
    const segmentEditor = useManager('segmentEditor');
    const editing = useEditor('editing');
    const undoArray = useEditor('undo');
    const redoArray = useEditor('redo');
    const manager = useManager('self');
    const tutorial = useStore($tutorial);
    const [endingButtons, setEndingButtons] = useState<EndingButtons>(() => new EndingButtons(manager));

    const selectDisabled = useMemo(() => editing, [editing]);

    const undoDisabled = useMemo(() => Number(undoArray?.length) < 1, [undoArray]);
    const rendoDisabled = useMemo(() => !redoArray?.length, [redoArray]);

    const undo = useCallback(() => {
        if (undoDisabled) return;
        segmentEditor.undoAction();
    }, [segmentEditor, undoDisabled]);

    const rendo = useCallback(() => {
        if (rendoDisabled) return;
        segmentEditor.rendoAction();
    }, [segmentEditor, rendoDisabled]);

    useHotKey('z', undo, useMemo(() => ({ modifier: 'ctrlKey' }), []));
    useHotKey('y', rendo, useMemo(() => ({ modifier: 'ctrlKey' }), []));

    useEffect(() => {
        if (editing) return setListener(new Listeners.BoxListener(manager));
        if (editingGroup) return setListener(new Listeners.EditGroup(manager));
        setListener(new Listeners.Select(manager));
    }, [editing, setListener, editingGroup, manager]);

    const handleClickHightlight = useCallback(() => {
        if (selectDisabled) return;

        if (editingGroup) return setListener(new Listeners.EditGroup(manager));
        else return setListener(new Listeners.Select(manager));
    }, [selectDisabled, manager, setListener, editingGroup]);

    const clickEsc = useCallback(() => {
        if (!editing) return;
        endingButtons.handleCancel();
    }, [editing, endingButtons]);

    useHotKey('Escape', clickEsc);

    return (
        <div className="left-bar">
            <div className="left-bar__arrows" id="undo_redo">
                <div
                    className={classNames({ 'left-bar__undo': true, 'left-bar__undo_disabled': undoDisabled })}
                    onClick={undo}
                    onMouseEnter={() => setHint({ id: 'undo_redo' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <UndoSvg />
                </div>
                <div
                    className={classNames({ 'left-bar__rendo': true, 'left-bar__rendo_disabled': rendoDisabled })}
                    onClick={rendo}
                    onMouseEnter={() => setHint({ id: 'undo_redo' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <RendoSvg />
                </div>
            </div>
            <div className="left-bar__cursor-buttons_wrapper" id="left-bar__cursor-buttons">
                <div className="left-bar__cursor-buttons">
                    <div
                        className={classNames({ 'left-bar__cursor-highlight': true, 'left-bar__cursor-highlight_active': listener instanceof Listeners.Select, "left-bar_bttns_disabled": editing })}
                        onClick={handleClickHightlight}
                        id="select"
                        onMouseEnter={() => setHint({ id: 'select' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <HightlightSvg className="select_cursor" />
                    </div>
                    <div
                        className={classNames({ 'left-bar__cursor-hand': true, 'left-bar__cursor-hand_active': listener instanceof Listeners.Zoom })}
                        onClick={() => setListener(new Listeners.Zoom(manager))}
                        id="move"
                        onMouseEnter={() => setHint({ id: 'move' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <CursorHandSvg className="move_cursor" />
                    </div>
                </div>
            </div>
            {editing || tutorial.step === 4 ? <EditButtons listener={listener} setListener={setListener} endingButtons={endingButtons} /> : <IndicateButton setEndingButtons={setEndingButtons} />}
        </div >
    );
}

type EditButtonsProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
    endingButtons: EndingButtons;
};

function EditButtons({ listener, setListener, endingButtons }: EditButtonsProps) {
    const undoArray = useEditor('undo');
    const manager = useManager('self');
    /*     const prevSketchState = useStore($prevSketchState) */

    return (
        <div className="left-bar__buttons_wrapper" id="left-bar__buttons_wrapper">
            <div className="left-bar__buttons_options">
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.BoxListener })}
                    onClick={() => setListener(new Listeners.BoxListener(manager))}
                    id="left-bar__icon1"
                    onMouseEnter={() => setHint({ id: 'left-bar__icon1' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="left-bar__icon1">
                        <ButtonSVG1 className='left-bar__icon' />
                    </div>
                    <div className="left-bar__button-text">FRAME<br />TO ADD</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.PositivePoint })}
                    onClick={() => setListener(new Listeners.PositivePoint(manager))}
                    id="left-bar__icon2"
                    onMouseEnter={() => setHint({ id: 'left-bar__icon2' })}
                    onMouseLeave={() => setHint(null)}>
                    <div className="left-bar__icon2">
                        <ButtonSVG2 className='left-bar__icon' />
                    </div>
                    <div className="left-bar__button-text">POINT<br />TO ADD</div>

                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.NegativePoint })}
                    onClick={() => setListener(new Listeners.NegativePoint(manager))}
                    id="left-bar__icon3"
                    onMouseEnter={() => setHint({ id: 'left-bar__icon3' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="left-bar__icon3">
                        <ButtonSVG3 />
                    </div>
                    <div className="left-bar__button-text">POINT<br />TO exclude</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.BrushListener })}
                    onClick={() => setListener(new Listeners.BrushListener(manager))}
                    id="left-bar__icon5"
                    onMouseEnter={() => setHint({ id: 'left-bar__icon5' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <BrushSVG className='left-bar__icon' />
                    <div className="left-bar__button-text">BRUSH</div>
                </div>
                <div
                    className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.EraserListener })}
                    onClick={() => setListener(new Listeners.EraserListener(manager))}
                    id="left-bar__icon6"
                    onMouseEnter={() => setHint({ id: 'left-bar__icon6' })}
                    onMouseLeave={() => setHint(null)}
                >
                    <div className="left-bar__icon6">
                        <EraserSVG className='left-bar__icon' />
                    </div>
                    <div className="left-bar__button-text">ERASER</div>
                </div>
            </div>
            {/*             <Button disabled={Number(undoArray?.length) < 2} icon="success" size="secondary" color='dark' className="left-bar__done-button" onClick={() => {
                endingButtons.handleDone()
                segments.undefinedMode = prevSketchState.showUndefined
                segments.hideSegments = prevSketchState.hideMasks

                setPrevSketchState({ showUndefined: false, hideMasks: false })
            }
            }>DONE</Button>
            <Button size="secondary" className="left-bar__cancel-button" onClick={() => {
                segments.undefinedMode = prevSketchState.showUndefined
                segments.hideSegments = prevSketchState.hideMasks

                setPrevSketchState({ showUndefined: false, hideMasks: false })
                endingButtons.handleCancel()
            }}>CANCEL</Button> */}
            <Button disabled={Number(undoArray?.length) < 1} icon="success" size="secondary" color='dark' className="left-bar__done-button" onClick={() => endingButtons.handleDone()}>DONE</Button>
            <Button size="secondary" className="left-bar__cancel-button" onClick={() => endingButtons.handleCancel()}>CANCEL</Button>
        </div>
    );
}

type IndicateButtonProps = {
    setEndingButtons: React.Dispatch<React.SetStateAction<EndingButtons>>;
};

function IndicateButton({ setEndingButtons }: IndicateButtonProps) {
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const selectedElements = useManager('selectedElements');
    const tutorial = useStore($tutorial);

    const disabled = useMemo(() => {
        if (tutorial.step !== 0 && editingGroup) return false
        if (editingGroup) return true;
        if (selectedElements.length > 1) return true;
        if (selectedElements[0] instanceof Group) return true;
    }, [editingGroup, selectedElements, tutorial.step]);

    const onClick = useCallback(async () => {
        if (disabled) return;
        const segment = selectedElements[0];
        if (segment instanceof Group) throw new Error('Selected element instanceof Group.');

        if (!segment) {
            const segment = manager.createNewSegment();
            manager.segmentEditor.editSegment(segment);
            setEndingButtons(new CreateEndingButton(manager));
        } else {
            manager.segmentEditor.editSegment(segment);
            setEndingButtons(new EditEndingButton(manager));
        }
    }, [manager, selectedElements, disabled, setEndingButtons]);

    return (
        <div
            id="left-bar__button_chair"
            className={classNames({
                'left-bar__button': true,
                'left-bar__button_disabled': disabled,
                'left-bar__button_disabled-tutorual': tutorial.step !== 0 && (selectedElements[0] instanceof Group),
            })}
            onClick={onClick}
            onMouseEnter={() => setHint({ id: 'left-bar__button_chair' })}
            onMouseLeave={() => setHint(null)}
        >
            <div className="left-bar__icon0">
                <ButtonSVG0 className='chair_icon' />
            </div>
            <div className="left-bar__button-text">{selectedElements.length ? 'Edit mask' : 'Indicate to describe'}</div>
        </div >
    );
}
