import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import * as Listeners from './Listeners';
import Drawing from './Drawing';
import LeftBar from './LeftBar';
import List from './List';
import Tutorial from './Tutorial/Tutorial';
import useStyles from './useStyles';
import { useManager } from 'entities/sketch/Manager';
import Header from 'Components/Header';
import MobileUpload from 'pages/Upload/Mobile/MobileUpload';
import { $tutorial, $tutorialDisabled, /* setPrevSketchState,  */setTutorialDisabled, skip, TutorialDisabledReasons } from 'entities/tutorial';
import './Sketch.scss';


export default function Sketch() {
    const list = useManager('list')
    const segmentEditor = useManager("segmentEditor")
    const tutorial = useStore($tutorial);
    const [showTutorial, setShowTutorial] = useState<boolean>(false);
    const tutorialDisabled = useStore($tutorialDisabled);
    const manager = useManager('self');
    const [listener, setListener] = useState<Listeners.Listener>(() => new Listeners.Select(manager));
    const [undefinedMode, setUndefinedMode] = useState<boolean>(false);

    useStyles();

    useEffect(() => {
        if (list.length < 3 || segmentEditor.editing) {
            if (list.length < 3) setTutorialDisabled(TutorialDisabledReasons.NO_ELEMENTS);
            else setTutorialDisabled(segmentEditor.editing ? TutorialDisabledReasons.IS_EDIT_MODE : TutorialDisabledReasons.NONE);
            skip();
            setShowTutorial(false);
            
        } else {
            setTutorialDisabled(TutorialDisabledReasons.NONE);
            setShowTutorial(true);
        }

/*         if(tutorial.step!==0 && tutorialDisabled===TutorialDisabledReasons.NONE){
            if(manager.hideSegments || manager.undefinedMode) setPrevSketchState({showUndefined: manager.undefinedMode, hideMasks: manager.hideSegments})
            if (manager.hideSegments) manager.hideSegments = false
            if (manager.undefinedMode) manager.undefinedMode = false;
        }  */
        if(tutorial.step===0) setShowTutorial(false)


    }, [list, manager, tutorial.step, segmentEditor?.editing, list.length, tutorialDisabled])

    return (
        <div className="sketch">
            <MobileUpload />
            <Header type="sketch" />
            <div className="sketch__body">
                <LeftBar listener={listener} setListener={setListener}/>
                <Drawing listener={listener} setListener={setListener} undefinedMode={undefinedMode} setUndefinedMode={setUndefinedMode} />
                <List />
            </div>
            {(showTutorial) && <Tutorial />}
        </div >
    );
}
