import Button from "Components/Button";
import { Notifications } from "entities/notification";

export function getNotificationText(notification: Notifications, filename?: string) {
  switch (notification) {
    case Notifications.UPLOAD_SIZE_ERROR: {
      return (
        <p>The specified file <span className="notification_span">{filename}</span> could not be uploaded.The file exceeds the maximum upload size (36MB).Please try again.</p>
      )
    }
    case Notifications.UPLOAD_EXT_ERROR: {
      return (
        <p>The specified file <span className="notification_span">{filename}</span> could not be uploaded.Only files with the following extensions are allowed: png, jpg, jpeg. Please try again.</p>
      )
    }
    case Notifications.UPLOAD_ERROR: {
      return (
        <p>The specified file <span className="notification_span">{filename}</span> could not be uploaded.Please read our <span className="underline pointer" >Requirements</span> and try again.</p>
      )
    }
    case Notifications.SAVE_GROUP_ERROR: {
      return (
        <p>To save the group, please decide what to do with descriptions of added objects. After you save the group pressing Done, the group description will be applied to every object within the group.</p>
      )
    }
    case Notifications.REMINDER: {
      return (
        <>
          <p className="notification_reminder">Prototype not saved recently. Would you like to save? Or <span className="underline pointer">Set reminder intervals</span></p>
          <Button color="dark" className="notification_reminder-bttn">Save</Button>
        </>
      )
    }
    case Notifications.MORE_ATTACHMENTS_INFO: {
      return (
        <>
          <p>After you submit image to render you will receive two renders:</p>
          <p>A - autogenerated, but based on all definitions except beta-marked ones</p>
          <p>B - generated with R&D team support, including all beta-marked definitions.</p>
          <p>We will study frequently attached file extensions and automate their processing.</p>
        </>
      )
    }
    case Notifications.RENDER_INFO: {
      return (
        <>
          <p>After you submit your prototype for rendering you will get two renders:</p>
          <ul className="notification_render-info-list">
            <li>Rendering A is generated automatically within a predictable time frame, estimated right before you submit. However only text notes, products and entourage from io-BURO library will be considered.</li>
            <li>Rendering B is generated free of charge, regardless of its size and complexity. The rendering process will consider every attachment, including beta-marked attachments. However, the speed and results are not predictable.</li>
          </ul>
        </>
      )
    }
    case Notifications.NO_PAID_FEATURES_INFO: {
      return (
        <>
          <p>We're currently in beta testing. We've distributed free tokens to enthusiasts for feedback collection. If you'd like to join them, please contact us directly.</p>
          <p>If you're already one of our enthusiasts and have run out of tokens, please contact us via [email] or any way convenient. We'll gladly replenish your tokens for continued testing.</p>
          <p>Billing will be introduced after the testing phase is complete.</p>

        </>
      )
    }
    case Notifications.NO_TOKENS_ERROR: {
      return (
        <>
          <p>You do not have enough tokens to submit image for rendering. Please, <span className="underline cursor gray">buy more tokens</span>.</p>
          <p>You can also <span className="underline cursor gray">reset resolution and speed to default</span> to render free of charge.</p>
        </>
      )
    }
     case Notifications.PREEXISTING_ATTACHMENTS_INFO : {
      return <p>All preexisting attachments have been replaced with the newly attached product definition.</p>
     }
  }

}
