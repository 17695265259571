import { useEffect, useState } from "react";

export interface CoordinatesI {
  top: number;
  left: number;
  bottom: number;
  right: number;
  height: number;
  width: number;
}

const useElementCoordinates = (id: string | undefined) => {
  const [coordinates, setCoordinates] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: 0,
    width: 0,
  });

  function getCoordinates(element: HTMLElement) {
    let rect = element.getBoundingClientRect();

    const top = rect.top;
    const left = rect.left;
    const bottom = rect.bottom
    const right = rect.right;
    const height = rect.height;
    const width = rect.width;

    return { top, left, bottom, right, height, width };
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (id) {
        let targetElementId = "";
        switch (id) {
          case "select":
          case "move": {
            targetElementId = "left-bar__cursor-buttons";
            break;
          }
          case "zoom_in":
          case "zoom_out":
          case "zoom_fit": {
            targetElementId = "drawing__zoom-buttons";
            break;
          }
          default: {
            targetElementId = id;
            break;
          }
        }
        const targetElement = document.getElementById(targetElementId);

        if (targetElement) {
          setCoordinates(getCoordinates(targetElement));
        }
      }
    });
    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [id]);

  return coordinates;
};

export default useElementCoordinates;