import Radio from './Radio';
import Select from 'Components/Select';
import Button from 'Components/Button';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { Step } from '../Auth';
import './SignUpStepOne.scss';


type SignUpStepOneProps = {
    setStep: React.Dispatch<React.SetStateAction<Step>>;
    teamSize: string | undefined;
    setTeamSize: React.Dispatch<React.SetStateAction<string | undefined>>;
    profession: string | undefined;
    setProfession: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const professionalFields = [{ key: 'Architecture', value: 'Architecture' }, { key: 'Interior design', value: 'Interior design' }, { key: 'Furniture design', value: 'Furniture design' }, { key: 'Industrial design', value: 'Industrial design' }, { key: 'Landscape design', value: 'Landscape design' }, { key: 'Visualization (CG)', value: 'Visualization (CG)' }, { key: 'Other', value: 'Other' }];

export default function SignUpStepOne({ setStep, teamSize, setTeamSize, profession, setProfession }: SignUpStepOneProps) {
    return (
        <div className="sign-up-step-one">
            <div className="sign-up-step-one__title-container">
                <div className="sign-up-step-one__title">Sign up</div>
                <div className="sign-up-step-one__second-title">Answer the questions to sign up.</div>
            </div>
            <div className="sign-up-step-one__form-container">
                <div className="sign-up-step-one__profession-label">Mark your professional fields</div>
                <Select className="sign-up-step-one__profession-select" items={professionalFields} value={profession} setValue={setProfession} placeholder='Select your profession' />
                <div className="sign-up-step-one__team-label">Number of creative professionals in your team</div>
                <Radio className="sign-up-step-one__radio" collections={['1-10', '11-50', '51+']} value={teamSize} setValue={setTeamSize} />
                <Button disabled={[teamSize, profession].includes(undefined)} className="sign-up-step-one__confirm-button" icon="right" onClick={() => setStep(Step.SIGN_UP_STEP_1)}>proceed to registration</Button>
                <div className="sign-up-step-one__form-sign-in">Already has an account? <span className="sign-up-step-one__form-sign-in-create-link" onClick={() => setStep(Step.SIGN_IN)}>Sign in</span></div>
            </div>
            <div className="sign-up-step-one__exit-button-container">
                <div className="sign-up-step-one__exit-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} />
            </div>
        </div>
    );
}
