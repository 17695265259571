import { useState } from 'react';
import { Magic } from 'magic-sdk';
import config from 'config';
import SignIn from './SignIn';
import SignUpStepOne from './SignUpStepOne';
import SignUpStepTwo from './SignUpStepTwo';
import './Auth.scss';


export enum Step {
    SIGN_IN = 'SUGN_IN',
    SIGN_UP_STEP_0 = 'SIGN_UP_STEP_0',
    SIGN_UP_STEP_1 = 'SIGN_UP_STEP_1',
}

export const magic = new Magic(config.magicPK);

export default function Auth() {
    const [step, setStep] = useState(Step.SIGN_IN);
    const [teamSize, setTeamSize] = useState<string | undefined>(undefined);
    const [profession, setProfession] = useState<string | undefined>(undefined);

    return (
        <div className="auth">
            {step === Step.SIGN_IN && <SignIn setStep={setStep} />}
            {step === Step.SIGN_UP_STEP_0 && <SignUpStepOne setStep={setStep} teamSize={teamSize} setTeamSize={setTeamSize} profession={profession} setProfession={setProfession} />}
            {step === Step.SIGN_UP_STEP_1 && <SignUpStepTwo setStep={setStep} teamSize={teamSize} profession={profession} />}
        </div>
    );
}
