import config from 'config';

export async function recognize(file: File): Promise<string> {
    const body = new FormData();
    body.append('image', file);

    const res = await fetch(`${config.serverUrl}/upload`, {
        method: 'POST',
        body,
    });
    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.id;
}

export type Mask = {
    name: number;
}

export type Status = 'QUEUE' | 'PROCESS' | 'END' | 'ERROR';

export async function loadMask(id: string): Promise<Array<Mask> | { status: Status; queueSize: number }> {
    const res = await fetch(`${config.serverUrl}/get-item/${id}`);

    if (!res.ok) throw new Error('Bad request.');

    const data: Array<Mask> = await res.json();

    return data;
}

export async function createSketch(data: string, masks: Array<{ mask: Blob, name: number }>, id: string) {
    const body = new FormData();
    masks.forEach(mask => body.append('mask', mask.mask, mask.name + '.png'));
    body.append('data', data);

    const res = await fetch(`${config.backendUrl}/api/create-sketch/${id}`, {
        method: 'POST',
        body,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function saveMask(mask: Blob, name: number, sketchId: string) {
    const body = new FormData();
    body.append('mask', mask, name + '.png');
    const res = await fetch(`${config.backendUrl}/api/mask/${sketchId}`, {
        method: 'POST',
        body,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function saveSketch(data: string, sketch: Blob, id: string) {
    const body = new FormData();
    body.append('sketch', sketch, id + '.png');
    body.append('data', data);
    const res = await fetch(`${config.backendUrl}/api/save-sketch`, {
        method: 'POST',
        body,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function deleteSketch(id: string) {
    const res = await fetch(`${config.backendUrl}/api/sketch`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function updateSketchName(data: { sketchId: string, name: string }) {
    const res = await fetch(`${config.backendUrl}/api/sketch-name`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

export async function renderSketch(sketch: Blob, masks: Array<{ mask: Blob, id: string }>, data: string, settings: string, id: string) {
    const body = new FormData();
    body.append('sketch', sketch, id + '.png');
    masks.forEach(mask => body.append('mask', mask.mask, mask.id + '.png'));
    body.append('data', data);
    body.append('settings', settings);

    const res = await fetch(`${config.backendUrl}/api/render-sketch/${id}`, {
        method: 'POST',
        body,
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');
}

type ResponseData<T> = {
    error: boolean;
    result: T;
};

export type Sketch = {
    id: string;
    date: string;
    name: string;
    default: boolean;
};

export async function loadSketches(id: string) {
    const res = await fetch(config.backendUrl + '/api/sketches/' + id);

    if (!res.ok) throw new Error('Bad request.');

    const data: ResponseData<Array<Sketch>> = await res.json();
    if (data.error) throw new Error('Unknown error.');

    return data.result;
}

export type FullSketch = {
    id: string;
    date: string
    lastModified: string,
    config: string;
    name: string;
};

export async function loadSketch(id: string) {
    const res = await fetch(config.backendUrl + '/api/sketch/' + id);

    if (!res.ok) throw new Error('Bad request.');

    const data: ResponseData<FullSketch> = await res.json();
    if (data.error) throw new Error('Unknown error.');

    return data.result;
}

export enum RenderState {
    CREATED = 'CREATED',
    RENDERED = 'RENDERED',
    ERROR = 'ERROR',
}

export type Render = {
    id: string;
    state: RenderState;
};

export async function loadRenders(id: string) {
    const res = await fetch(config.backendUrl + '/api/renders/' + id);

    if (!res.ok) throw new Error('Bad request.');

    const data: ResponseData<Array<Render>> = await res.json();
    if (data.error) throw new Error('Unknown error.');

    return data.result;
}

export async function copySketch(id: string): Promise<Sketch> {
    const res = await fetch(`${config.backendUrl}/api/copy-sketch`, {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!res.ok) throw new Error('Unknown error.');
    const answer = await res.json();
    if (answer.error) throw new Error('Unknown error.');

    return answer.result;
}